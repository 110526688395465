body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
        "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.swal2-container {
    z-index: 2000 !important;
}

legend {
    float: none !important;
}

.changelist-container h4 {
    color: #fff !important;
    font-weight: 300;
    line-height: 1.5em;
    margin: 15px 0;
    font-size: 1.3em;
}
