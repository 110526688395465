/*
Author       : Dreamguys
Template Name: Preskool - Bootstrap Admin Template
Version      : 1.0
*/

/*============================
 [Table of CSS]

1. General
2. Table
3. Helper Class
4. Bootstrap Classes
5. Select2
6. Nav Tabs
7. Components
8. Header
9. Footer
10. Sidebar
11. Content
12. Login
13. Notifications
14. Dashboard
15. Student Details
16. Event
17. Inbox
18. Error
19. Profile
20. Teacher Dashboard
21. Student Dashboard
22. Settings
23. Blog
24. Blog Details
25. Ribbon
26. Drag & Drop
27. Alert
28. Timeline
29. Responsive

========================================*/

/*-----------------
	1. General
-----------------------*/

html {
    height: 100%;
}
body {
    background-color: #f7f7fa;
    color: #333;
    font-family: "Roboto", sans-serif;
    font-size: 15px;
    height: 100%;
    line-height: 1.5;
    overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Roboto", sans-serif;
    margin-top: 0;
    color: #000000;
}
a:hover,
a:active,
a:focus {
    outline: none;
    text-decoration: none;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px white inset !important;
    box-shadow: 0 0 0px 1000px white inset !important;
}
.form-control {
    border: 1px solid #ddd;
    box-shadow: none;
    color: #333;
    font-size: 15px;
    height: 45px;
}
.form-control:focus {
    border-color: #3d5ee1;
    box-shadow: none;
    outline: 0 none;
}
.form-control.form-control-sm {
    height: calc(1.5em + 0.5rem + 2px);
}
.form-control-sm {
    background-color: #fff;
}
.form-control.form-control-lg {
    height: calc(1.5em + 1rem + 2px);
}
a {
    color: #3d5ee1;
    text-decoration: none;
}
input,
button,
a {
    transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    -ms-transition: all 0.4s ease;
    -webkit-transition: all 0.4s ease;
}
input,
input:focus,
button,
button:focus {
    outline: none;
}
input[type="file"] {
    height: auto;
    min-height: calc(1.5em + 0.75rem + 2px);
}
input[type="text"],
input[type="password"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}
textarea.form-control {
    resize: vertical;
    min-height: 120px;
}
.navbar-nav > li {
    float: left;
}
.form-group {
    margin-bottom: 1.25rem;
}
label {
    display: inline-block;
    margin-bottom: 0.5rem;
}
.input-group .form-control {
    height: 40px;
}
.input-group .input-height {
    height: auto;
}
.nav .open > a,
.nav .open > a:focus,
.nav .open > a:hover {
    background-color: rgba(0, 0, 0, 0.1);
    border-color: rgba(0, 0, 0, 0.1);
}
.font-weight-600 {
    font-weight: 600;
}
ul,
ol {
    padding: 0px;
    list-style: none;
}

/*-----------------
	2. Table
-----------------------*/

.table {
    color: #333;
    max-width: 100%;
    margin-bottom: 0;
    width: 100%;
}
.table > :not(:first-child) {
    border-top: transparent;
}
.table > :not(caption) > * > * {
    box-shadow: unset;
    padding: 0.75rem;
}
.table thead th {
    vertical-align: bottom;
    border-bottom: 1px solid #dee2e6;
}
.table-responsive {
    white-space: nowrap;
}
.table-striped > tbody > tr:nth-of-type(2n + 1) {
    background-color: #f8f9fa;
}
.table.no-border > tbody > tr > td,
.table > tbody > tr > th,
.table.no-border > tfoot > tr > td,
.table.no-border > tfoot > tr > th,
.table.no-border > thead > tr > td,
.table.no-border > thead > tr > th {
    border-top: 0;
    padding: 10px 8px;
}
.table-nowrap td,
.table-nowrap th {
    white-space: nowrap;
}
.table.dataTable {
    border-collapse: collapse !important;
    border: 1px solid rgba(0, 0, 0, 0.05);
}
table.table td h2 {
    display: inline-block;
    font-size: inherit;
    font-weight: 400;
    margin: 0;
    padding: 0;
    vertical-align: middle;
}
table.table td h2.table-avatar {
    align-items: center;
    display: inline-flex;
    font-size: inherit;
    font-weight: 400;
    margin: 0;
    padding: 0;
    vertical-align: middle;
    white-space: nowrap;
}
table.table td h2 a {
    color: #333;
}
table.table td h2 a:hover {
    color: #3d5ee1;
}
table.table td h2 span {
    color: #888;
    display: block;
    font-size: 12px;
    margin-top: 3px;
}
.table thead tr th {
    font-weight: 600;
}
.table tbody tr {
    border-bottom: 1px solid #dee2e6;
}
.table.table-center td,
.table.table-center th {
    vertical-align: middle;
}
.table-hover tbody tr:hover {
    background-color: #f7f7f7;
}
.table-hover tbody tr:hover td {
    color: #474648;
}
.table-striped thead tr {
    border-color: transparent;
}
.table-striped tbody tr {
    border-color: transparent;
}
.table-striped tbody tr:nth-of-type(even) {
    background-color: rgba(255, 255, 255, 0.3);
}
.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(235, 235, 235, 0.4);
}
.table-bordered {
    border: 1px solid rgba(0, 0, 0, 0.05) !important;
}
.table-bordered th,
.table-bordered td {
    border-color: rgba(0, 0, 0, 0.05);
}
.card-table .card-body .table > thead > tr > th {
    border-top: 0;
}
.card-table .card-body .table tr td:first-child,
.card-table .card-body .table tr th:first-child {
    padding-left: 1.5rem;
}
.card-table .card-body .table tr td:last-child,
.card-table .card-body .table tr th:last-child {
    padding-right: 1.5rem;
}
.card-table .table td,
.card-table .table th {
    border-top: 1px solid #e2e5e8;
    padding: 1rem 0.75rem;
    white-space: nowrap;
}
.table .thead-light th {
    color: #495057;
    background-color: #f8f9fa;
    border-color: #eff2f7;
}
.table .student-thread th {
    color: #000;
    background-color: #f8f9fa;
    border-color: #eff2f7;
}
.settings-form {
    margin-top: 20px;
}
.nav-tabs.menu-tabs .nav-item.active .nav-link {
    color: #fff;
    background: #3d5ee1;
}
.settings-menu-links .nav-tabs.menu-tabs {
    border: none;
    margin-bottom: 20px;
}
.settings-label {
    margin-bottom: 10px;
}
.settings-btn {
    border: 1px solid #d7d7d7;
    border-radius: 5px;
    color: #000000;
    cursor: pointer;
    font-weight: 400;
    padding: 15px 15px;
    position: relative;
    width: 100%;
    height: 46px;
}
.settings-size {
    font-size: 14px;
    color: #131523;
    font-style: italic;
    margin-top: 10px;
    margin-bottom: 0;
}
.upload-images {
    background: rgba(255, 255, 255, 0.8);
    border: 1px solid #e4e4e4;
    border-radius: 5px;
    display: flex;
    margin-top: 30px;
    width: 196px;
    height: 78px;
    justify-content: space-around;
    align-items: center;
    position: relative;
}
.settings-btn .hide-input {
    position: relative;
    z-index: 1;
    cursor: pointer;
    min-height: auto;
    padding-left: 4px;
    padding-top: 0;
    line-height: 10px;
    width: 100%;
    opacity: 0;
}
.settings-btn .upload {
    position: absolute;
    z-index: 11;
    background: #3d5ee1;
    border-radius: 5px;
    right: 2px;
    top: 1.5px;
    width: 41px;
    height: 41px;
    font-size: 20px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
}
.settings-size span {
    font-weight: 700;
}
.upload-images img {
    max-height: 45px;
    width: auto;
}
.upload-images .btn-icon {
    color: #18aefa;
    font-size: 16px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
    position: absolute;
    padding: 0;
    width: 20px;
    height: 20px;
    display: -ms-inline-flexbox;
    display: inline-flex;
    right: 0px;
    top: 0px;
    justify-content: center;
    -webkit-justify-content: center;
}
.check {
    display: block;
    margin: 0;
    padding: 0;
    width: 0;
    height: 0;
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
    position: absolute;
}
.checktoggle {
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.25);
    border-radius: 12px;
    cursor: pointer;
    display: block;
    font-size: 0;
    height: 24px;
    margin-bottom: 0;
    position: relative;
    width: 48px;
}
.checktoggle:after {
    content: " ";
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(5px, -50%);
    width: 16px;
    height: 16px;
    background-color: rgba(0, 0, 0, 0.25);
    border-radius: 50%;
    transition: left 300ms ease, transform 300ms ease;
}
.upload-size {
    width: 82px;
    height: 82px;
}
.settings-btns .btn-orange:hover {
    background: #18aefa;
    border: 1px solid #18aefa;
    color: #fff;
    opacity: 0.8;
}
.settings-btns .btn-orange {
    font-size: 16px;
    font-weight: 700;
    padding: 10px 26px;
    margin-right: 15px;
    background: #3d5ee1;
    border: 1px solid #3d5ee1;
    color: #fff;
    border-radius: 4px;
}
.settings-btns .btn-grey {
    font-size: 16px;
    font-weight: 700;
    padding: 10px 26px;
    background: #fff;
    color: #242424;
    border: 1px solid #242424;
    border-radius: 4px;
}
.settings-btns .btn-grey:hover {
    background: #242424;
    border: 1px solid #242424;
    color: #fff;
}
.card .card-header .card-title {
    margin-bottom: 0;
    font-weight: 600;
    font-size: 20px;
    color: #000000;
    font-family: "Source Sans Pro", sans-serif;
}
.check:checked + .checktoggle {
    background-color: #3d5ee1;
    border: 1px solid #3d5ee1;
}
.check:checked + .checktoggle:after {
    background-color: #fff;
    left: 100%;
    transform: translate(calc(-100% - 5px), -50%);
}
.nav-tabs.menu-tabs .nav-link {
    position: relative;
    border: none;
    outline: none;
    display: inline-flex;
    align-items: center;
    background: transparent;
    font-weight: 400;
    font-size: 14px;
    padding: 10px 20px;
    border-radius: 5px;
    color: #000000;
    background: #fff;
    border: 1px solid #3d5ee1;
}
.nav-tabs.menu-tabs .nav-item {
    padding-right: 10px;
    margin-bottom: 10px;
    display: inline-block;
}
.nav-tabs.menu-tabs .nav-link:hover {
    color: #fff;
    background: #3d5ee1;
}
.settings-form .social-icon {
    width: 50px;
    height: 40px;
    margin-right: 6px;
    color: #fff;
    background: #3d5ee1;
    border: 1px solid #3d5ee1;
    border-radius: 5px;
    font-size: 24px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: relative;
    padding: 4px;
}
.settings-form .links-cont .social-icon::after {
    content: "";
    border-top: 0.2em solid;
    border-right: 0.2em solid transparent;
    border-bottom: 0;
    border-left: 0.2em solid transparent;
    margin-left: 4px;
}
.settings-form .trash:hover {
    color: #fff;
    opacity: 0.8;
}
.settings-form .trash {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 40px;
    width: 46px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    background-color: #3d5ee1;
    color: #fff;
    position: relative;
    right: -6px;
    font-size: 20px;
}
.add-links {
    background-color: #3d5ee1;
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    padding: 10px 26px;
    border-radius: 4px;
}
.add-links:hover {
    color: #fff;
    opacity: 0.8;
}
.settings-form .bootstrap-tagsinput .tag {
    margin-right: 5px;
    margin-bottom: 5px;
    color: #000000;
    background: #e4e4e4 !important;
    border-radius: 0px;
    font-size: 15px;
    font-weight: 500;
    padding: 12px 12px;
    font-family: "Titillium Web", sans-serif;
}
.settings-form .bootstrap-tagsinput input {
    line-height: 36px;
    border: 1px solid transparent;
}
.settings-form .bootstrap-tagsinput {
    display: block;
    border-radius: 5px;
    color: #333;
    background: #ffffff;
    border: 1px solid #d7d7d7;
    box-shadow: unset;
    min-height: 46px;
    padding-top: 4px;
    padding-left: 5px;
    padding-right: 10px;
}

.settings-form .custom_radio {
    color: #000;
    display: inline-block;
    position: relative;
    font-size: 14px;
    font-size: 0.9375rem;
    padding-left: 30px;
    margin-bottom: 0px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.settings-form .custom_radio .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #fff;
    border: 1px solid #3d5ee1;
    border-radius: 50%;
}
.settings-form .custom_radio .checkmark:after {
    display: block;
    content: "";
    position: absolute;
    opacity: 0;
    top: 3px;
    left: 3px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: #3d5ee1;
}
.custom_radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}
.custom_radio input:checked ~ .checkmark::after {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    opacity: 1;
    visibility: visible;
}
.darkmode .card .card-header .card-title {
    color: #97a2d2;
}
.darkmode .nav-tabs.menu-tabs .nav-link {
    color: #97a2d2;
}
.darkmode .settings-form .custom_radio {
    color: #97a2d2;
}
.darkmode .page-header .breadcrumb a {
    color: #97a2d2;
}
.darkmode .breadcrumb-item.active {
    color: #97a2d2;
}
.darkmode .text-muted {
    color: #97a2d2 !important;
}
.darkmode .d-block {
    color: #97a2d2;
}
.darkmode .sorting_1 {
    color: #97a2d2;
}
.darkmode td {
    color: #97a2d2;
}
.darkmode th {
    color: #97a2d2;
}
.btn-light {
    border-color: #e6e6e6;
    color: #a6a6a6;
}
.bootstrap-datetimepicker-widget table td.active,
.bootstrap-datetimepicker-widget table td.active:hover {
    background-color: #7638ff;
    text-shadow: unset;
}
.bootstrap-datetimepicker-widget table td.today:before {
    border-bottom-color: #7638ff;
}
.bg-info-light {
    background-color: rgba(2, 182, 179, 0.12) !important;
    color: #1db9aa !important;
}
.bg-primary-light {
    background-color: rgba(17, 148, 247, 0.12) !important;
    color: #2196f3 !important;
}
.bg-danger-light {
    background-color: rgb(255 218 218 / 49%) !important;
    color: #ff0000 !important;
}
.bg-warning-light {
    background-color: rgba(255, 152, 0, 0.12) !important;
    color: #f39c12 !important;
}
.bg-success-light {
    background-color: #f7f7fa !important;
    border-radius: 50px;
    color: #52536d !important;
}
.actions {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: flex-end;
    -webkit-justify-content: end;
    -ms-flex-pack: end;
}
.actions a {
    width: 32px;
    height: 32px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.actions a:hover {
    background-color: #3d5ee1 !important;
    color: #fff !important;
}
.bg-purple-light {
    background-color: rgba(197, 128, 255, 0.12) !important;
    color: #c580ff !important;
}
.bg-default-light {
    background-color: rgba(40, 52, 71, 0.12) !important;
    color: #283447 !important;
}
.card-two {
    border: 1px solid #dbdfea;
    border-radius: 5px;
}
.badge-soft-primary {
    color: #405189;
    background-color: rgba(64, 81, 137, 0.1);
}
.badge-soft-secondary {
    color: #3577f1;
    background-color: rgba(53, 119, 241, 0.1);
}
.badge-soft-success {
    color: #0ab39c;
    background-color: rgba(10, 179, 156, 0.1);
}
.badge-soft-info {
    color: #299cdb;
    background-color: rgba(41, 156, 219, 0.1);
}
.badge-soft-warning {
    color: #f7b84b;
    background-color: rgba(247, 184, 75, 0.1);
}
.badge-soft-danger {
    color: #f06548;
    background-color: rgba(240, 101, 72, 0.1);
}
.badge-soft-dark {
    color: #212529;
    background-color: rgba(33, 37, 41, 0.1);
}
.badge-soft-light {
    color: #f3f6f9;
    background-color: rgba(243, 246, 249, 0.1);
}
.badge-outline-primary {
    color: #405189;
    border: 1px solid #405189;
    background-color: transparent;
}
.badge-outline-secondary {
    color: #3577f1;
    border: 1px solid #3577f1;
    background-color: transparent;
}
.badge-outline-success {
    color: #0ab39c;
    border: 1px solid #0ab39c;
    background-color: transparent;
}
.badge-outline-info {
    color: #299cdb;
    border: 1px solid #299cdb;
    background-color: transparent;
}
.badge-outline-warning {
    color: #f7b84b;
    border: 1px solid #f7b84b;
    background-color: transparent;
}
.badge-outline-danger {
    color: #f06548;
    border: 1px solid #f06548;
    background-color: transparent;
}
.badge-outline-dark {
    color: #212529;
    border: 1px solid #212529;
    background-color: transparent;
}
.badge-outline-light {
    color: #f3f6f9;
    border: 1px solid #f3f6f9;
    background-color: transparent;
}
.badge-gradient-primary {
    background: linear-gradient(135deg, #405189 0, #0ab39c 100%);
}
.badge-gradient-secondary {
    background: linear-gradient(135deg, #3577f1 0, #299cdb 100%);
}
.badge-gradient-success {
    background: linear-gradient(135deg, #0ab39c 0, #f7b84b 100%);
}
.badge-gradient-danger {
    background: linear-gradient(135deg, #f06548 0, #3577f1 100%);
}
.badge-gradient-warning {
    background: linear-gradient(135deg, #f7b84b 0, #eb990a 100%);
}
.badge-gradient-info {
    background: linear-gradient(135deg, #299cdb 0, #0ab39c 100%);
}
.badge-gradient-dark {
    background: linear-gradient(135deg, #212529 0, #405189 100%);
}
/*-----------------
	3. Helper Class
-----------------------*/

.p-20 {
    padding: 20px !important;
}
.p-t-0 {
    padding-top: 0 !important;
}
.m-0 {
    margin: 0 !important;
}
.m-r-5 {
    margin-right: 5px !important;
}
.m-r-10 {
    margin-right: 10px !important;
}
.m-l-5 {
    margin-left: 5px !important;
}
.m-l-15 {
    margin-left: 15px !important;
}
.m-t-5 {
    margin-top: 5px !important;
}
.m-t-0 {
    margin-top: 0 !important;
}
.m-t-10 {
    margin-top: 10px !important;
}
.m-t-15 {
    margin-top: 15px !important;
}
.m-t-20 {
    margin-top: 20px !important;
}
.m-t-30 {
    margin-top: 30px !important;
}
.m-t-50 {
    margin-top: 50px !important;
}
.m-b-5 {
    margin-bottom: 5px !important;
}
.m-b-10 {
    margin-bottom: 10px !important;
}
.m-b-15 {
    margin-bottom: 15px !important;
}
.m-b-20 {
    margin-bottom: 20px !important;
}
.m-b-30 {
    margin-bottom: 30px !important;
}
.block {
    display: block !important;
}
.table .bg-success-dark {
    background: #008f64;
}
.table .bg-danger-dark {
    background: #ff0000;
}

/*-----------------
	4. Bootstrap Classes
-----------------------*/

.btn.focus,
.btn:focus {
    box-shadow: unset;
}
.btn-block {
    width: 100%;
}
.btn-link {
    text-decoration: none;
}
.btn-link:hover {
    text-decoration: underline;
}
.note-btn {
    padding: 5px 8px;
}
.btn-white {
    background-color: #fff;
    border: 1px solid #ccc;
    color: #333;
}
.btn.btn-rounded {
    border-radius: 50px;
}
.bg-primary {
    background-color: #fff5d9 !important;
}
.pro-bg-primary,
.badge-primary {
    background-color: #fdbb38 !important;
}
.bg-light {
    background-color: #ebebff !important;
}
a.bg-primary:focus,
a.bg-primary:hover,
button.bg-primary:focus,
button.bg-primary:hover {
    background-color: #18aefa !important;
}
.bg-success,
.badge-success {
    background-color: #7bb13c !important;
}
a.bg-success:focus,
a.bg-success:hover,
button.bg-success:focus,
button.bg-success:hover {
    background-color: #699834 !important;
}
.bg-info,
.badge-info {
    background-color: #009efb !important;
}
.pro-bg-info,
.badge-info {
    background-color: #56dccc !important;
}
.pro-bg-light,
.badge-light {
    background-color: #18aefa !important;
}
a.bg-info:focus,
a.bg-info:hover,
button.bg-info:focus,
button.bg-info:hover {
    background-color: #028ee1 !important;
}
.bg-warning,
.badge-warning {
    background-color: #ffbc34 !important;
}
a.bg-warning:focus,
a.bg-warning:hover,
button.bg-warning:focus,
button.bg-warning:hover {
    background-color: #e9ab2e !important;
}
.bg-danger,
.badge-danger {
    background-color: #e84646 !important;
}
a.bg-danger:focus,
a.bg-danger:hover,
button.bg-danger:focus,
button.bg-danger:hover {
    background-color: #e63333 !important;
}
.bg-white {
    background-color: #fff;
}
.bg-purple,
.badge-purple {
    background-color: #9368e9 !important;
}
.text-primary,
.dropdown-menu > li > a.text-primary {
    color: #3d5ee1 !important;
}
.text-success,
.dropdown-menu > li > a.text-success {
    color: #699834 !important;
}
.text-danger,
.dropdown-menu > li > a.text-danger {
    color: #e84646 !important;
}
.text-info,
.dropdown-menu > li > a.text-info {
    color: #009efb !important;
}
.text-warning,
.dropdown-menu > li > a.text-warning {
    color: #ffbc34 !important;
}
.text-purple,
.dropdown-menu > li > a.text-purple {
    color: #7460ee !important;
}
.text-muted {
    color: #757575 !important;
}
.btn-primary {
    background-color: #3d5ee1;
    border: 1px solid #3d5ee1;
}
.btn-primary-save-bg {
    background-color: #18aefa;
}
.btn-primary-save-bg:hover {
    background-color: #3d5ee1;
    border: 1px solid #3d5ee1;
}
.btn-primary:hover,
.btn-primary:focus,
.btn-primary.active,
.btn-primary:active,
.open > .dropdown-toggle.btn-primary {
    background-color: #18aefa;
    border: 1px solid #18aefa;
}
.btn-primary.active.focus,
.btn-primary.active:focus,
.btn-primary.active:hover,
.btn-primary.focus:active,
.btn-primary:active:focus,
.btn-primary:active:hover,
.open > .dropdown-toggle.btn-primary.focus,
.open > .dropdown-toggle.btn-primary:focus,
.open > .dropdown-toggle.btn-primary:hover {
    background-color: #18aefa;
    border: 1px solid #18aefa;
}
.btn-primary.active:not(:disabled):not(.disabled),
.btn-primary:active:not(:disabled):not(.disabled),
.show > .btn-primary.dropdown-toggle {
    background-color: #18aefa;
    border-color: #18aefa;
    color: #fff;
}
.btn-primary.active:focus:not(:disabled):not(.disabled),
.btn-primary:active:focus:not(:disabled):not(.disabled),
.show > .btn-primary.dropdown-toggle:focus {
    box-shadow: unset;
}
.btn-primary.disabled,
.btn-primary:disabled {
    background-color: #3d5ee1;
    border-color: #3d5ee1;
    color: #fff;
}
.btn-secondary.active:focus:not(:disabled):not(.disabled),
.btn-secondary:active:focus:not(:disabled):not(.disabled),
.show > .btn-secondary.dropdown-toggle:focus {
    box-shadow: unset;
}
.btn-success {
    background-color: #7bb13c;
    border: 1px solid #7bb13c;
}
.btn-success:hover,
.btn-success:focus,
.btn-success.active,
.btn-success:active,
.open > .dropdown-toggle.btn-success {
    background-color: #699834;
    border: 1px solid #699834;
    color: #fff;
}
.btn-success.active.focus,
.btn-success.active:focus,
.btn-success.active:hover,
.btn-success.focus:active,
.btn-success:active:focus,
.btn-success:active:hover,
.open > .dropdown-toggle.btn-success.focus,
.open > .dropdown-toggle.btn-success:focus,
.open > .dropdown-toggle.btn-success:hover {
    background-color: #699834;
    border: 1px solid #699834;
}
.btn-success.active:not(:disabled):not(.disabled),
.btn-success:active:not(:disabled):not(.disabled),
.show > .btn-success.dropdown-toggle {
    background-color: #699834;
    border-color: #699834;
    color: #fff;
}
.btn-success.active:focus:not(:disabled):not(.disabled),
.btn-success:active:focus:not(:disabled):not(.disabled),
.show > .btn-success.dropdown-toggle:focus {
    box-shadow: unset;
}
.btn-success.disabled,
.btn-success:disabled {
    background-color: #7bb13c;
    border-color: #7bb13c;
    color: #fff;
}
.btn-info {
    background-color: #009efb;
    border: 1px solid #009efb;
}
.btn-info:hover,
.btn-info:focus,
.btn-info.active,
.btn-info:active,
.open > .dropdown-toggle.btn-info {
    background-color: #028ee1;
    border: 1px solid #028ee1;
}
.btn-info.active.focus,
.btn-info.active:focus,
.btn-info.active:hover,
.btn-info.focus:active,
.btn-info:active:focus,
.btn-info:active:hover,
.open > .dropdown-toggle.btn-info.focus,
.open > .dropdown-toggle.btn-info:focus,
.open > .dropdown-toggle.btn-info:hover {
    background-color: #028ee1;
    border: 1px solid #028ee1;
}
.btn-info.active:not(:disabled):not(.disabled),
.btn-info:active:not(:disabled):not(.disabled),
.show > .btn-info.dropdown-toggle {
    background-color: #028ee1;
    border-color: #028ee1;
    color: #fff;
}
.btn-info.active:focus:not(:disabled):not(.disabled),
.btn-info:active:focus:not(:disabled):not(.disabled),
.show > .btn-info.dropdown-toggle:focus {
    box-shadow: unset;
}
.btn-info.disabled,
.btn-info:disabled {
    background-color: #009efb;
    border-color: #009efb;
    color: #fff;
}
.btn-warning {
    background-color: #ffbc34;
    border: 1px solid #ffbc34;
}
.btn-warning:hover,
.btn-warning:focus,
.btn-warning.active,
.btn-warning:active,
.open > .dropdown-toggle.btn-warning {
    background-color: #e9ab2e;
    border: 1px solid #e9ab2e;
}
.btn-warning.active.focus,
.btn-warning.active:focus,
.btn-warning.active:hover,
.btn-warning.focus:active,
.btn-warning:active:focus,
.btn-warning:active:hover,
.open > .dropdown-toggle.btn-warning.focus,
.open > .dropdown-toggle.btn-warning:focus,
.open > .dropdown-toggle.btn-warning:hover {
    background-color: #e9ab2e;
    border: 1px solid #e9ab2e;
}
.btn-warning.active:not(:disabled):not(.disabled),
.btn-warning:active:not(:disabled):not(.disabled),
.show > .btn-danger.dropdown-toggle {
    background-color: #e9ab2e;
    border-color: #e9ab2e;
    color: #fff;
}
.btn-warning.active:focus:not(:disabled):not(.disabled),
.btn-warning:active:focus:not(:disabled):not(.disabled),
.show > .btn-warning.dropdown-toggle:focus {
    box-shadow: unset;
}
.btn-warning.disabled,
.btn-warning:disabled {
    background-color: #ffbc34;
    border-color: #ffbc34;
    color: #fff;
}
.btn-danger {
    background-color: #e84646;
    border: 1px solid #e84646;
}
.btn-danger:hover,
.btn-danger:focus,
.btn-danger.active,
.btn-danger:active,
.open > .dropdown-toggle.btn-danger {
    background-color: #e63333;
    border: 1px solid #e63333;
}
.btn-danger.active.focus,
.btn-danger.active:focus,
.btn-danger.active:hover,
.btn-danger.focus:active,
.btn-danger:active:focus,
.btn-danger:active:hover,
.open > .dropdown-toggle.btn-danger.focus,
.open > .dropdown-toggle.btn-danger:focus,
.open > .dropdown-toggle.btn-danger:hover {
    background-color: #e63333;
    border: 1px solid #e63333;
}
.btn-danger.active:not(:disabled):not(.disabled),
.btn-danger:active:not(:disabled):not(.disabled),
.show > .btn-danger.dropdown-toggle {
    background-color: #e63333;
    border-color: #e63333;
    color: #fff;
}
.btn-danger.active:focus:not(:disabled):not(.disabled),
.btn-danger:active:focus:not(:disabled):not(.disabled),
.show > .btn-danger.dropdown-toggle:focus {
    box-shadow: unset;
}
.btn-danger.disabled,
.btn-danger:disabled {
    background-color: #f62d51;
    border-color: #f62d51;
    color: #fff;
}
.btn-light.active:focus:not(:disabled):not(.disabled),
.btn-light:active:focus:not(:disabled):not(.disabled),
.show > .btn-light.dropdown-toggle:focus {
    box-shadow: unset;
}
.btn-dark.active:focus:not(:disabled):not(.disabled),
.btn-dark:active:focus:not(:disabled):not(.disabled),
.show > .btn-dark.dropdown-toggle:focus {
    box-shadow: unset;
}
.download-grp .btn-outline-primary {
    background-color: #3d5ee1;
    border-color: #3d5ee1;
    color: #fff;
}
.btn-outline-gray {
    border: 2px solid #edf1f1;
    width: 38px;
    height: 38px;
    display: inline-flex;
    padding: 0;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
}
.btn-outline-gray.active {
    background-color: #3d5ee1;
    border-color: #3d5ee1;
    color: #fff;
}
.btn-outline-primary {
    color: #3d5ee1;
    border-color: #3d5ee1;
}
.btn-outline-primary:hover,
.btn-outline-gray:hover {
    background-color: #18aefa;
    border-color: #18aefa;
    color: #fff;
}
.btn-outline-primary:focus,
.btn-outline-primary.focus {
    box-shadow: none;
}
.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
    color: #3d5ee1;
    background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
    background-color: #3d5ee1;
    border-color: #3d5ee1;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
    box-shadow: none;
}
.btn-outline-success {
    color: #7bb13c;
    border-color: #7bb13c;
}
.btn-outline-success:hover {
    background-color: #7bb13c;
    border-color: #7bb13c;
}
.btn-outline-success:focus,
.btn-outline-success.focus {
    box-shadow: none;
}
.btn-outline-success.disabled,
.btn-outline-success:disabled {
    color: #7bb13c;
    background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled):active,
.btn-outline-success:not(:disabled):not(.disabled).active,
.show > .btn-outline-success.dropdown-toggle {
    background-color: #7bb13c;
    border-color: #7bb13c;
}
.btn-outline-success:not(:disabled):not(.disabled):active:focus,
.btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-success.dropdown-toggle:focus {
    box-shadow: none;
}
.btn-outline-info {
    color: #009efb;
    border-color: #009efb;
}
.btn-outline-info:hover {
    color: #fff;
    background-color: #009efb;
    border-color: #009efb;
}
.btn-outline-info:focus,
.btn-outline-info.focus {
    box-shadow: none;
}
.btn-outline-info.disabled,
.btn-outline-info:disabled {
    background-color: transparent;
    color: #009efb;
}
.btn-outline-info:not(:disabled):not(.disabled):active,
.btn-outline-info:not(:disabled):not(.disabled).active,
.show > .btn-outline-info.dropdown-toggle {
    background-color: #009efb;
    border-color: #009efb;
}
.btn-outline-info:not(:disabled):not(.disabled):active:focus,
.btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-info.dropdown-toggle:focus {
    box-shadow: none;
}
.btn-outline-warning {
    color: #ffbc34;
    border-color: #ffbc34;
}
.btn-outline-warning:hover {
    color: #212529;
    background-color: #ffbc34;
    border-color: #ffbc34;
}
.btn-outline-warning:focus,
.btn-outline-warning.focus {
    box-shadow: none;
}
.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
    background-color: transparent;
    color: #ffbc34;
}
.btn-outline-warning:not(:disabled):not(.disabled):active,
.btn-outline-warning:not(:disabled):not(.disabled).active,
.show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ffbc34;
    border-color: #ffbc34;
}
.btn-outline-warning:not(:disabled):not(.disabled):active:focus,
.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-warning.dropdown-toggle:focus {
    box-shadow: none;
}
.btn-outline-danger {
    color: #e84646;
    border-color: #e84646;
}
.btn-outline-danger:hover {
    color: #fff;
    background-color: #e84646;
    border-color: #e84646;
}
.btn-outline-danger:focus,
.btn-outline-danger.focus {
    box-shadow: none;
}
.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
    background-color: transparent;
    color: #e84646;
}
.btn-outline-danger:not(:disabled):not(.disabled):active,
.btn-outline-danger:not(:disabled):not(.disabled).active,
.show > .btn-outline-danger.dropdown-toggle {
    background-color: #e84646;
    border-color: #e84646;
}
.btn-outline-danger:not(:disabled):not(.disabled):active:focus,
.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus {
    box-shadow: none;
}
.btn-outline-light {
    color: #ababab;
    border-color: #e6e6e6;
}
.btn-outline-light.disabled,
.btn-outline-light:disabled {
    color: #ababab;
}
.pagination > .active > a,
.pagination > .active > a:focus,
.pagination > .active > a:hover,
.pagination > .active > span,
.pagination > .active > span:focus,
.pagination > .active > span:hover {
    background-color: #3d5ee1;
    border-color: #3d5ee1;
}
.pagination > li > a,
.pagination > li > span {
    color: #3d5ee1;
}
.page-link:hover {
    color: #3d5ee1;
}
.page-link:focus {
    box-shadow: unset;
}
.page-item.active .page-link {
    background-color: #3d5ee1;
    border-color: #3d5ee1;
}
.dropdown-menu {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    transform-origin: left top 0;
    box-shadow: inherit;
    background-color: #fff;
}
.dropdown-item.active,
.dropdown-item:active {
    background-color: #3d5ee1;
}
.navbar-nav .open .dropdown-menu {
    border: 0;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: #fff;
}
.dropdown-menu {
    font-size: 14px;
}
.card {
    border: 0;
    border-radius: 10px;
    box-shadow: 0px 0px 31px 3px rgb(44 50 63 / 2%);
    margin-bottom: 1.875rem;
}
.card-body {
    padding: 1.5rem;
}
.card-body {
    position: relative;
    padding: 25px;
}
.card-header {
    border-bottom: 1px solid #e6e6e6;
    padding: 1rem 1.5rem;
}
.card-footer {
    background-color: #fff;
    border-top: 1px solid #e6e6e6;
    padding: 1rem 1.5rem;
}
.card .card-header {
    background-color: #fff;
    border-bottom: 1px solid #eaeaea;
    padding: 15px;
}
.card .card-header .card-title {
    margin-bottom: 0;
    font-size: 18px;
}
.modal-footer.text-left {
    text-align: left;
}
.modal-footer.text-center {
    text-align: center;
}
.btn-light {
    border-color: #e6e6e6;
    color: #a6a6a6;
}
.bootstrap-datetimepicker-widget table td.active,
.bootstrap-datetimepicker-widget table td.active:hover {
    background-color: #3d5ee1;
    text-shadow: unset;
}
.bootstrap-datetimepicker-widget table td.today:before {
    border-bottom-color: #ffbc53;
}
.bg-info-light {
    background-color: rgba(2, 182, 179, 0.12) !important;
    color: #1db9aa !important;
}
.bg-primary-light {
    background-color: rgba(17, 148, 247, 0.12) !important;
    color: #2196f3 !important;
}
.bg-danger-light {
    background-color: #f7f7fa !important;
    color: #52536d !important;
    border-radius: 50px;
}
.bg-warning-light {
    background-color: rgba(255, 152, 0, 0.12) !important;
    color: #f39c12 !important;
}
.bg-success-light {
    background-color: #18aefa;
    color: #fff;
}
.bg-purple-light {
    background-color: rgba(197, 128, 255, 0.12) !important;
    color: #c580ff !important;
}
.bg-default-light {
    background-color: rgba(40, 52, 71, 0.12) !important;
    color: #283447 !important;
}
.bg-success-light:hover {
    color: #fff;
}
div.dataTables_wrapper div.dataTables_paginate ul.pagination {
    margin: 15px 0 0;
}
div.dataTables_wrapper div.dataTables_info {
    padding-top: 25px;
}
div.dataTables_wrapper div.dataTables_length select {
    appearance: auto;
}
.page-title {
    color: #333;
    margin-bottom: 5px;
}
.navtab-bg .nav-link {
    background-color: #edeff1;
    color: #455560;
}
.navtab-bg .nav-item {
    margin: 0 5px;
}
/*-----------------
	5. Select2
-----------------------*/

.select2-container .select2-selection--single {
    border: 1px solid #ddd;
    height: 45px;
}
.select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 43px;
    right: 7px;
}
.select2-container--default .select2-selection--single .select2-selection__arrow b {
    border-color: #000 transparent transparent;
    border-style: solid;
    border-width: 6px 6px 0;
    height: 0;
    left: 50%;
    margin-left: -10px;
    margin-top: -2px;
    position: absolute;
    top: 50%;
    width: 0;
}
.select2-container--default.select2-container--open
    .select2-selection--single
    .select2-selection__arrow
    b {
    border-color: transparent transparent #000;
    border-width: 0 6px 6px;
}
.select2-container .select2-selection--single .select2-selection__rendered {
    padding-right: 30px;
    padding-left: 15px;
}
.select2-container--default .select2-selection--single .select2-selection__rendered {
    color: #333;
    font-size: 14px;
    font-weight: normal;
    line-height: 43px;
}
.select2-container--default .select2-results__option--highlighted[aria-selected] {
    background-color: #18aefa;
}
.select2-container--default .select2-selection--multiple {
    border: 1px solid #ddd;
    min-height: 40px;
}

/*-----------------
	6. Nav Tabs
-----------------------*/

.nav-tabs {
    border-bottom: 1px solid #e6e6e6;
}
.card-header-tabs {
    border-bottom: 0;
}
.nav-tabs > li > a {
    margin-right: 0;
    color: #888;
    border-radius: 0;
}
.nav-tabs > li > a:hover,
.nav-tabs > li > a:focus {
    border-color: transparent;
    color: #333;
}
.nav-tabs.nav-tabs-solid > li > a {
    color: #333;
}
.nav-tabs.nav-tabs-solid > .active > a,
.nav-tabs.nav-tabs-solid > .active > a:hover,
.nav-tabs.nav-tabs-solid > .active > a:focus {
    background-color: #3d5ee1;
    border-color: #3d5ee1;
    color: #fff;
}
.tab-content {
    padding-top: 20px;
}
.nav-tabs .nav-link {
    border-radius: 0;
}
.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
    background-color: #eee;
    border-color: transparent;
    color: #333;
}
.nav-tabs.nav-justified > li > a {
    border-radius: 0;
    margin-bottom: 0;
}
.nav-tabs.nav-justified > li > a:hover,
.nav-tabs.nav-justified > li > a:focus {
    border-bottom-color: #ddd;
}
.nav-tabs.nav-justified.nav-tabs-solid > li > a {
    border-color: transparent;
}
.nav-tabs.nav-tabs-solid > li > a {
    color: #333;
}
.nav-tabs.nav-tabs-solid > li > a.active,
.nav-tabs.nav-tabs-solid > li > a.active:hover,
.nav-tabs.nav-tabs-solid > li > a.active:focus {
    background-color: #3d5ee1;
    border-color: #3d5ee1;
    color: #fff;
}
.nav-tabs.nav-tabs-solid.nav-tabs-rounded {
    border-radius: 50px;
}
.nav-tabs.nav-tabs-solid.nav-tabs-rounded > li > a {
    border-radius: 50px;
}
.nav-tabs.nav-tabs-solid.nav-tabs-rounded > li > a.active,
.nav-tabs.nav-tabs-solid.nav-tabs-rounded > li > a.active:hover,
.nav-tabs.nav-tabs-solid.nav-tabs-rounded > li > a.active:focus {
    border-radius: 50px;
}
.nav-tabs-justified > li > a {
    border-radius: 0;
    margin-bottom: 0;
}
.nav-tabs-justified > li > a:hover,
.nav-tabs-justified > li > a:focus {
    border-bottom-color: #ddd;
}
.nav-tabs-justified.nav-tabs-solid > li > a {
    border-color: transparent;
}
.nav-tabs.nav-justified.nav-tabs-top {
    border-bottom: 1px solid #ddd;
}
.nav-tabs.nav-justified.nav-tabs-top > li > a,
.nav-tabs.nav-justified.nav-tabs-top > li > a:hover,
.nav-tabs.nav-justified.nav-tabs-top > li > a:focus {
    border-width: 2px 0 0 0;
}
.nav-tabs.nav-tabs-top > li {
    margin-bottom: 0;
}
.nav-tabs.nav-tabs-top > li > a,
.nav-tabs.nav-tabs-top > li > a:hover,
.nav-tabs.nav-tabs-top > li > a:focus {
    border-width: 2px 0 0 0;
}
.nav-tabs.nav-tabs-top > li.open > a,
.nav-tabs.nav-tabs-top > li > a:hover,
.nav-tabs.nav-tabs-top > li > a:focus {
    border-top-color: #ddd;
}
.nav-tabs.nav-tabs-top > li + li > a {
    margin-left: 1px;
}
.nav-tabs.nav-tabs-top > li > a.active,
.nav-tabs.nav-tabs-top > li > a.active:hover,
.nav-tabs.nav-tabs-top > li > a.active:focus {
    border-top-color: #3d5ee1;
}
.nav-tabs.nav-tabs-bottom > li {
    margin-bottom: -1px;
}
.nav-tabs.nav-tabs-bottom > li > a.active,
.nav-tabs.nav-tabs-bottom > li > a.active:hover,
.nav-tabs.nav-tabs-bottom > li > a.active:focus {
    border-bottom-width: 2px;
    border-color: transparent;
    border-bottom-color: #3d5ee1;
    background-color: transparent;
    transition: none 0s ease 0s;
    -moz-transition: none 0s ease 0s;
    -o-transition: none 0s ease 0s;
    -ms-transition: none 0s ease 0s;
    -webkit-transition: none 0s ease 0s;
}
.nav-tabs.nav-tabs-solid {
    background-color: #fafafa;
    border: 0;
}
.nav-tabs.nav-tabs-solid > li {
    margin-bottom: 0;
}
.nav-tabs.nav-tabs-solid > li > a {
    border-color: transparent;
}
.nav-tabs.nav-tabs-solid > li > a:hover,
.nav-tabs.nav-tabs-solid > li > a:focus {
    background-color: #f5f5f5;
}
.nav-tabs.nav-tabs-solid > .open:not(.active) > a {
    background-color: #f5f5f5;
    border-color: transparent;
}
.nav-tabs-justified.nav-tabs-top {
    border-bottom: 1px solid #ddd;
}
.nav-tabs-justified.nav-tabs-top > li > a,
.nav-tabs-justified.nav-tabs-top > li > a:hover,
.nav-tabs-justified.nav-tabs-top > li > a:focus {
    border-width: 2px 0 0 0;
}

/*-----------------
	7. Components
-----------------------*/

.section-header {
    margin-bottom: 1.875rem;
}
.section-header .section-title {
    color: #333;
}
.line {
    background-color: #18aefa;
    height: 2px;
    margin: 0;
    width: 60px;
}
.comp-buttons .btn {
    margin-bottom: 5px;
}
.pagination-box .pagination {
    margin-top: 0;
}
.comp-dropdowns .btn-group {
    margin-bottom: 5px;
}
.progress-example .progress {
    margin-bottom: 1.5rem;
}
.progress-xs {
    height: 4px;
}
.progress-sm {
    height: 15px;
}
.progress.progress-sm {
    height: 6px;
}
.progress.progress-md {
    height: 8px;
}
.progress.progress-lg {
    height: 18px;
}
.row.row-sm {
    margin-left: -3px;
    margin-right: -3px;
}
.row.row-sm > div {
    padding-left: 3px;
    padding-right: 3px;
}
.avatar {
    position: relative;
    display: inline-block;
    width: 3rem;
    height: 3rem;
}
.avatar > img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
}
.avatar-title {
    width: 100%;
    height: 100%;
    background-color: #3d5ee1;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}
.avatar-away:before,
.avatar-offline:before,
.avatar-online:before {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 25%;
    height: 25%;
    border-radius: 50%;
    content: "";
    border: 2px solid #fff;
}
.avatar-online:before {
    background-color: #7bb13c;
}
.avatar-offline:before {
    background-color: #e84646;
}
.avatar-away:before {
    background-color: #ffbc34;
}
.avatar .border {
    border-width: 3px !important;
}
.avatar .rounded {
    border-radius: 6px !important;
}
.avatar .avatar-title {
    font-size: 18px;
}
.avatar-xs {
    width: 1.65rem;
    height: 1.65rem;
}
.avatar-xs .border {
    border-width: 2px !important;
}
.avatar-xs .rounded {
    border-radius: 4px !important;
}
.avatar-xs .avatar-title {
    font-size: 12px;
}
.avatar-xs.avatar-away:before,
.avatar-xs.avatar-offline:before,
.avatar-xs.avatar-online:before {
    border-width: 1px;
}
.avatar-sm {
    width: 2.5rem;
    height: 2.5rem;
}
.avatar-sm .border {
    border-width: 3px !important;
}
.avatar-sm .rounded {
    border-radius: 4px !important;
}
.avatar-sm .avatar-title {
    font-size: 15px;
}
.avatar-sm.avatar-away:before,
.avatar-sm.avatar-offline:before,
.avatar-sm.avatar-online:before {
    border-width: 2px;
}
.avatar-lg {
    width: 3.75rem;
    height: 3.75rem;
}
.avatar-lg .border {
    border-width: 3px !important;
}
.avatar-lg .rounded {
    border-radius: 8px !important;
}
.avatar-lg .avatar-title {
    font-size: 24px;
}
.avatar-lg.avatar-away:before,
.avatar-lg.avatar-offline:before,
.avatar-lg.avatar-online:before {
    border-width: 3px;
}
.avatar-xl {
    width: 5rem;
    height: 5rem;
}
.avatar-xl .border {
    border-width: 4px !important;
}
.avatar-xl .rounded {
    border-radius: 8px !important;
}
.avatar-xl .avatar-title {
    font-size: 28px;
}
.avatar-xl.avatar-away:before,
.avatar-xl.avatar-offline:before,
.avatar-xl.avatar-online:before {
    border-width: 4px;
}
.avatar-xxl {
    width: 5.125rem;
    height: 5.125rem;
}
.avatar-xxl .border {
    border-width: 6px !important;
}
.avatar-xxl .rounded {
    border-radius: 8px !important;
}
.avatar-xxl .avatar-title {
    font-size: 30px;
}
.avatar-xxl.avatar-away:before,
.avatar-xxl.avatar-offline:before,
.avatar-xxl.avatar-online:before {
    border-width: 4px;
}
.avatar-group {
    display: inline-flex;
}
.avatar-group .avatar + .avatar {
    margin-left: -0.75rem;
}
.avatar-group .avatar-xs + .avatar-xs {
    margin-left: -0.40625rem;
}
.avatar-group .avatar-sm + .avatar-sm {
    margin-left: -0.625rem;
}
.avatar-group .avatar-lg + .avatar-lg {
    margin-left: -1rem;
}
.avatar-group .avatar-xl + .avatar-xl {
    margin-left: -1.28125rem;
}
.avatar-group .avatar:hover {
    z-index: 1;
}
.alert {
    position: relative;
    padding: 0.75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;
}
.alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    padding: 0.75rem 1.25rem;
    color: inherit;
    border: none;
    background: transparent;
}
.close {
    float: right;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: 0.5;
}
.swal2-actions button {
    margin-right: 10px;
}
.swal2-title {
    font-size: 22px !important;
}

/*-----------------
	8. Header
-----------------------*/

.header {
    background-color: #fff;
    border-bottom: 1px solid #f3f3f4;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 1001;
    height: 60px;
}
.header .header-left {
    float: left;
    height: 60px;
    padding: 0 20px;
    position: relative;
    width: 259px;
    z-index: 1;
    transition: all 0.2s ease-in-out;
    display: flex;
    align-items: center;
    background: #ffffff;
    box-shadow: 0px 0px 44px rgba(0, 0, 0, 0.03);
}
.mini-sidebar .header .header-left {
    justify-content: center;
}
.header .header-left .logo {
    display: inline-block;
    line-height: 60px;
}
.header .header-left .logo img {
    max-height: 40px;
    width: auto;
}
.header-left .logo.logo-small {
    display: none;
}
.header .dropdown-menu > li > a {
    position: relative;
}
.header .dropdown-toggle:after {
    display: none;
}
.header .has-arrow .dropdown-toggle:after {
    border-top: 0;
    border-left: 0;
    border-bottom: 2px solid #7e84a3;
    border-right: 2px solid #7e84a3;
    content: "";
    height: 8px;
    display: inline-block;
    pointer-events: none;
    -webkit-transform-origin: 66% 66%;
    -ms-transform-origin: 66% 66%;
    transform-origin: 66% 66%;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-transition: all 0.15s ease-in-out;
    transition: all 0.15s ease-in-out;
    width: 8px;
    vertical-align: 0px;
}
.header .has-arrow .dropdown-toggle[aria-expanded="true"]:after {
    -webkit-transform: rotate(-135deg);
    -ms-transform: rotate(-135deg);
    transform: rotate(-135deg);
}
.user-menu {
    float: right;
    margin: 0;
    position: relative;
    z-index: 99;
    padding-right: 15px;
    align-items: center;
}
.user-menu.nav > li > a {
    color: #a0a0a0;
    font-size: 14px;
    line-height: 58px;
    padding: 0 15px;
    height: 60px;
}
.user-menu.nav > li > a:hover,
.user-menu.nav > li > a:focus {
    background-color: rgb(232 232 232 / 20%);
}
.user-menu.nav > li > a:hover i,
.user-menu.nav > li > a:focus i {
    color: #18aefa;
}
.user-img {
    margin-right: 3px;
    position: relative;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.new-user-menus .nav-link {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.user-img .user-text {
    margin: 0px 8px;
}
.user-img .user-text .text-muted {
    line-height: 1.2;
    font-size: 12px;
    font-weight: 500;
    color: #3d5ee1;
}
.user-img .user-text h6 {
    font-size: 14px;
    font-weight: 500;
    color: #1d1d1d;
    margin-bottom: 3px;
}
.user-img img {
    width: 40px;
    height: 40px;
    border: 2px solid #e6e6e6;
    padding: 2px;
}
.user-menu.nav > li > a.mobile_btn {
    border: 0;
    position: relative;
    padding: 0;
    margin: 0;
    cursor: pointer;
}
.user-menu .dropdown-menu {
    min-width: 200px;
    padding: 0;
    visibility: hidden;
    opacity: 0;
    display: block;
    transform: translate(0px, 100px);
}
.user-menu .dropdown-menu.show {
    visibility: visible;
    opacity: 1;
    margin-top: 0;
    transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
}
.user-menu .dropdown-menu .dropdown-item {
    padding: 7px 15px;
}
.user-menu .dropdown-menu .dropdown-item {
    display: flex;
    align-items: center;
    border-top: 1px solid #e3e3e3;
    padding: 10px 15px;
}
.user-menu .dropdown-menu .dropdown-item:hover {
    color: #3d5ee1;
}
.header .dropdown-menu > li > a:focus,
.header .dropdown-menu > li > a:hover {
    background-color: #3d5ee1;
    color: #fff;
}
.header .dropdown-menu > li > a:focus i,
.header .dropdown-menu > li > a:hover i {
    color: #fff;
}
.header .dropdown-menu > li > a {
    padding: 10px 18px;
}
.header .dropdown-menu > li > a i {
    color: #3d5ee1;
    margin-right: 10px;
    text-align: center;
    width: 18px;
}
.header .user-menu .dropdown-menu > li > a i {
    color: #3d5ee1;
    font-size: 16px;
    margin-right: 10px;
    min-width: 18px;
    text-align: center;
}
.header .user-menu .dropdown-menu > li > a:focus i,
.header .user-menu .dropdown-menu > li > a:hover i {
    color: #fff;
}
.mobile_btn {
    display: none;
    float: left;
}
.slide-nav .sidebar {
    margin-left: 0;
}
.user-header {
    background-color: #f9f9f9;
    display: flex;
    padding: 10px 15px;
}
.user-header .user-text {
    margin-left: 10px;
}
.user-header .user-text h6 {
    margin-bottom: 2px;
}
.menu-title {
    color: #a3a3a3;
    display: block;
    font-size: 14px;
    margin-bottom: 5px;
    padding: 0 25px;
}
.sidebar-overlay {
    background-color: rgba(0, 0, 0, 0.6);
    display: none;
    height: 100%;
    left: 0;
    position: fixed;
    top: 60px;
    width: 100%;
    z-index: 1000;
}
.sidebar-overlay.opened {
    display: block;
}
html.menu-opened {
    overflow: hidden;
}
html.menu-opened body {
    overflow: hidden;
}
.top-nav-search {
    float: left;
    margin-left: 55px;
}
.top-nav-search form {
    margin-top: 10px;
    position: relative;
    width: 300px;
}
.top-nav-search .form-control {
    background: #f5f5f5;
    border: 1px solid #d7d9de;
    border-radius: 8px;
    color: #6c757d;
    height: 40px;
    padding: 10px 15px 10px 40px;
}
.top-nav-search .btn {
    background-color: transparent;
    border-color: transparent;
    color: rgba(17, 17, 17, 0.32);
    min-height: 40px;
    padding: 7px 15px;
    position: absolute;
    left: 0;
    top: 0;
}
.top-nav-search .form-control::-webkit-input-placeholder {
    color: rgba(17, 17, 17, 0.32);
    font-weight: 500;
}
.top-nav-search .form-control::-moz-placeholder {
    color: rgba(17, 17, 17, 0.32);
    font-weight: 500;
}
.top-nav-search .form-control:-ms-input-placeholder {
    color: rgba(17, 17, 17, 0.32);
    font-weight: 500;
}
.top-nav-search .form-control::-ms-input-placeholder {
    color: rgba(17, 17, 17, 0.32);
    font-weight: 500;
}
.top-nav-search .form-control::placeholder {
    color: rgba(17, 17, 17, 0.32);
    font-weight: 500;
}
.top-nav-search.active form {
    display: block;
    left: 0;
    position: absolute;
}
.user-menu.nav .header-nav-list {
    width: 40px;
    height: 40px;
    background: #f7f7fa;
    border-radius: 50px;
    padding: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
}
.user-menu.nav .header-nav-list i {
    font-size: 20px;
}
.language-drop .noti-content {
    width: 200px;
    overflow-y: auto;
    position: relative;
}
.language-drop .dropdown-menu {
    inset: 0px 0 auto auto !important;
}

/*-----------------
	9. Footer
-----------------------*/

footer {
    padding: 17px 0px;
    background: #fff;
    text-align: center;
}
footer p {
    color: #808191;
    font-size: 14px;
    margin-bottom: 0;
    text-transform: uppercase;
}

/*-----------------
	10. Sidebar
-----------------------*/

.sidebar {
    bottom: 0;
    left: 0;
    margin-top: 0;
    position: fixed;
    top: 60px;
    transition: all 0.2s ease-in-out 0s;
    width: 259px;
    z-index: 1001;
    background: #ffffff;
}
.sidebar.opened {
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    transition: all 0.4s ease;
}
.sidebar-inner {
    height: 100%;
    min-height: 100%;
    transition: all 0.2s ease-in-out 0s;
}
.sidebar-menu {
    padding: 15px;
}
.sidebar-menu ul {
    font-size: 15px;
    list-style-type: none;
    margin: 0;
    padding: 0;
    position: relative;
}
.sidebar-menu li a {
    color: #6f6f6f;
    display: block;
    font-size: 16px;
    height: auto;
    padding: 0 20px;
}
.sidebar-menu li a:hover {
    color: #fff;
    background: #3d5ee1;
    border-radius: 6px;
}
.sidebar-menu > ul > li > a:hover {
    background-color: #fff;
    color: #3d5ee1;
    margin: 0px -15px;
    padding-left: 30px;
}
.sidebar-menu li.active > a {
    background-color: #fff;
    color: #3d5ee1;
    position: relative;
    margin: 0px -15px;
    padding-left: 30px;
}
.sidebar-menu li.active > a::before {
    width: 6px;
    content: "";
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: #3d5ee1;
    -webkit-transition: all 0.5s;
    -ms-transition: all 0.5s;
    transition: all 0.5s;
    border-radius: 0px 8px 8px 0px;
}
.menu-title {
    color: #9e9e9e;
    display: flex;
    font-size: 14px;
    opacity: 1;
    padding: 5px 15px;
    white-space: nowrap;
}
.menu-title > i {
    float: right;
    line-height: 40px;
}
.sidebar-menu li.menu-title a {
    color: #ff9b44;
    display: inline-block;
    margin-left: auto;
    padding: 0;
}
.sidebar-menu li.menu-title a.btn {
    color: #fff;
    display: block;
    float: none;
    font-size: 15px;
    margin-bottom: 15px;
    padding: 10px 15px;
}
.sidebar-menu ul ul a.active {
    color: #fff;
    background: #3d5ee1;
    border-radius: 6px;
}
.mobile_btn {
    display: none;
    float: left;
}
.sidebar .sidebar-menu > ul > li > a span {
    transition: all 0.2s ease-in-out 0s;
    display: inline-block;
    margin-left: 10px;
    white-space: nowrap;
}
.sidebar .sidebar-menu > ul > li > a span.chat-user {
    margin-left: 0;
    overflow: hidden;
    text-overflow: ellipsis;
}
.sidebar .sidebar-menu > ul > li > a span.badge {
    margin-left: auto;
}
.sidebar-menu ul ul a {
    display: block;
    font-size: 15px;
    padding: 7px 10px 7px 30px;
    position: relative;
    margin-bottom: 2px;
}
.sidebar-menu ul ul {
    display: none;
    margin-left: 15px;
}
.sidebar-menu ul ul ul a {
    padding-left: 65px;
}
.sidebar-menu ul ul ul ul a {
    padding-left: 85px;
}
.sidebar-menu > ul > li {
    margin-bottom: 3px;
    position: relative;
}
.sidebar-menu > ul > li:last-child {
    margin-bottom: 0;
}
.sidebar-menu .menu-arrow {
    -webkit-transition: -webkit-transform 0.15s;
    -o-transition: -o-transform 0.15s;
    transition: transform 0.15s;
    position: absolute;
    right: 15px;
    display: inline-block;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    text-rendering: auto;
    line-height: 40px;
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    line-height: 18px;
    top: 14px;
}
.sidebar-menu .menu-arrow:before {
    content: "\f105";
}
.sidebar-menu li a.subdrop .menu-arrow {
    -ms-transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
}
.sidebar-menu ul ul a .menu-arrow {
    top: 10px;
}
.sidebar-menu > ul > li > a {
    align-items: center;
    display: flex;
    justify-content: flex-start;
    padding: 12px 15px;
    position: relative;
    transition: all 0.2s ease-in-out 0s;
}
.sidebar-menu ul li a i {
    display: inline-block;
    font-size: 16px;
    line-height: 24px;
    text-align: left;
    vertical-align: middle;
    width: 20px;
    transition: all 0.2s ease-in-out 0s;
}
.sidebar-menu ul li.menu-title a i {
    font-size: 16px !important;
    margin-right: 0;
    text-align: right;
    width: auto;
}
.sidebar-menu li a > .badge {
    color: #fff;
}
.right-side-views {
    display: none;
}

/*-----------------
	11. Content
-----------------------*/

.main-wrapper {
    width: 100%;
    height: 100vh;
    min-height: 100vh;
}
.page-wrapper {
    margin-left: 259px;
    padding-top: 60px;
    position: relative;
    transition: all 0.4s ease;
}
.page-wrapper > .content {
    padding: 1.875rem 1.875rem 0;
}
.page-header {
    margin-bottom: 1.875rem;
}
.page-header .breadcrumb {
    background-color: transparent;
    color: #6c757d;
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: 0;
    padding: 0;
    margin-left: auto;
}
.page-header .breadcrumb a {
    color: #333;
}
.page-title {
    font-size: 22px;
    font-weight: 500;
    color: #2c323f;
    margin-bottom: 5px;
}
.breadcrumb {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 0.75rem 1rem;
    margin-bottom: 1rem;
    list-style: none;
    background-color: #e9ecef;
    border-radius: 0.25rem;
}
.breadcrumb.pre-breadcrumb {
    padding: 0;
    background-color: transparent;
}
.page-sub-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.page-sub-header .breadcrumb {
    margin-left: auto;
}
.page-header .col {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

/*-----------------
	12. Login
-----------------------*/
.feather.feather-eye {
    width: 15px;
}
.login-body {
    display: table;
    height: 100vh;
    min-height: 100vh;
}
.login-wrapper {
    width: 100%;
    height: 100%;
    display: table-cell;
    vertical-align: middle;
}
.login-wrapper .loginbox {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    display: flex;
    margin: 1.875rem auto;
    max-width: 800px;
    min-height: 500px;
    width: 100%;
}
.login-wrapper .loginbox .login-left {
    align-items: center;
    background: #18aefa;
    flex-direction: column;
    justify-content: flex-end;
    width: 400px;
    display: flex;
    background-blend-mode: multiply;
    border-radius: 8px 20px 20px 8px;
    position: relative;
}
.login-wrapper .loginbox .login-left:before {
    content: "";
    background-image: url(../img/login-icon-01.png);
    position: absolute;
    top: 0;
    left: 0;
    width: 144px;
    height: 144px;
}
.login-wrapper .loginbox .login-left:after {
    content: "";
    background-image: url(../img/login-icon-02.png);
    position: absolute;
    top: 0;
    right: 0;
    width: 144px;
    height: 144px;
}
.login-wrapper .loginbox .login-right {
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 40px;
    width: 400px;
}
.login-wrapper .loginbox .login-right .login-right-wrap {
    max-width: 100%;
    flex: 0 0 100%;
}
.login-wrapper .loginbox .login-right .login-right-wrap .form-group {
    position: relative;
}
.login-wrapper .loginbox .login-right .login-right-wrap .form-group label {
    font-size: 13px;
    color: #ababab;
    font-weight: 500;
    position: absolute;
    top: -10px;
    left: 10px;
    background: #fff;
    margin-bottom: 0;
    padding: 0px 5px;
}
.login-danger,
.star-red {
    color: #ff0000;
}
.login-wrapper .loginbox .login-right h1 {
    font-size: 25px;
    font-weight: 600;
    margin-bottom: 5px;
    text-align: left;
}
.account-subtitle {
    color: #aaaaaa;
    font-size: 16px;
    margin-bottom: 1.875rem;
    text-align: left;
}
.account-subtitle a {
    color: #18aefa;
}
.login-wrapper .loginbox .login-right h2 {
    font-size: 23px;
    font-weight: 500;
    color: #000;
    margin-bottom: 28px;
}
.login-wrapper .loginbox .login-right .forgotpass {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 20px;
}
.remember-me .custom_check {
    color: #878787;
    font-size: 14px;
    font-weight: 400;
}
.remember-me .custom_check .checkmark {
    width: 20px;
    height: 20px;
    top: 0px;
    border: 1px solid #c1ccdb;
}
.login-wrapper .loginbox .login-right .forgotpass a {
    color: #18aefa;
    font-size: 15px;
    margin-left: auto;
}
.login-wrapper .loginbox .login-right .forgotpass a:hover {
    color: #333;
    text-decoration: underline;
}
.login-wrapper .loginbox .login-right .dont-have {
    color: #a0a0a0;
    margin: 20px 0px;
}
.login-wrapper .loginbox .login-right .dont-have a {
    color: #18aefa;
    font-weight: 500;
}
.login-wrapper .loginbox .login-right .dont-have a:hover {
    text-decoration: underline;
}
.social-login {
    text-align: center;
}
.social-login > span {
    color: #a0a0a0;
    margin-right: 8px;
}
.social-login > a {
    background-color: #ccc;
    border-radius: 4px;
    color: #000;
    display: inline-block;
    font-size: 18px;
    height: 32px;
    line-height: 32px;
    margin-right: 6px;
    text-align: center;
    width: 32px;
}
.social-login > a:hover {
    background-color: #3d5ee1;
    color: #fff;
}
.social-login > a i.fa-twitter,
.social-login > a i.fa-linkedin-in {
    color: #000;
}
.social-login > a i.fa-twitter:hover,
.social-login > a i.fa-linkedin-in:hover {
    color: #fff;
}
.social-login > a:last-child {
    margin-right: 0;
}
.social-login > a.facebook {
    background-color: #4b75bd;
}
.social-login > a.google {
    background-color: #fe5240;
}
.login-or {
    color: #a0a0a0;
    margin-bottom: 20px;
    margin-top: 20px;
    padding-bottom: 10px;
    padding-top: 10px;
    position: relative;
}
.or-line {
    background-color: #e5e5e5;
    height: 1px;
    margin-bottom: 0;
    margin-top: 0;
    display: block;
}
.span-or {
    background-color: #fff;
    display: block;
    left: 50%;
    margin-left: -20px;
    position: absolute;
    text-align: center;
    text-transform: uppercase;
    top: 0;
    width: 42px;
}
.btn-primary.primary-reset {
    background-color: #18aefa;
    border-color: #18aefa;
}
.btn-primary.primary-reset:hover {
    background-color: #3d5ee1;
    border: 1px solid #3d5ee1;
}
.profile-views {
    position: absolute;
    right: 17px;
    top: 50%;
    transform: translateY(-50%);
}

/*-----------------
	13. Notifications
-----------------------*/

.notifications {
    padding: 0;
}
.notifications .notification-time {
    font-size: 12px;
    line-height: 1.35;
    color: #bdbdbd;
}
.notifications .media {
    margin-top: 0;
    border-bottom: 1px solid #f5f5f5;
}
.notifications .media:last-child {
    border-bottom: none;
}
.notifications .media a {
    display: block;
    padding: 10px 15px;
    border-radius: 2px;
}
.notifications .media a:hover {
    background-color: #fafafa;
}
.notifications .media > .avatar {
    margin-right: 10px;
}
.notifications .media-list .media-left {
    padding-right: 8px;
}
.topnav-dropdown-header {
    border-bottom: 1px solid #eee;
    text-align: center;
}
.topnav-dropdown-header,
.topnav-dropdown-footer {
    font-size: 14px;
    height: 40px;
    line-height: 40px;
    padding-left: 15px;
    padding-right: 15px;
}
.topnav-dropdown-footer {
    border-top: 1px solid #eee;
}
.topnav-dropdown-footer a {
    display: block;
    text-align: center;
    color: #333;
}
.user-menu.nav > li > a .badge {
    background-color: #3d5ee1;
    display: block;
    font-size: 10px;
    font-weight: bold;
    height: 15px;
    width: 15px;
    position: absolute;
    right: 7px;
    top: 13px;
    color: #fff;
    padding: 3px;
    border-radius: 50%;
}
.user-menu.nav > li > a > i {
    font-size: 1.5rem;
    line-height: 60px;
}
.noti-details {
    color: #989c9e;
    margin-bottom: 0;
}
.noti-title {
    color: #333;
}
.notifications .noti-content {
    height: 290px;
    width: 350px;
    overflow-y: auto;
    position: relative;
}
.notification-list {
    list-style: none;
    padding: 0;
    margin: 0;
}
.notifications ul.notification-list > li {
    margin-top: 0;
    border-bottom: 1px solid #f5f5f5;
}
.notifications ul.notification-list > li:last-child {
    border-bottom: none;
}
.notifications ul.notification-list > li a {
    display: block;
    padding: 10px 15px;
    border-radius: 2px;
}
.notifications ul.notification-list > li a:hover {
    background-color: #fafafa;
}
.notifications ul.notification-list > li .list-item {
    border: 0;
    padding: 0;
    position: relative;
}
.topnav-dropdown-header .notification-title {
    color: #333;
    display: block;
    float: left;
    font-size: 14px;
}
.topnav-dropdown-header .clear-noti {
    color: #18aefa;
    float: right;
    font-size: 12px;
    text-transform: uppercase;
}
.noti-time {
    margin: 0;
}

/*-----------------
	14. Dashboard
-----------------------*/

.bg-comman {
    background-color: #fff;
    border: 0;
    border-radius: 10px;
    box-shadow: 0px 0px 31px 3px rgba(44, 50, 63, 0.02);
}
.db-icon {
    font-size: 25px;
    width: 60px;
    height: 60px;
    border-radius: 10px;
    color: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
}
.bg-comman .db-icon {
    background-color: #edf4ff;
    border-radius: 8px;
}
.db-info h3 {
    font-size: 22px;
    font-weight: 600;
    color: #000;
    margin-bottom: 0;
}
.db-info h6 {
    margin: 0px;
    font-weight: 400;
    font-size: 14px;
    color: #808191;
    margin-bottom: 5px;
}
.card-chart .card-body {
    padding: 8px;
}
.activity-feed {
    list-style: none;
    margin-bottom: 0;
    margin-left: 5px;
    padding: 0;
    width: 100%;
}
.activity-feed .feed-item {
    border-left: 2px solid #e4e8eb;
    padding-bottom: 20px;
    padding-left: 20px;
    position: relative;
}
.activity-feed .feed-item:last-child {
    border-color: transparent;
    padding-bottom: 0;
}
.activity-feed .feed-item:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: -7px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background-color: #3d5ee1;
}
.activity-feed .feed-item:after {
    content: "";
    display: block;
    position: absolute;
    background-color: #fff;
    width: 7px;
    height: 7px;
    top: 3px;
    left: -4px;
    border-radius: 50px;
}
.activity-feed .feed-item .feed-date {
    display: block;
    position: relative;
    color: #777;
    text-transform: uppercase;
    font-size: 13px;
    top: -2px;
}
.activity-feed .feed-item .feed-text {
    color: #777;
    position: relative;
}
.activity-feed .feed-item .feed-text a {
    color: #333;
    font-weight: 600;
}
.activity-feed .feed-item .feed-date1 {
    display: block;
    position: relative;
    color: #777;
    font-size: 12px;
    top: -2px;
}
.activity-feed .feed-item .feed-text1 a {
    color: #000;
    font-weight: 600;
    font-size: 18px;
}
.activity-feed .feed-item p {
    color: #3bbafd;
    font-weight: 400;
    margin: 0;
    font-size: 14px;
}
.activity-feed .feed-item p span {
    color: #ffba38;
}
.activity-btns .btn-info {
    min-width: 151px;
    background: #f4feff;
    border: 1px solid #f4feff;
    font-size: 14px;
    border-radius: 10px;
}
.activity-btns .btn-info:hover {
    background-color: #028ee1;
    border-color: #028ee1;
    color: #fff;
}
.sm-box {
    padding: 11px;
    box-shadow: 0px 0px 31px 3px rgba(44, 50, 63, 0.02);
    border-radius: 10px;
    flex-direction: inherit;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.sm-box .fab {
    font-size: 80px;
    position: absolute;
    right: 15px;
    bottom: 0px;
}
.sm-box h6 {
    color: #fff;
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 0;
}
.sm-box p {
    color: #fff;
    font-size: 12px;
    margin: 0px;
    margin-bottom: 5px;
}
.fb {
    background-color: #1877f2;
    border: 1px solid #1877f2;
}
.twitter {
    background-color: #1d9bf0;
    border: 1px solid #1d9bf0;
}
.linkedin {
    background-color: #0a66c2;
    border: 1px solid #0a66c2;
}
.insta {
    background-color: #fe643b;
    border: 1px solid #fe643b;
}
.fa-facebook {
    color: #7085b0;
}
.fa-twitter {
    color: #5ebbf4;
    bottom: -10px !important;
    right: 18px !important;
}
.fa-instagram {
    color: #de7099;
    bottom: -7px !important;
    right: 20px !important;
}
.fa-linkedin-in {
    color: #4a92b5;
    bottom: -14px !important;
    right: 23px !important;
}
.card.card-chart,
.card.comman-shadow {
    background: #ffffff;
    box-shadow: 0px 0px 31px rgba(44, 50, 63, 0.02);
    border-radius: 15px;
    border: 0;
}
.card.card-chart .card-header,
.card.comman-shadow .card-header {
    border-radius: 15px 15px 0 0;
    border-bottom: 0;
    padding-bottom: 10px;
}
.star-menus a {
    width: 25px;
    height: 28px;
    background: #f4f4f4;
    display: flex;
    border-radius: 6px;
    align-items: center;
    justify-content: center;
    color: #000;
    font-size: 14px;
}
.chart-list-out {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: flex-end;
    -webkit-justify-content: end;
    -ms-flex-pack: end;
    margin-bottom: 0;
}
.chart-list-out li {
    margin-right: 10px;
    color: #808191;
    font-size: 11px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.chart-list-out li.star-menus {
    margin-right: 0;
}
.chart-list-out span {
    width: 9px;
    height: 9px;
    border-radius: 50px;
    display: inline-block;
    margin-right: 5px;
}
.circle-blue {
    background: #3d5ee1;
}
.circle-green {
    background: #70c4cf;
}
.circle-gray {
    background: #ebebeb;
}
.chart-list-out.student-ellips {
    margin-left: auto;
}
.star-student.table-striped tbody tr:nth-of-type(even) {
    background-color: #fafafa;
}
.star-student.table-striped tbody tr:nth-of-type(odd) {
    background-color: transparent;
}
.star-student.table-striped tbody tr td,
.star-student.table-striped tbody tr td a {
    color: #666;
}
.star-student.table-striped tbody tr td img {
    margin-right: 10px;
}
.activity-awards {
    margin-bottom: 38px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.award-boxs {
    background: #edf4ff;
    width: 40px;
    height: 40px;
    border-radius: 6px;
    margin-right: 12px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
}
.award-list-outs h4 {
    color: #2c323f;
    font-weight: 500;
    font-size: 14px;
}
.award-list-outs h5 {
    color: #808191;
    font-weight: 400;
    font-size: 12px;
    margin-bottom: 0;
}
.award-time-list {
    margin-left: auto;
}
.award-time-list span {
    background: #fff9ed;
    color: #808191;
    font-weight: 400;
    font-size: 11px;
    padding: 5px 9px;
    border-radius: 6px;
}
.social-boxs {
    background: #fff;
    width: 63px;
    height: 59px;
    border-radius: 8px;
    margin-left: auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
}

/*-----------------
	15. Student Details
-----------------------*/

h2.table-avatar {
    font-size: 14px;
}
.about-info img {
    width: 250px;
}
.about-info ul li {
    font-size: 18px;
    margin: 0px 0px 15px 10px;
    display: flex;
}
.title-span {
    font-weight: 500;
    width: 150px;
}
.blue-box {
    background-color: #18aefa;
    padding: 40px 30px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    color: #fff;
    text-align: center;
}
.follow-sec h3 {
    font-size: 35px;
    font-weight: 600;
}
.follow-sec p {
    font-size: 18px;
    margin: 0px;
}
.skill-info {
    padding-top: 20px;
    border-top: 1px solid #ddd;
}
.skill-info ul li {
    margin-bottom: 25px;
}

/*-----------------
	16. Event
-----------------------*/

.calendar-events {
    border: 1px solid transparent;
    cursor: move;
    padding: 10px 15px;
}
.calendar-events:hover {
    border-color: #e9e9e9;
    background-color: #fff;
}
.calendar-events i {
    margin-right: 8px;
}
.calendar {
    float: left;
    margin-bottom: 0;
}
.fc-toolbar.fc-header-toolbar {
    margin-bottom: 1.5rem;
}
.none-border .modal-footer {
    border-top: none;
}
.fc-toolbar h2 {
    font-size: 18px;
    font-weight: 600;
    font-family: "Roboto", sans-serif;
    line-height: 30px;
    text-transform: uppercase;
}
.fc-day-grid-event .fc-time {
    font-family: "Roboto", sans-serif;
}
.fc-day {
    background: #fff;
}
.fc-toolbar .fc-state-active,
.fc-toolbar .ui-state-active,
.fc-toolbar button:focus,
.fc-toolbar button:hover,
.fc-toolbar .ui-state-hover {
    z-index: 0;
}
.fc th.fc-widget-header {
    background: #eeeeee;
    font-size: 14px;
    line-height: 20px;
    padding: 10px 0;
    text-transform: uppercase;
}
.fc-unthemed th,
.fc-unthemed td,
.fc-unthemed thead,
.fc-unthemed tbody,
.fc-unthemed .fc-divider,
.fc-unthemed .fc-row,
.fc-unthemed .fc-popover {
    border-color: #f3f3f3;
}
.fc-basic-view .fc-day-number,
.fc-basic-view .fc-week-number {
    padding: 2px 5px;
}
.fc-button {
    background: #f1f1f1;
    border: none;
    color: #797979;
    text-transform: capitalize;
    box-shadow: none !important;
    border-radius: 3px !important;
    margin: 0 3px !important;
    padding: 6px 12px !important;
    height: auto !important;
}
.fc-text-arrow {
    font-family: inherit;
    font-size: 16px;
}
.fc-state-hover {
    background: #f3f3f3;
}
.fc-state-highlight {
    background: #f0f0f0;
}
.fc-state-down,
.fc-state-active,
.fc-state-disabled {
    background-color: #3d5ee1 !important;
    color: #fff !important;
    text-shadow: none !important;
}
.fc-cell-overlay {
    background: #f0f0f0;
}
.fc-unthemed .fc-today {
    background: #fff;
}
.fc-event {
    border-radius: 2px;
    border: none;
    color: #fff !important;
    cursor: move;
    font-size: 13px;
    margin: 1px 7px;
    padding: 5px 5px;
    text-align: center;
}
.fc-basic-view td.fc-week-number span {
    padding-right: 8px;
    font-weight: 700;
    font-family: "Roboto", sans-serif;
}
.fc-basic-view td.fc-day-number {
    padding-right: 8px;
    font-weight: 700;
    font-family: "Roboto", sans-serif;
}
.event-form .input-group .form-control {
    height: 40px;
}
.submit-section {
    text-align: center;
    margin-top: 40px;
}
.submit-btn {
    border-radius: 50px;
    font-size: 18px;
    font-weight: 600;
    min-width: 200px;
    padding: 10px 20px;
}

/*-----------------
	17. Inbox
-----------------------*/

.dropdown-action {
    margin-bottom: 5px;
}
.dropdown-action .dropdown-toggle:after {
    display: none;
}
.table-inbox input[type="radio"],
.table-inbox input[type="checkbox"] {
    cursor: pointer;
}
.mail-list {
    list-style: none;
    padding: 0;
}
.mail-list > li > a {
    color: #333;
    display: block;
    padding: 10px;
}
.mail-list > li.active > a {
    color: #3d5ee1;
    font-weight: bold;
}
.unread .name,
.unread .subject,
.unread .mail-date {
    color: #000;
    font-weight: 600;
}
.table-inbox .starred.fa-star {
    color: #ffd200;
}
.table.table-inbox > tbody > tr > td,
.table.table-inbox > tbody > tr > th,
.table.table-inbox > tfoot > tr > td,
.table.table-inbox > tfoot > tr > th,
.table.table-inbox > thead > tr > td,
.table.table-inbox > thead > tr > th {
    border-bottom: 1px solid #f2f2f2;
    border-top: 0;
}
.table-inbox {
    font-size: 15px;
    margin-bottom: 0;
}
.table.table-inbox thead {
    background-color: #fff;
}
.note-editor.note-frame {
    border: 1px solid #ddd;
    box-shadow: inherit;
}
.note-editor.note-frame .note-statusbar {
    background-color: #fff;
}
.note-editor.note-frame.fullscreen {
    top: 60px;
}
.note-editor.note-frame .btn-light {
    background-color: #f9f9f9;
    box-shadow: unset;
    color: #333;
}
.mail-title {
    font-weight: bold;
    text-transform: uppercase;
}
.form-control.search-message {
    border-color: #ccc;
    border-radius: 4px;
    height: 38px;
    width: 180px;
}
.table-inbox tr {
    cursor: pointer;
}
table.table-inbox tbody tr.checked {
    background-color: #ffffcc;
}
.mail-label {
    font-size: 16px !important;
    margin-right: 5px;
}
.inbox-menu {
    display: inline-block;
    margin: 0 0 1.875rem;
    padding: 0;
    width: 100%;
}
.inbox-menu li {
    display: inline-block;
    width: 100%;
}
.inbox-menu li + li {
    margin-top: 2px;
}
.inbox-menu li a {
    color: #333;
    display: inline-block;
    padding: 10px 15px;
    width: 100%;
    text-transform: capitalize;
    -webkit-transition: 0.3s ease;
    -moz-transition: 0.3s ease;
    transition: 0.3s ease;
}
.inbox-menu li a i {
    font-size: 16px;
    padding-right: 10px;
    color: #878787;
}
.inbox-menu li a:hover,
.inbox-menu li.active a,
.inbox-menu li a:focus {
    background: rgba(33, 33, 33, 0.05);
}
.compose-btn {
    margin-bottom: 1.875rem;
}
.compose-btn a {
    font-weight: 600;
    padding: 8px 15px;
}

/*-----------------
	18. Error
-----------------------*/

.error-page {
    align-items: center;
    color: #1f1f1f;
    display: flex;
}
.error-page .main-wrapper {
    display: flex;
    flex-wrap: wrap;
    height: auto;
    justify-content: center;
    width: 100%;
    min-height: unset;
}
.error-box {
    margin: 0 auto;
    max-width: 480px;
    padding: 1.875rem 0;
    text-align: center;
    width: 100%;
}
.error-box h1 {
    color: #3d5ee1;
    font-size: 10em;
}
.error-box p {
    margin-bottom: 1.875rem;
}
.error-box .btn {
    border-radius: 50px;
    font-size: 18px;
    font-weight: 600;
    min-width: 200px;
    padding: 10px 20px;
}

/*-----------------
	19. Profile
-----------------------*/

.profile-header {
    background: linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%);
    border: 1px solid #efefef;
    padding: 1.5rem;
}
.profile-menu {
    background-color: #fff;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
    padding: 0.9375rem 1.5rem;
}
.profile-menu .nav-tabs.nav-tabs-solid {
    background-color: transparent;
}
.profile-header img {
    height: auto;
    max-width: 120px;
    width: 120px;
}
.profile-tab-cont {
    padding-top: 1.875rem;
}
.about-text {
    max-width: 500px;
}
.skill-tags span {
    background-color: #f4f4f5;
    border-radius: 4px;
    color: #66676b;
    display: inline-block;
    font-size: 15px;
    line-height: 22px;
    margin: 2px 0;
    padding: 5px 15px;
}
.edit-link {
    color: #66676b;
    font-size: 16px;
    margin-top: 4px;
}
.form-title {
    width: 100%;
    max-width: 100%;
    padding: 0;
    font-size: 1.25rem;
    font-weight: 500;
    line-height: inherit;
    color: #333;
    white-space: normal;
    position: relative;
    display: block;
    margin-bottom: 30px;
}
.form-title span {
    padding: 0px 0.5rem 0 0;
    background-color: #fff;
    display: inline-block;
    z-index: 2;
    position: relative;
}
.search-student-btn .btn-primary {
    min-width: 135px;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    padding: 11px 15px;
}
.student-profile-head {
    background: #f7f7fa;
    border-radius: 10px;
    margin-bottom: 40px;
}
.profile-bg-img img {
    width: 100%;
    border-radius: 10px;
}
.profile-user-box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
}
.profile-user-img {
    margin-right: 20px;
    position: relative;
    top: -30px;
}
.profile-user-img img {
    border-radius: 50%;
    border: 9px solid #fff;
    width: 141px;
}
.names-profiles h4 {
    font-size: 18px;
    font-weight: 500;
    color: #000;
    margin-bottom: 3px;
}
.names-profiles h5 {
    font-size: 14px;
    font-weight: 500;
    color: #9e9e9e;
    margin-bottom: 0;
}
.profile-edit-icon {
    width: 34px;
    height: 34px;
    background: #3d5ee1;
    position: absolute;
    border-radius: 50px;
    bottom: 0;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
}
.profile-edit-icon:hover {
    background: #70c4cf;
    transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
}
.profile-edit-icon i {
    color: #fff;
}
.follow-group {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-flex-pack: space-between;
}
.students-follows h5 {
    color: #808191;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 0;
}
.students-follows h4 {
    color: #000000;
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 0;
}
.follow-btn-group {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
}
.follow-btn-group .btn-info {
    min-width: 127px;
    font-size: 16px;
    font-weight: 500;
    color: #fff;
    border-radius: 10px;
    padding: 9px 15px;
}
.follow-btn-group .follow-btns {
    background: #3d5ee1;
    border: 1px solid #3d5ee1;
    margin-right: 15px;
}
.follow-btn-group .follow-btns:hover {
    background: #70c4cf;
    border: 1px solid #70c4cf;
}
.follow-btn-group .message-btns {
    background: #70c4cf;
    border: 1px solid #70c4cf;
}
.follow-btn-group .message-btns:hover {
    background: #3d5ee1;
    border: 1px solid #3d5ee1;
}
.personal-activity {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 20px;
}
.views-personal {
    margin-left: 10px;
}
.views-personal h5 {
    color: #808080;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 0;
}
.views-personal h4 {
    color: #000;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 0;
}
.personal-icons i {
    color: #3d5ee1;
}
.student-personals-grp .card {
    border: 2px solid rgba(220, 220, 220, 0.3);
    border-radius: 10px;
    box-shadow: none;
}
.heading-detail h4 {
    font-size: 20px;
    font-weight: 500;
    color: #000000;
    margin-bottom: 25px;
}
.hello-park h5 {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 20px;
}
.hello-park p {
    font-size: 14px;
    font-weight: 400;
    color: #828282;
}
.educate-year h6 {
    font-size: 13px;
    font-weight: 400;
    color: #000000;
}
.skills-head {
    margin-bottom: 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-flex-pack: space-between;
}
.skills-head h5 {
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 500;
}
.skills-head p {
    margin-bottom: 0;
    color: #848484;
    font-size: 14px;
    font-weight: 500;
}
.skill-statistics {
    margin-bottom: 25px;
}
.skill-statistics .progress {
    height: 8px;
}
.bg-photoshop {
    background: #3d5ee1;
}
.bg-editor {
    background: #70c4cf;
}
.bg-illustrator {
    background: #ffbf42;
}
.local-forms {
    position: relative;
}
.local-forms label {
    font-size: 13px;
    color: #ababab;
    font-weight: 500;
    position: absolute;
    top: -10px;
    left: 10px;
    background: #fff;
    margin-bottom: 0;
    padding: 0px 5px;
    z-index: 99;
}
.local-forms .form-control::placeholder {
    color: #333;
    font-size: 14px;
}
.form-group.local-forms {
    margin-bottom: 40px;
}
.students-files {
    margin-bottom: 30px;
}
.students-files h5 {
    font-size: 16px;
    font-weight: 500;
}
.student-submit .btn-primary {
    min-width: 160px;
    border-radius: 10px;
    background: #3d5ee1;
    border: 1px solid #3d5ee1;
}
.student-submit .btn-primary:hover {
    background: #18aefa;
    border: 1px solid #18aefa;
}
.form-title.student-info,
.about-info h4 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-flex-pack: space-between;
}
.about-info h4 i,
.form-title.student-info i {
    color: #8c8c8c;
}
.about-info {
    margin-bottom: 25px;
}
.calendar-icon:after {
    color: #344357;
    content: "\f073";
    display: block;
    font-family: "Font Awesome 5 Free";
    font-size: 16px;
    font-weight: 600;
    margin: auto;
    position: absolute;
    right: 15px;
    bottom: 10px;
}
.students-up-files label {
    font-size: 15px;
    font-weight: 500;
}
.students-up-files label.file-upload.image-upbtn {
    background: #444;
    border: 1px solid #444;
    border-radius: 8px;
    color: #fff;
    padding: 6px 19px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
}
.students-up-files label.file-upload.image-upbtn:hover {
    background: #000;
    border: 1px solid #000;
}
.students-up-files label.file-upload {
    position: relative;
    overflow: hidden;
}
.students-up-files label.file-upload input[type="file"] {
    position: absolute;
    top: 0;
    right: 0;
    min-width: 100%;
    min-height: 100%;
    font-size: 100px;
    text-align: right;
    filter: alpha(opacity=0);
    opacity: 0;
    outline: none;
    background: white;
    cursor: inherit;
    display: block;
}
.student-pro-list .card {
    border: 2px solid rgba(220, 220, 220, 0.3);
    border-radius: 10px;
    margin-bottom: 25px;
    width: 100%;
}
.student-img {
    position: relative;
    text-align: center;
}
.student-img img {
    border-radius: 50%;
}
.student-content {
    padding: 15px 0px;
    text-align: center;
}
.student-content h5 {
    font-size: 18px;
}
.student-pro-list.profile-edit-icon .card {
    border: 2px solid rgba(220, 220, 220, 0.3);
    border-radius: 50px;
}
.students-up-files.profile-edit-icon label.file-upload.profile-upbtn {
    cursor: pointer;
}

/*-----------------
	20. Teacher Dashboard
-----------------------*/

.bg-five {
    background-color: rgb(244 104 65 / 0.2);
    border-color: #f46841;
    border-radius: 30px 0px;
}
.bg-six {
    background-color: rgb(110 107 250 / 0.2);
    border-color: #6e6bfa;
    border-radius: 30px 0px;
}
.bg-seven {
    background-color: rgb(253 187 56 / 0.5);
    border-color: #fdbb38;
    border-radius: 30px 0px;
}
.bg-eight {
    background-color: rgb(25 175 251 / 0.2);
    border-color: #19affb;
    border-radius: 30px 0px;
}
.bg-nine {
    background-color: rgb(244 104 65 / 0.2);
    border-color: #f46841;
    border-radius: 30px 0px;
}
.bg-ten {
    background-color: rgb(253 187 56 / 0.5);
    border-color: #fdbb38;
    border-radius: 30px 0px;
}
.bg-eleven {
    background-color: rgb(25 175 251 / 0.2);
    border-color: #19affb;
    border-radius: 30px 0px;
}
.bg-five .db-icon {
    background-color: #f46841;
    padding: 13px 15px;
}
.bg-six .db-icon {
    background-color: #6e6bfa;
}
.bg-seven .db-icon {
    background-color: #fdbb38;
    padding: 13px 18px;
}
.bg-eight .db-icon {
    background-color: #19affb;
    padding: 13px 18px;
}
.bg-nine .db-icon {
    background-color: #f46841;
    padding: 13px 16px;
}
.bg-ten .db-icon {
    background-color: #fdbb38;
    padding: 13px 21px;
}
.bg-eleven .db-icon {
    background-color: #19affb;
    padding: 13px 21px;
}
.lesson {
    white-space: nowrap;
}
.lesson .table td {
    border-top: transparent;
    padding-left: 4px;
    padding-right: 4px;
    padding-top: 0;
}
.lesson .table td + td {
    padding-top: 0.75rem;
}
.lesson .table td:first-child {
    padding-left: 1.5rem;
}
.lesson .table td:last-child {
    padding-right: 1.5rem;
    text-align: right;
}
.lesson .table tbody tr:last-child {
    border-bottom: transparent;
}
.lesson .table tbody tr {
    border-bottom: transparent;
}
.lesson .date b {
    font-size: 16px;
    font-weight: 500;
    color: #000;
}
.lesson .date p {
    font-size: 13px;
    font-weight: 400;
    color: #959595;
    margin-bottom: 0;
}
.lesson a {
    font-size: 12px;
    font-weight: 500;
    color: #3bbafd;
}
.lesson .btn {
    font-size: 14px;
    font-weight: 500;
    color: #fff;
}
.lesson .btn-info {
    background: #3d5ee1;
    border: 1px solid #3d5ee1;
}
.lesson .btn-info:hover {
    background: #3652bf;
    border: 1px solid #3652bf;
}
.teacher-date-list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 0;
}
.teacher-date-list li {
    color: #a098ae;
    font-size: 14px;
}
.teacher-date-list li:first-child {
    margin-right: 12px;
}
.teacher-date-list li:last-child {
    margin-left: 12px;
}
.lesson-confirm {
    margin-bottom: 15px;
}
.lesson .lesson-confirm a {
    color: #3d5ee1;
    font-size: 14px;
}
.dash-circle {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.dash-widget3 {
    padding-right: 0;
}
.dash-widget4 {
    padding-left: 0;
}
.dash-widget {
    text-align: center;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
}
.circle-bar > div {
    display: inline-block;
    position: relative;
    text-align: center;
}
.circle-bar > div b {
    font-size: 25px;
    font-weight: 600;
    color: #000;
}
.circle-bar > div p {
    font-size: 11px;
    font-weight: 400;
    color: #7c7c7c;
    margin-bottom: 0;
}
.circle-bar > div .progress-less {
    left: 0;
    position: absolute;
    top: 50%;
    right: 0;
    text-align: center;
    margin: 0 auto;
    transform: translateY(-50%);
}
.circle-bar > div canvas {
    width: 148px !important;
    height: 148px !important;
}
.dash-info {
    padding-top: 20px;
}
.dash-info h6 {
    font-size: 14px;
    font-weight: 500;
    color: #6e6bfa;
    margin-bottom: 10px;
}
.dash-info h4 {
    font-size: 26px;
    font-weight: 600;
    color: #6e6bfa;
    margin: 0;
}
.dash-info h4 span {
    color: #b8b7ff;
}
.calendar-info {
    border-top: 1px solid #eaeaea;
}
.calendar-details {
    display: flex;
    align-items: center;
    padding-top: 20px;
}
.calendar-info p {
    font-size: 12px;
    font-weight: 500;
    color: #777777;
    margin-bottom: 0;
    margin-right: 10px;
    flex: none;
}
.calendar-info .calendar-blue {
    font-size: 12px;
    font-weight: 500;
    color: #333333;
    background: #d2efff;
    margin-bottom: 0;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 10px;
    padding-right: 10px;
}
.calendar-info .calendar-violet {
    font-size: 12px;
    font-weight: 500;
    color: #333333;
    background: #e2e2fe;
    margin-bottom: 0;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 10px;
    padding-right: 10px;
}
.calendar-info .calendar-red {
    font-size: 12px;
    font-weight: 500;
    color: #333333;
    background: #fde0d8;
    margin-bottom: 0;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 10px;
    padding-right: 10px;
}
.calendar-info .calendar-orange {
    font-size: 12px;
    font-weight: 500;
    color: #333333;
    background: #ffefcf;
    margin-bottom: 0;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 10px;
    padding-right: 10px;
}
.calendar-box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    width: 100%;
}
.calendar-box:before {
    content: "";
    position: absolute;
    background: #3d5ee1;
    width: 4px;
    height: 100%;
    border-radius: 25px;
}
.normal-bg:before {
    background: #3d5ee1;
}
.break-bg:before {
    background: #70c4cf;
}
.calendar-box span {
    color: #888888;
    font-size: 12px;
    font-weight: 400;
    margin-left: auto;
}
.calandar-event-name h4 {
    font-size: 14px;
    font-weight: 500;
    color: #000;
}
.calandar-event-name h5 {
    font-size: 12px;
    font-weight: 400;
    color: #747474;
    margin-bottom: 0;
}
.calandar-event-name {
    padding-left: 10px;
}
.up-come-header {
    margin-top: 45px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-flex-pack: space-between;
}
.up-come-header span {
    background: #f7f7fa;
    width: 36px;
    height: 36px;
    border-radius: 5px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
}
.up-come-header span a {
    color: #52536d;
    font-size: 20px;
}
.up-come-header h2 {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 0;
}
.upcome-event-date h3 {
    display: inline-block;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 0;
    background: #fff;
    z-index: 99;
    padding-right: 10px;
}
.upcome-event-date span {
    background: #fff;
    z-index: 99;
    padding-left: 10px;
}
.upcome-event-date {
    position: relative;
    margin: 38px 0 0 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-flex-pack: space-between;
}
.upcome-event-date:before {
    content: "";
    position: absolute;
    background: #ebebeb;
    width: 100%;
    height: 1px;
    top: 10px;
}
.teaching-card {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.steps-history {
    margin-bottom: 0;
    margin-right: 10px;
    align-items: baseline;
    display: inline-grid;
}
.steps-history li {
    margin-bottom: 10px;
    color: #3d5ee1;
    font-weight: 500;
}

/*-----------------
	21. Student Dashboard
-----------------------*/

.dash-widget1 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
}
.dash-widget2 {
    border: none;
}
.dash-widget1 .circle-bar {
    text-align: center;
}
.dash-widget1 .circle-bar > div {
    display: inline-block;
    position: relative;
    text-align: center;
}
.dash-widget1 .circle-bar > div b {
    font-size: 24px;
    font-weight: 600;
    color: #b8b7ff;
    left: 0;
    position: absolute;
    top: 50%;
    right: 0;
    text-align: center;
    margin: 0 auto;
    transform: translateY(-50%);
}
.dash-widget1 .circle-bar > div canvas {
    width: 90px !important;
    height: 90px !important;
}
.dash-widget1 h6 {
    font-size: 14px;
    font-weight: 500;
    color: #6e6bfa;
    margin-top: 20px;
    margin-bottom: 10px;
}
.dash-widget1 h4 {
    font-size: 20px;
    font-weight: 600;
    color: #6e6bfa;
    margin: 0;
}
.dash-widget1 h4 span {
    color: #b8b7ff;
}
.dash-details {
    padding-top: 0px;
}
.dash-details h4 {
    font-size: 16px;
    font-weight: 600;
    color: #3b3b3b;
    margin-bottom: 10px;
}
.dash-widget1 ul {
    padding: 0;
    margin: 0 -6px;
    list-style: none;
}
.dash-widget1 ul li {
    padding: 6px 6px;
    display: inline-block;
    font-size: 14px;
    font-weight: 500;
    color: #777;
}
.dash-widget1 .dash-btn {
    margin-top: 30px;
}
.dash-widget1 .dash-btn .btn {
    font-size: 14px;
    font-weight: 500;
    width: 110px;
    margin-bottom: 10px;
    margin-right: 10px;
    color: #ffffff;
    background: #009efb;
}
.dash-widget1 .dash-btn .btn:last-child {
    margin-right: 0;
}
.dash-widget1 .dash-btn .btn:hover {
    color: #009efb;
    background: transparent;
}
.dash-widget1 .dash-btn .btn-border {
    color: #009efb;
    background: transparent;
}
.dash-widget1 .dash-btn .btn-border:hover {
    color: #ffffff;
    background: #009efb;
}
.view-link {
    font-size: 14px;
}
.lesson-activity {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 20px;
}
.views-lesson {
    margin-left: 10px;
}
.views-lesson h5 {
    color: #808080;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 0;
}
.views-lesson h4 {
    color: #000;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 0;
}
.skip-group .btn-info {
    min-width: 160px;
    padding: 9px 15px;
    border-radius: 10px;
    font-size: 16px;
    font-weight: 500;
}
.skip-group .skip-btn {
    background: #f7f7fa;
    border: 1px solid #f7f7fa;
    color: #000;
    margin-bottom: 20px;
}
.skip-group .skip-btn:hover {
    background: #3d5ee1;
    border: 1px solid #3d5ee1;
    color: #fff;
}
.skip-group .continue-btn {
    background: #3d5ee1;
    border: 1px solid #3d5ee1;
    color: #fff;
}
.skip-group .continue-btn:hover {
    background: #f7f7fa;
    border: 1px solid #f7f7fa;
    color: #000;
}
.lesson-view-all a {
    color: #3d5ee1;
    font-weight: 500;
    font-size: 14px;
}

/*-----------------
    22. Settings
-----------------------*/

.setting-wrap {
    background: rgba(12, 224, 255, 0.03);
    padding: 15px;
    margin: 10px 0 15px;
}
.setting-wrap h5 {
    font-weight: 400;
    margin-bottom: 6px;
}
.setting-wrap p {
    color: #7e84a3;
}
.setting-info .form-control {
    min-height: 51px;
    background: #f5f6fa;
}
.custom-file-upload {
    background-color: #fff;
    border: 1px solid #dde2ff;
    border-radius: 5px;
    display: inline-flex;
    transition: border-color 0.3s;
    width: 100%;
    font-size: 14px;
    cursor: pointer;
    margin-top: 0;
    height: 51px;
    color: #000;
    position: relative;
}
.custom-file-upload .file-upload-text {
    display: flex;
    display: -ms-flexbox;
    align-items: center;
    padding-left: 115px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.custom-file-upload input {
    display: none;
}
.change-user {
    padding: 14px 21px;
    background: #f5f6fa;
    border-radius: 4px 0px 0px 4px;
    font-size: 14px;
    color: #131523;
    position: absolute;
    left: 0;
}
.img-text {
    padding-top: 5px;
}
.login-option {
    margin: 25px 0;
}
.login-option h5 {
    font-size: 14px;
    font-weight: 400;
    color: #7e84a3;
}
.login-option h6 {
    font-weight: 400;
    color: #000000;
}
.login-option p {
    color: #000000;
    padding-left: 16px;
}
.setting-accordion .accordion-item {
    margin-bottom: 18px;
    background: #ffffff;
    border: 1px solid #eef1fe;
    border-radius: 0;
}
.accordion-button:focus {
    box-shadow: none;
    border-color: #eef1fe;
}
.accordion-header {
    cursor: pointer;
}
.accordion-button:not(.collapsed) {
    background: #ffffff;
    color: #000000;
    border-bottom: 1px solid #eef1fe;
    box-shadow: none;
}
.accordion-button::after {
    content: none;
}
.accordion-button,
.accordion-body {
    padding: 20px;
}
.setting-accordion .accordion-header .accord-title {
    border-left: 1px solid #d9d9d9;
    font-size: 16px;
    padding-left: 20px;
}
.setting-accordion .accordion-header p {
    color: #7e84a3;
}
.setting-accordion .toggle-switch {
    margin-right: 20px;
}
.payment-item {
    padding: 16px;
    border-bottom: 1px solid #dfe5fc;
}
.payment-item:last-child {
    border: 0;
}
.payment-item .head-title {
    font-size: 14px;
    text-align: right;
    margin-bottom: 0;
}
.payment-item .form-control {
    background: #f5f6fa;
    border: 1px solid #e6e9f4;
    padding: 6px 12px;
    height: 51px;
}
.settings-menu ul {
    display: block;
    padding: 0;
}
.settings-menu ul li + li {
    margin-top: 15px;
}
.settings-menu ul li a {
    color: #455560;
    padding: 0;
    border: 0 !important;
    display: inline-block;
}
.settings-menu ul li a:hover,
.settings-menu ul li a:focus {
    background-color: transparent !important;
    color: #455560;
    border: 0 !important;
}
.settings-menu ul li a.active {
    color: #3d5ee1 !important;
    border: 0;
}
.settings-menu ul li i {
    margin-right: 10px;
    font-size: 20px;
    min-width: 25px;
}
.setting-info h5 {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 12px;
    color: #000000;
}
.setting-info p {
    color: #7e84a3;
}
.submit-sec {
    text-align: right;
    padding-top: 20px;
}
.submit-sec .btn {
    min-width: 146px;
    margin-bottom: 10px;
}
.submit-sec .btn + .btn {
    margin-left: 10px;
}
.acc-wrap {
    border-bottom: 1px solid #eef1fe;
    margin-bottom: 30px;
}
.acc-wrap h6 {
    font-size: 16px;
    font-weight: 400;
    color: #000000;
    margin-bottom: 6px;
}
.acc-wrap p {
    color: #7e84a3;
    margin-bottom: 20px;
}

/*-----------------
	21. Invoices
-----------------------*/

.invoice-item .invoice-logo {
    margin-bottom: 30px;
}
.invoice-item .invoice-logo img {
    width: auto;
    max-height: 52px;
}
.invoice-item .invoice-text h2 {
    color: #272b41;
    font-size: 36px;
    font-weight: 600;
}
.invoice-item .invoice-details {
    text-align: right;
    font-weight: 400;
}
.invoice-item .invoice-details strong {
    color: #272b41;
}
.invoice-item .invoice-details-two {
    text-align: left;
    color: #000;
}
.invoice-item .invoice-text {
    padding-top: 42px;
    padding-bottom: 36px;
}
.invoice-item .invoice-text h2 {
    font-weight: 400;
}
.invoice-info {
    margin-bottom: 20px;
}
.invoice-info p {
    margin-bottom: 0;
}
.invoice-info.invoice-info2 {
    text-align: right;
}
.invoice-item .customer-text {
    font-size: 20px;
    color: #000;
    font-weight: 600;
    margin-bottom: 16px;
    display: block;
}
.invoice-item .customer-text a {
    font-size: 14px;
    color: #3d5ee1;
    margin-left: 12px;
}
.invoice-table tr th,
.invoice-table tr td,
.invoice-table-two tr th,
.invoice-table-two tr td {
    color: #272b41;
    font-weight: 600;
    padding: 15px 20px;
    line-height: inherit;
}
.invoice-table tr td,
.invoice-table-two tr td {
    font-weight: 500;
}
.invoice-table-two {
    margin-bottom: 0;
}
.invoice-table-two tr th,
.invoice-table-two tr td {
    border-top: 0;
}
.invoice-table-two tr td {
    text-align: right;
}
.invoice-info h5 {
    font-size: 16px;
    font-weight: 500;
}
.other-info {
    margin-top: 10px;
}
.file {
    visibility: hidden;
    position: absolute;
}
.btn-group,
.btn-group-vertical {
    position: relative;
    display: -inline-flexbox;
    display: inline-flex;
    vertical-align: middle;
}
.font-size-14 {
    font-size: 14px;
}

/*-----------------
	31. New Invoices
-----------------------*/

.invoices-links {
    color: #1b2559;
    margin-right: 20px;
}
.invoices-links:last-child {
    margin-right: 0px;
}
.invoices-links:hover {
    color: #18aefa;
}
.invoices-links.active {
    color: #3d5ee1;
}
.invoices-links .feather {
    width: 25px;
    height: 25px;
    font-size: 24px;
}
.report-card {
    border-radius: 5px;
}
.app-listing {
    padding: 0;
    margin: 0 -15px;
    list-style: none;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}
.app-listing li {
    width: 20%;
    padding-left: 10px;
    padding-right: 10px;
}
.multipleSelection .selectBox,
.SortBy .selectBoxes {
    position: relative;
    background: #fff;
    width: 100%;
    padding: 13px 10px;
    font-weight: 400;
    background: #ffffff;
    border: 1px solid #f7f8f9;
    border-radius: 7px;
    display: flex;
    display: -ms-flexbox;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}
.selectBox-cont {
    height: 220px;
    overflow-y: auto;
    padding-right: 5px;
}
.selectBox-cont-one {
    overflow-y: inherit;
}
.selectBox-cont::-webkit-scrollbar {
    width: 4px;
    background: #eef1fe;
    height: 10px;
}
.selectBox-cont::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgb(231, 231, 231);
    border-radius: 4px;
}
.selectBox-cont::-webkit-scrollbar-thumb {
    background: rgba(231, 234, 252, 0.75);
    border-radius: 4px;
}
.selectBox-cont::-webkit-scrollbar-thumb:hover {
    background: rgba(231, 234, 252, 0.75);
}
.selectBoxes.order-by {
    min-width: 154px;
    background: #eef1fe;
    padding: 6px 10px;
}
.multipleSelection {
    position: relative;
    margin-bottom: 20px;
}
.multi-filter,
.SortBy {
    position: relative;
}
.multi-filter:before {
    border: 7px solid #fff;
    border-color: transparent transparent #fff #fff;
    box-shadow: -2px 2px 2px -1px rgb(0 0 0 / 10%);
    content: "";
    right: 0;
    position: absolute;
    top: 2px;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
}
.multipleSelection #checkBoxes,
.SortBy #checkBox {
    display: none;
    position: absolute;
    width: 300px;
    left: 0;
    z-index: 1;
    margin-top: 10px;
    padding: 20px;
    background: #ffffff;
    border: 1px solid #f2ecff;
    box-shadow: 0px 4px 44px rgba(231, 234, 252, 0.75);
    border-radius: 6px;
}
.multipleSelection #checkBoxes-one {
    display: none;
    position: absolute;
    width: 100%;
    left: 0;
    z-index: 1;
    margin-top: 10px;
    padding: 20px;
    background: #ffffff;
    border: 1px solid #f2ecff;
    box-shadow: 0px 4px 44px rgba(231, 234, 252, 0.75);
    border-radius: 6px;
}
.multipleSelection #checkBoxes-one::before {
    border: 7px solid #f2ecff;
    border-color: transparent transparent #ffffff #ffffff;
    box-shadow: -2px 2px 2px -1px rgb(0 0 0 / 10%);
    content: "";
    left: 30px;
    position: absolute;
    top: 0;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
}
#checkBoxes-one .form-custom {
    margin-bottom: 14px;
}
#checkBoxes-one .form-custom .form-control {
    padding-left: 14px;
    border: 1px solid rgba(231, 234, 252, 0.75);
    border-radius: 6px;
}
#checkBoxes-one .btn-grey:hover {
    background: #18aefa;
    color: #fff;
}
#checkBoxes-one .btn-grey {
    background: rgba(231, 234, 252, 0.75);
    color: #1b2559;
    margin-top: 10px;
}
#checkBoxes-one .btn,
#checkBox .btn {
    font-size: 14px;
    font-weight: 500;
    padding: 8px 0px;
}
.multipleSelection #checkBoxes::before,
.SortBy #checkBox::before {
    border: 7px solid #f2ecff;
    border-color: transparent transparent #ffffff #ffffff;
    box-shadow: -2px 2px 2px -1px rgb(0 0 0 / 10%);
    content: "";
    left: 30px;
    position: absolute;
    top: 0;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
}
.check-list {
    position: relative;
}
.date-picker {
    width: 50%;
    float: left;
    padding-right: 10px;
}
#checkBoxes .date-picker .bootstrap-datetimepicker-widget table td {
    padding-left: 10px;
    padding-right: 10px;
}
#checkBoxes .date-picker .form-custom .form-control {
    padding-left: 10px;
}
.date-list ul {
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
}
.date-list ul li {
    width: 100%;
    padding: 0;
    padding-bottom: 5px;
}
.date-list ul li:last-child {
    padding-bottom: 0;
}
.date-list .date-btn {
    background: #fff;
    color: #1b2559;
    border: 1px solid #e7eafc;
    border-radius: 4px;
    padding: 10px 0 !important;
    width: 100%;
}
.date-list .date-btn:hover {
    background: #18aefa;
    color: #fff;
    border: 1px solid #e7eafc;
}
.form-custom {
    position: relative;
}
.form-custom.cal-icon:after {
    font-size: 14px;
}
.form-custom i {
    position: absolute;
    top: 10px;
    left: 10px;
    color: #7e84a3;
}
#checkBoxes .form-custom {
    margin-bottom: 14px;
}
.card-header .form-custom .form-control {
    padding-left: 30px;
    height: 35px;
}
#checkBoxes .form-custom .form-control {
    padding-left: 14px;
    border: 1px solid rgba(231, 234, 252, 0.75);
    border-radius: 6px;
}
#checkBoxes .form-custom .form-control::placeholder {
    color: #8f9bba;
}
.multipleSelection #checkBoxes.form-custom .form-control {
    padding-left: 25px;
    background: #f5f6fa;
    border: 1px solid #e6e9f4;
    border-radius: 5px;
    height: 33px;
}
.multipleSelection #checkBoxes .form-custom i {
    position: absolute;
    top: 35%;
    left: 6px;
    color: #7e84a3;
    font-size: 12px;
}
#checkBoxes .btn,
#checkBox .btn {
    font-size: 14px;
    font-weight: 500;
    padding: 8px 0px;
}
#checkBoxes .btn-grey {
    background: rgba(231, 234, 252, 0.75);
    color: #1b2559;
    margin-top: 10px;
}
#checkBoxes .btn-grey:hover {
    background: #18aefa;
    color: #fff;
}
.checkbox-title {
    font-weight: 600;
    font-size: 16px;
    color: #3d5ee1;
    margin-bottom: 14px;
}
.custom_check {
    color: #1b2559;
    display: inline-block;
    position: relative;
    font-size: 14px;
    margin-bottom: 15px;
    padding-left: 30px;
    cursor: pointer;
    font-weight: 400;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.custom_check input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}
.custom_check input:checked ~ .checkmark {
    background-color: #3d5ee1;
    border-color: #3d5ee1;
}
.custom_check .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 19px;
    width: 21px;
    border: 1px solid #c4c4c4;
    background-color: #fff;
    border-radius: 6px;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.custom_check .checkmark::after {
    content: "\f00c";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    position: absolute;
    display: none;
    left: 5px;
    top: 2px;
    color: #ffffff;
    font-size: 9px;
}
.custom_check input:checked ~ .checkmark:after {
    display: block;
}
.selectBox p {
    color: #1b2559;
    font-weight: 500;
}
.selectBox p .select-icon {
    color: #3d5ee1;
    width: 20px;
    height: 20px;
}
.report-btn {
    margin-bottom: 20px;
}
.report-btn .btn {
    font-weight: 600;
    color: #3d5ee1;
    background: #ffffff;
    border: 2px solid #3d5ee1;
    border-radius: 8px;
    width: 100%;
    padding: 11px 0;
}
.report-btn .btn:hover {
    color: #ffffff;
    background: #18aefa;
    border: 2px solid #18aefa;
}
.report-btn .btn:hover img {
    filter: invert(0) brightness(100);
}
.invoices-tabs-card {
    background: transparent;
    box-shadow: unset;
}
.invoices-main-tabs {
    border-bottom: 2px solid #f3f3f3;
    padding-bottom: 10px;
}
.invoices-tabs ul {
    padding: 0;
    margin: 0;
    list-style: none;
}
.invoices-tabs ul li {
    position: relative;
    display: inline-block;
    margin-right: 36px;
}
.invoices-tabs ul li:last-child {
    margin-right: 0;
}
.invoices-tabs ul li a {
    font-weight: 600;
    font-size: 16px;
    color: #1b2559;
    padding-bottom: 24px;
}
.invoices-tabs ul li a:hover {
    color: #3d5ee1;
}
.invoices-tabs ul li a.active {
    color: #3d5ee1;
    border-bottom: 2px solid #3d5ee1;
}
.invoices-settings-btn {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: flex-end;
    -webkit-justify-content: end;
    -ms-flex-pack: end;
}
.invoices-settings-icon {
    color: #3d5ee1;
    margin-right: 20px;
}
.invoices-settings-icon:hover {
    color: #18aefa;
}
.invoices-settings-icon .feather {
    width: 24px;
    height: 24px;
    font-size: 24px;
}
.invoices-settings-btn .btn {
    font-weight: 600;
    color: #fff;
    padding: 12px 0;
    min-width: 167px;
    background: #3d5ee1;
    border: 1px solid #3d5ee1;
    border-radius: 6px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
}
.invoices-settings-btn .btn:hover {
    background: #18aefa;
    border: 1px solid #18aefa;
}
.invoices-settings-btn .btn .feather {
    margin-right: 6px;
}
.inovices-card {
    border-radius: 5px;
}
.inovices-card .card-body {
    padding: 15px;
}
.inovices-widget-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-flex-pack: space-between;
}
.inovices-widget-icon img {
    width: 50px;
}
.inovices-widget-header .inovices-amount {
    font-weight: 600;
    font-size: 24px;
    color: #3d5ee1;
}
.inovices-all {
    font-weight: 600;
    color: #1b2559;
    margin-top: 16px;
    margin-bottom: 0px;
}
.inovices-all span {
    font-size: 10px;
    margin-left: 15px;
}
.invoices-grid-card {
    background: #ffffff;
    box-shadow: 0px 4px 24px rgba(228, 228, 228, 0.25);
    border-radius: 10px;
    color: #1b2559;
}
.invoices-grid-card .card-header {
    padding: 20px;
    border: 0;
}
.invoices-grid-card .card-header .dropdown-action {
    margin-bottom: 0;
}
.invoices-grid-card .card-header .action-icon {
    color: #1b2559;
    display: initial;
}
.invoices-grid-card .card-body {
    padding: 20px;
}
.invoices-grid-card .card-body span {
    font-size: 14px;
}
.invoices-grid-card .card-body h6 {
    font-size: 16px;
    font-weight: 500;
    color: #1b2559;
    margin-top: 4px;
}
.invoice-grid-link {
    color: #1b2559;
}
.invoice-grid-link:hover {
    color: #7638ff;
}
.card-middle {
    padding: 20px;
    background: #f7f7ff;
}
.card-middle-avatar {
    align-items: center;
    display: inline-flex;
    font-size: 16px;
    margin: 0;
}
.card-middle-avatar a {
    color: #1b2559;
}
.card-middle-avatar a:hover {
    color: #0a58ca;
}
.invoices-grid-card .card-footer {
    padding: 20px;
    border: 0;
}
.invoices-grid-card .card-footer .badge {
    border-radius: 6px;
    font-size: 13px;
    font-weight: 400;
    padding: 7px 18px;
}
.invoices-grid-card .bg-success-dark {
    background: #008f64;
}
.invoices-grid-card .bg-danger-dark {
    background: #ff0000;
}
.invoices-grid-card .bg-secondary-dark {
    background: #7638ff;
}
.invoices-grid-card .bg-primary-dark {
    background: #2196f3;
}
.invoices-grid-card .text-sm {
    font-size: 14px;
}
.invoice-load-btn {
    text-align: center;
    margin-top: 30px;
    margin-bottom: 30px;
}
.invoice-load-btn .btn {
    font-weight: 700;
    font-size: 16px;
    color: #3d5ee1;
    min-width: 170px;
    padding: 12px 15px;
    background: #ffffff;
    border: 2px solid #3d5ee1;
    border-radius: 6px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}
.invoice-load-btn .btn:hover {
    color: #ffffff;
    background: #18aefa;
    border: 2px solid #18aefa;
}
.invoice-load-btn .btn span {
    display: inline-block;
    width: 25px;
    height: 25px;
    margin-right: 8px;
    vertical-align: 0;
    border: 3px solid #3e45eb7d;
    border-right-color: #3d5ee1;
    border-radius: 50%;
    -webkit-animation: 0.75s linear infinite spinner-border;
    animation: 0.75s linear infinite spinner-border;
}
.invoice-load-btn .btn:hover span {
    border: 3px solid #ffffff7a;
    border-right-color: #fff;
}
.invoices-page-header {
    background: #ffffff;
    border-radius: 10px;
    padding: 12px;
}
.invoices-page-header .invoices-breadcrumb-item a {
    font-weight: 600;
    color: #3d5ee1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
}
.invoices-page-header .invoices-breadcrumb-item a:hover {
    color: #18aefa;
}
.invoices-page-header .invoices-breadcrumb-item i {
    color: #fff;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    margin-right: 10px;
    background: #3d5ee1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
}
.invoices-page-header .invoices-breadcrumb-item a:hover i {
    color: #fff;
    background: #377dff;
}
.invoices-create-btn .btn {
    font-weight: 600;
    font-size: 16px;
    color: #fff;
    border-radius: 8px;
    padding: 10px 0px;
    min-width: 160px;
}
.invoices-create-btn .invoices-preview-link {
    font-weight: 600;
    color: #3d5ee1;
    margin-right: 30px;
}
.invoices-create-btn .invoices-preview-link:hover {
    color: #18aefa;
}
.invoices-create-btn .delete-invoice-btn {
    background: #3d5ee1;
    margin-right: 10px;
}
.invoices-create-btn .delete-invoice-btn:hover {
    background: #18aefa;
}
.invoices-create-btn .save-invoice-btn {
    background: #3d5ee1;
}
.invoices-create-btn .save-invoice-btn:hover {
    background: #18aefa;
}
.invoices-add-card {
    background: #ffffff;
    border-radius: 10px;
}
.invoices-main-form {
    padding-bottom: 15px;
    margin-bottom: 30px;
    border-bottom: 1px solid #e5e5e5;
}
.invoices-form label {
    font-weight: 600;
    color: #1b2559;
}
.invoices-form .multipleSelection .selectBox {
    border: 2px solid #3d5ee1;
    border-radius: 6px;
}
.invoices-form .form-control {
    height: 50px;
    border: 1px solid #e5e5e5;
    border-radius: 6px;
}
.invoices-form .form-control::placeholder {
    color: #8f9bba;
    font-weight: 400;
}
.invoice-details-title {
    font-weight: 500;
    font-size: 20px;
    color: #1b2559;
    margin-bottom: 0.5rem;
}
.invoice-details-box {
    background: #ffffff;
    border: 1px solid #e5e5e5;
    border-radius: 10px;
}
.invoice-inner-head {
    padding: 26px;
    border-bottom: 1px solid #e5e5e5;
}
.invoice-inner-head span {
    color: #1b2559;
}
.invoice-inner-head span a {
    font-weight: 500;
    color: #3d5ee1;
}
.invoice-inner-head span a:hover {
    font-weight: 500;
    color: #377dff;
}
.invoice-inner-footer {
    padding: 0;
}
.invoice-inner-date {
    padding: 26px;
    border-right: 1px solid #e5e5e5;
}
.invoice-inner-datepic {
    border: 0;
    padding-left: 0;
}
.invoice-inner-footer span {
    font-weight: 500;
    color: #1b2559;
    position: relative;
}
.invoice-inner-footer .form-control {
    position: absolute;
    color: #3d5ee1;
    background: transparent;
    border: 0;
    display: inline-block;
    width: auto;
    height: auto;
    padding: 0 10px;
}
.invoice-inner-footer .form-control::placeholder {
    color: #3d5ee1;
    font-weight: 500;
}
.inovices-month-info {
    padding-top: 50px;
}
.inovices-month-info label {
    font-weight: 400;
}
.inovices-month-info
    .select2-container--default
    .select2-selection--single
    .select2-selection__rendered {
    color: #8f9bba;
    border-radius: 6px;
}
.inovices-month-info .form-control {
    height: 42px;
}
#show-invoices {
    display: none;
}
.invoice-add-table {
    padding-top: 20px;
    padding-bottom: 30px;
}
.invoice-add-table h4 {
    font-weight: 600;
    font-size: 20px;
    color: #3d5ee1;
    margin-bottom: 30px;
}
.invoice-add-table th {
    font-size: 16px;
    color: #1b2559;
}
.invoice-add-table .no-border td {
    border: 0;
}
.invoice-add-table .table-form-control td .form-control::placeholder {
    color: #1b2559;
}
.invoice-add-table .add-product {
    border-bottom: 1px solid #e5e5e5;
}
.invoice-add-table .add-product td {
    border: 0;
}
.invoice-add-table .add-product td a {
    color: #7638ff;
}
.invoice-add-table th {
    background: #f8f9fa;
}
.invoice-add-table td,
.invoice-add-table th {
    border-color: #e5e5e5;
    padding: 20px;
}
.invoice-add-table .add-btn {
    color: #3d5ee1;
}
.invoice-add-table .remove-btn {
    color: #ff0000;
}
.invoice-add-table .copy-btn {
    color: #c4c4c4;
}
.invoice-fields .field-title {
    font-weight: 600;
    font-size: 16px;
    color: #1b2559;
    margin-bottom: 15px;
}
.field-box {
    padding: 15px;
    background: #ffffff;
    border: 1px solid #e5e5e5;
    border-radius: 4px;
}
.field-box p {
    font-weight: 500;
    font-size: 14px;
    color: #1b2559;
    margin-bottom: 10px;
}
.field-box .btn {
    min-width: 266px;
    border-radius: 4px;
    padding: 30px 0;
}
.payment-details {
    width: 266px;
    border-radius: 4px;
    padding: 15px;
    background: #3d5ee1;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.payment-details p {
    color: #fff;
    max-width: 140px;
    font-weight: 400;
    margin-bottom: 0;
}
.payment-details a {
    font-size: 26px;
    color: #fff;
}
.invoice-faq .faq-tab {
    padding-top: 10px;
}
.faq-tab .panel-title {
    background: #ffffff;
    border: 1px solid #e5e5e5;
    border-radius: 4px;
    padding: 10px 20px;
    margin-bottom: 0px;
    position: relative;
}
.faq-tab .panel-title a {
    color: #c4c4c4;
}
.faq-tab .panel-title a:hover {
    color: #1b2559;
}
.faq-tab .panel-title a:not(.collapsed) {
    color: #1b2559;
}
.faq-tab .panel-title a.collapsed::after {
    content: "\f067";
    font-family: "Font Awesome 5 Free";
    position: absolute;
    right: 20px;
    top: 10px;
    color: #f09e9e;
    font-weight: 600;
    font-size: 14px;
}
.faq-tab .panel-title a::after {
    content: "\f068";
    font-family: "Font Awesome 5 Free";
    position: absolute;
    right: 20px;
    top: 10px;
    color: #f09e9e;
    font-weight: 600;
    font-size: 14px;
}
.faq-tab .panel-title a:not(.collapsed)::after {
    color: #ff0000;
}
.faq-tab .panel-body {
    padding: 10px 20px;
    border: 1px solid #e5e5e5;
}
.faq-tab .panel-body textarea {
    min-height: 100px;
    border: 0;
    border-radius: 0 0 4px 4px;
}
.invoice-total-card .invoice-total-title {
    font-weight: 600;
    font-size: 16px;
    color: #1b2559;
    margin-bottom: 15px;
}
.invoice-total-box {
    background: #ffffff;
    border: 1px solid #e5e5e5;
    border-radius: 4px;
}
.invoice-total-inner {
    padding: 15px;
}
.invoice-total-box p {
    color: #1b2559;
    margin-bottom: 20px;
    position: relative;
}
.invoice-total-box p span {
    float: right;
}
.check {
    display: block;
    margin: 0;
    padding: 0;
    width: 0;
    height: 0;
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
    position: absolute;
}
.checktoggle {
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.25);
    border-radius: 12px;
    cursor: pointer;
    display: block;
    font-size: 0;
    height: 24px;
    margin-bottom: 0;
    position: relative;
    width: 48px;
}
.checktoggle:after {
    content: " ";
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(5px, -50%);
    width: 16px;
    height: 16px;
    background-color: rgba(0, 0, 0, 0.25);
    border-radius: 50%;
    transition: left 300ms ease, transform 300ms ease;
}
.check:checked + .checktoggle {
    background-color: #18aefa;
    border: 1px solid #18aefa;
}
.check:checked + .checktoggle:after {
    background-color: #fff;
    left: 100%;
    transform: translate(calc(-100% - 5px), -50%);
}
.onoffswitch {
    margin-left: auto;
    position: relative;
    width: 73px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}
.onoffswitch-checkbox {
    display: none;
}
.onoffswitch-label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border-radius: 20px;
    margin-bottom: 0;
}
.onoffswitch-inner {
    display: block;
    margin-left: -100%;
    transition: margin 0.3s ease-in 0s;
    width: 200%;
}
.onoffswitch-inner:before,
.onoffswitch-inner:after {
    box-sizing: border-box;
    color: #fff;
    display: block;
    float: left;
    font-size: 16px;
    height: 30px;
    line-height: 32px;
    padding: 0;
    width: 50%;
}
.onoffswitch-inner:before {
    background-color: #55ce63;
    color: #fff;
    content: "ON";
    padding-left: 14px;
}
.onoffswitch-inner:after {
    content: "OFF";
    padding-right: 14px;
    background-color: #ccc;
    color: #fff;
    text-align: right;
}
.onoffswitch-switch {
    background: #fff;
    border-radius: 20px;
    bottom: 0;
    display: block;
    height: 20px;
    margin: 5px;
    position: absolute;
    right: 43px;
    top: 0;
    transition: all 0.3s ease-in 0s;
    width: 20px;
}
.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-inner {
    margin-left: 0;
}
.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch {
    right: 0px;
}
.invoice-total-box .add-links {
    font-weight: 500;
    color: #7638ff;
    margin-bottom: 20px;
    display: block;
}
.invoice-total-box .add-links-bg {
    background: transparent;
    padding: 0;
    color: #3d5ee1;
}
.invoice-total-box .service-amount {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-flex-pack: space-between;
}
.invoice-total-box .service-trash {
    margin-bottom: 10px;
    color: #1b2559;
}
.invoice-total-box .service-trash i {
    color: #ec1361;
}
.invoice-total-box .add-links-one {
    font-weight: 500;
    color: #3d5ee1;
}
.invoice-total-box .service-trash-one {
    margin-top: 10px;
    color: #1b2559;
}
.invoice-total-box .service-trash-one i {
    color: #ec1361;
}
.invoice-total-footer {
    border-top: 1px solid #e5e5e5;
    padding: 15px;
}
.invoice-total-footer h4 {
    font-size: 20px;
    margin-bottom: 0;
    color: #3d5ee1;
}
.invoice-total-footer h4 span {
    float: right;
}
.upload-sign {
    float: right;
    margin-top: 20px;
}
.service-upload {
    border: 2px dashed #e5e5e5;
    text-align: center;
    padding: 30px 0;
    background-color: #fff;
    position: relative;
    width: 330px;
}
.service-upload span {
    font-size: 15px;
    color: #858585;
    display: block;
}
.service-upload input[type="file"] {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
}
.upload-sign .btn {
    border-radius: 8px;
    min-width: 198px;
    padding: 10px 0;
}
.invoice-info-card {
    border-radius: 14px;
}
.invoice-item-one {
    border-bottom: 1px solid rgba(231, 234, 252, 0.75);
}
.invoice-item-one .invoice-info {
    margin-bottom: 30px;
    text-align: right;
}
.customer-text-one {
    font-size: 20px;
    color: #1b2559;
    font-weight: 600;
    display: block;
    margin-bottom: 10px;
}
.invoice-name {
    color: #1b2559;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 10px;
}
.invoice-item-one .invoice-details {
    color: #1b2559;
    font-weight: 400;
}
.invoice-head h2 {
    font-weight: 600;
    font-size: 28px;
    color: #1b2559;
    text-transform: uppercase;
    margin-bottom: 0;
}
.invoice-head {
    margin-bottom: 30px;
}
.invoice-head p {
    font-weight: 400;
    font-size: 20px;
    color: #1b2559;
    margin-bottom: 0;
}
.invoice-item-two {
    padding-top: 30px;
    padding-bottom: 30px;
}
.invoice-item-two .invoice-details {
    color: #1b2559;
    font-weight: 400;
}
.invoice-item-box {
    background: #f7f7ff;
    border-radius: 5px;
    padding: 15px;
    width: 200px;
    float: right;
    margin-top: 20px;
}
.invoice-item-box p {
    color: #1b2559;
    font-weight: 400;
    margin-bottom: 10px;
}
.invoice-issues-box {
    background: #3d5ee1;
    border-radius: 10px;
    text-align: center;
    padding: 10px;
    margin-bottom: 30px;
}
.invoice-issues-date {
    padding-top: 20px;
    padding-bottom: 20px;
}
.invoice-issues-date p {
    font-weight: 600;
    font-size: 16px;
    color: #ffffff;
    margin-bottom: 0;
}
.invoice-table-wrap {
    margin-bottom: 30px;
}
.invoice-table > thead > tr > th {
    background: #f5f7ff;
    border-top: 0;
    font-weight: 500;
    font-size: 16px;
    color: #1b2559;
}
.invoice-table > tbody > tr > td {
    font-weight: 400;
    color: #1b2559;
    padding: 15px 20px;
}
.invoice-terms {
    margin-bottom: 30px;
}
.invoice-terms h6 {
    font-weight: 500;
    font-size: 20px;
    color: #1b2559;
    margin-bottom: 6px;
}
.invoice-terms p {
    font-weight: 400;
    font-size: 16px;
    color: #8f9bba;
}
.invoice-sign {
    padding-top: 30px;
}
.invoice-sign span {
    color: #1b2559;
    margin-top: 18px;
}
.invoice-item-bg {
    background: #3d5ee1;
    border-radius: 10px;
    margin-top: 30px;
    margin-bottom: 30px;
}
.invoice-circle-img {
    position: relative;
}
.invoice-circle-img .invoice-circle1 {
    position: absolute;
    left: 0;
    opacity: 0.1;
}
.invoice-circle-img .invoice-circle2 {
    position: absolute;
    right: 0;
    opacity: 0.1;
}
.invoice-item-bg .invoice-info {
    border-right: 1px solid #fff;
    padding: 30px;
    margin-top: 20px;
}
.invoice-item-bg .customer-text-one {
    color: #fff;
}
.invoice-item-bg .invoice-name {
    color: #fff;
}
.invoice-item-bg .invoice-details-two {
    color: #fff;
}
.invoice-item-bg .invoice-info-one p {
    color: #fff;
    margin-bottom: 10px;
}
.invoice-sign-box {
    margin-top: 30px;
}
.invoice-payment-box h4 {
    font-weight: 600;
    font-size: 16px;
    color: #1b2559;
    margin-bottom: 16px;
}
.invoice-payment-box .payment-details {
    background: transparent;
    border: 2px dashed #18aefa;
}
.invoice-payment-box .payment-details p {
    font-weight: 600;
    font-size: 16px;
    color: #1b2559;
}
.invoices-preview .modal-body {
    padding: 0;
}
.add-remove {
    white-space: nowrap;
}

/*-----------------
	32. Invoices Settings
-----------------------*/

.invoices-settings-card {
    border-radius: 5px;
}
.invoices-settings-card .card-title {
    color: #1b2559;
}
.invoices-settings-form label {
    color: #1b2559;
}
.invoices-settings-form .form-control {
    height: 50px;
    border-radius: 6px;
}
.invoices-upload-btn {
    border: 1px solid #dee2e6;
    border-radius: 6px;
    color: #000000;
    cursor: pointer;
    font-weight: 400;
    padding: 15px 15px;
    position: relative;
    width: 100%;
    height: 50px;
}
.invoices-upload-btn .hide-input {
    position: relative;
    z-index: 1;
    cursor: pointer;
    min-height: auto;
    padding-left: 4px;
    padding-top: 0;
    line-height: 10px;
    width: 100%;
    opacity: 0;
}
.invoices-upload-btn .upload {
    position: absolute;
    background: #3d5ee1;
    border-radius: 4px;
    right: 6px;
    top: 8px;
    width: 136px;
    height: 32px;
    font-size: 14px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
}
.invoice-setting-btn .btn {
    min-width: 160px;
    padding: 10px 0;
    color: #fff;
    border-radius: 8px;
}
.invoice-setting-btn .cancel-btn {
    background: #3d5ee1;
}
.invoice-setting-btn .cancel-btn:hover {
    background: #18aefa;
}
.invoice-setting-btn .btn-primary:hover {
    background: #18aefa;
    border: 1px solid #18aefa;
}
.invoices-items-main-tabs {
    border-bottom: 3px solid #f3f3f3;
    padding-bottom: 10px;
}
.invoices-items-tabs ul {
    padding: 0;
    margin: 0;
    list-style: none;
}
.invoices-items-tabs ul li {
    display: inline-block;
    padding-left: 36px;
    padding-right: 36px;
    position: relative;
}
.invoices-items-tabs ul li:last-child {
    padding-right: 0;
}
.invoices-items-tabs ul li a {
    font-weight: 600;
    font-size: 16px;
    color: #1b2559;
    padding-bottom: 24px;
    position: relative;
}
.invoices-items-tabs ul li a.active:after {
    content: "";
    position: absolute;
    left: 50%;
    bottom: 4px;
    width: 130px;
    height: 6px;
    background-color: #7638ff;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
}
.invoices-items-tabs ul li a.active {
    color: #7638ff;
}
.invoices-items-tabs ul li a:hover {
    color: #7638ff;
}

/*-----------------
	5. Modal
-----------------------*/

.modal {
    -webkit-overflow-scrolling: touch;
}
.modal-footer.text-center {
    justify-content: center;
}
.modal-footer.text-left {
    justify-content: flex-start;
}
.modal-dialog.modal-md {
    max-width: 600px;
}
.custom-modal .modal-content {
    border: 0;
    border-radius: 10px;
}
.custom-modal .modal-footer {
    border: 0;
}
.custom-modal .modal-header {
    border: 0;
    justify-content: center;
    padding: 30px 30px 0;
}
.custom-modal .modal-footer {
    border: 0;
    justify-content: center;
    padding: 0 30px 30px;
}
.custom-modal .modal-body {
    padding: 30px;
}
.custom-modal .close {
    background-color: #a0a0a0;
    border-radius: 50%;
    color: #fff;
    font-size: 13px;
    height: 20px;
    line-height: 20px;
    margin: 0;
    opacity: 1;
    padding: 0;
    position: absolute;
    right: 10px;
    top: 10px;
    width: 20px;
    z-index: 99;
}
.custom-modal .modal-title {
    font-size: 22px;
}
.modal-backdrop.show {
    opacity: 0.4;
    -webkit-transition-duration: 400ms;
    transition-duration: 400ms;
}
.modal .card {
    box-shadow: unset;
}
.form-header {
    text-align: center;
    margin-bottom: 30px;
}
.form-header h3 {
    color: #1b2559;
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 10px;
}
.form-header h4 {
    color: #1b2559;
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 10px;
}
.form-header p {
    color: #929292;
    font-size: 15px;
    margin-bottom: 0;
}
.modal-btn > .row {
    margin-left: -8px;
    margin-right: -8px;
}
.modal-btn > .row > div {
    padding-left: 8px;
    padding-right: 8px;
}
.paid-continue-btn {
    background-color: #3d5ee1;
    border: 1px solid #3d5ee1;
    border-radius: 5px;
    color: #fff;
    display: block;
    font-size: 18px;
    font-weight: 600;
    padding: 10px 20px;
    text-align: center;
}
.paid-continue-btn:hover,
.paid-continue-btn:focus,
.paid-continue-btn:active {
    background-color: #fff;
    border: 1px solid #3d5ee1;
    color: #3d5ee1;
}
.paid-cancel-btn {
    background-color: #fff;
    border: 1px solid #3d5ee1;
    border-radius: 5px;
    color: #3d5ee1;
    display: block;
    font-size: 18px;
    font-weight: 600;
    padding: 10px 20px;
    text-align: center;
}
.paid-cancel-btn:hover,
.paid-cancel-btn:focus,
.paid-cancel-btn:active {
    background-color: #18aefa;
    border: 1px solid #18aefa;
    color: #fff;
}
.bank-details .modal-content {
    border: 1px solid #f2ecff;
    border-radius: 6px;
}
.bank-details .modal-header {
    border: 0;
    justify-content: space-between;
    padding: 30px;
    align-items: center;
    display: flex;
}
.bank-details .close {
    background: transparent;
    border: 0;
    color: #1b2559;
    font-size: 28px;
    line-height: normal;
    top: 20px;
    width: auto;
    height: auto;
    right: 20px;
}
.bank-details .close:hover {
    color: #000;
}
.bank-details .modal-body {
    padding-bottom: 10px;
}
.bank-details .bank-inner-details label {
    font-size: 16px;
    font-weight: 600;
    color: #1b2559;
}
.bank-details .bank-inner-details .form-control {
    height: 50px;
}
.bank-details .bank-inner-details textarea {
    min-height: 100px;
}
.bank-details .bank-inner-details .form-control::placeholder {
    color: #8f9bba;
}
.bank-details .modal-footer > * {
    margin: 0;
}
.bank-details .modal-footer {
    justify-content: end;
    padding: 30px;
}
.bank-details-btn .btn {
    min-width: 160px;
    border-radius: 8px;
    padding: 10px 0;
    color: #fff;
}
.bank-details-btn .bank-cancel-btn {
    background: #3d5ee1;
}
.bank-details-btn .bank-cancel-btn:hover {
    background: #18aefa;
}
.bank-details-btn .bank-save-btn {
    background: #3d5ee1;
}
.bank-details-btn .bank-save-btn:hover {
    background: #3d5ee1;
}
.check:checked + .checktoggle {
    background-color: #3d5ee1;
    border: 1px solid #3d5ee1;
}
.invoice-total-inner .checktoggle {
    background-color: #c4c4c4;
    border: 1px solid #c4c4c4;
    border-radius: 12px;
    cursor: pointer;
    font-size: 0;
    height: 15px;
    margin-left: 10px;
    top: 3px;
    margin-bottom: 0;
    position: absolute;
    width: 30px;
    display: inline-block;
}
.invoice-total-inner .checktoggle:after {
    content: " ";
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(5px, -50%);
    width: 8px;
    height: 8px;
    background-color: #fff;
    border-radius: 50%;
    transition: left 300ms ease, transform 300ms ease;
}
.modal-body .invoice-info-card {
    border: none;
    box-shadow: none;
    margin-bottom: 1rem;
}
.invoices-preview .modal-body {
    padding: 0 !important;
}
.settings-form .bootstrap-tagsinput .tag {
    margin-right: 5px;
    color: #fff;
    background: #3d5ee1 !important;
    border-radius: 0px;
    font-size: 15px;
    font-weight: 500;
    padding: 12px 12px;
    font-family: "Titillium Web", sans-serif;
}
.bootstrap-tagsinput .tag [data-role="remove"]:after {
    content: "x";
    padding: 0px 2px;
}
.bootstrap-tagsinput .tag [data-role="remove"] {
    margin-left: 8px;
    cursor: pointer;
}

.links-cont .form-control {
    width: calc(100% - 108px);
}
/*-----------------
	23. Blog
-----------------------*/

.blog {
    background: #fff;
    border-radius: 18px;
    margin-bottom: 25px;
    padding: 15px;
    position: relative;
}
.grid-blog .blog-image {
    margin-bottom: 20px;
    border-radius: 10px;
}
.blog-image,
.blog-image > a,
.blog-image img {
    display: block;
    position: relative;
    width: 100%;
    height: auto;
}
.blog-image img {
    border-radius: 10px;
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    transform: translateZ(0);
    -moz-transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
    -ms-transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
    -o-transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
    -webkit-transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
    transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
}
.blog-content {
    position: relative;
    margin-bottom: 10px;
}
.grid-blog .entry-meta {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 0;
    padding: 0;
}
.table tbody tr:last-child {
    border-color: transparent;
}
.categories-table tr:last-child td {
    padding-bottom: 0px;
}
.grid-blog .entry-meta li {
    margin-bottom: 15px;
}
.entry-meta li {
    display: inline-block;
    margin-right: 15px;
}
.grid-blog .post-author {
    width: 189px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.back-btn {
    font-weight: 600;
    font-size: 16px;
    color: #3d5ee1;
    display: inline-block;
    margin-bottom: 18px;
}
.post-author span:nth-child(1):hover {
    color: #3d5ee1;
}
.post-author a {
    display: flex;
    display: -ms-flexbox;
    align-items: center;
}
.post-author img {
    border-radius: 5px;
    width: 42px;
    height: 42px;
    margin-right: 10px;
}
.grid-blog .blog-title {
    font-size: 16px;
    margin: 0 0 6px;
}
.grid-blog .blog-title a {
    color: #333333;
}
.grid-blog .blog-content p {
    font-size: 12px;
    margin: 0 0 15px;
    color: #71717a;
}
.post-title {
    display: block;
    color: #131523;
    font-weight: 600;
}
.post-date {
    font-size: 12px;
    color: #7e84a3;
}
.blog-views {
    position: absolute;
    top: 20px;
    left: 15px;
    background: #f5f6fa;
    border-radius: 6px;
    padding: 2px 5px;
    display: flex;
    display: -ms-flexbox;
    align-items: center;
    font-size: 12px;
}
.blog-catagories {
    position: absolute;
    top: 15px;
    right: 15px;
    background: #3d5ee1;
    border-radius: 6px;
    min-width: 92px;
    padding: 6px 10px;
    display: flex;
    display: -ms-flexbox;
    align-items: center;
    justify-content: center;
}
.blog-catagories p {
    color: #fff;
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 0px;
}
.blog-views img {
    width: auto;
    margin-right: 5px;
}
.post-list .post-author a span:hover {
    color: #3d5ee1;
}
.edit-delete-btn a {
    margin-right: 6px;
    font-size: 12px;
    border-radius: 5px;
    padding: 6px 13px;
}
.edit-delete-btn a:nth-child(1) {
    margin-right: 6px;
    background: rgba(23, 208, 83, 0.21);
}
.edit-delete-btn a:nth-child(2) {
    background: rgba(255, 0, 0, 0.1);
}
.btn-blog {
    padding: 10px 22px;
}
.edit-options {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.active-text {
    color: #1b5a90;
}
.modal.contentmodal .modal-content .submit-section .btn.btn-download {
    min-width: 200px;
}
.list-links {
    display: flex;
    display: -ms-flexbox;
    flex-wrap: wrap;
    padding: 0;
}
.list-links li {
    margin: 0 15px 10px 0;
    list-style: none;
}
.list-links li a {
    background: #f2f0f0;
    border-radius: 5px;
    padding: 12px 30px;
    font-size: 14px;
    color: #131523;
    border: 1px solid #f5f6fa;
    display: inline-block;
}
.inactive-style {
    font-size: 14px;
}
.list-links li a:hover {
    background: #e5e5e8;
}
.list-links li a span {
    font-size: 14px;
}
.list-links li.active a {
    background: #ffffff;
    border: 1px solid #3d5ee1;
    color: #3d5ee1;
}
.user-menu.nav > li.view-btn > a {
    color: #fff;
    padding: 10px 15px;
    border-radius: 6px;
    background-color: #3d5ee1;
}
.user-menu.nav > li.view-btn > a:hover {
    background-color: #18aefa;
    color: #fff;
}
.user-menu.nav > li.view-btn > a:hover i {
    color: #fff;
}
.user-menu.nav > li.view-btn > a i {
    font-size: 17px;
    position: relative;
}
.view-btn {
    margin-right: 15px;
}
.select-by {
    min-width: 134px;
    margin-right: 25px;
}
.select-by .select2-container--default .select2-selection--single .select2-selection__arrow b {
    border-color: #131523;
    border-style: solid;
    border-width: 0 2px 2px 0;
    padding: 4px;
    height: 0;
    left: 50%;
    margin-left: -5px;
    margin-top: -5px;
    position: absolute;
    top: 45%;
    width: 0;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}
.modal-select-box
    .select2-container--default
    .select2-selection--single
    .select2-selection__arrow
    b {
    border-color: #131523;
    border-style: solid;
    border-width: 0 2px 2px 0;
    padding: 4px;
    height: 0;
    left: 50%;
    margin-left: -5px;
    margin-top: -5px;
    position: absolute;
    top: 45%;
    width: 0;
    transform: rotate(224deg);
    -webkit-transform: rotate(224deg);
}
.select-by
    .select2-container--default.select2-container--open
    .select2-selection--single
    .select2-selection__arrow
    b {
    border-color: #131523;
    border-width: 2px 0 0 2px;
    padding: 3px;
    margin-top: -2px;
}
.pagination-tab {
    padding: 30px;
}
.header.fixed-header .user-menu.nav > li.view-btn > a {
    background-color: #fff;
    color: #000000;
}
.header.fixed-header .user-menu.nav > li.view-btn > a:hover {
    background-color: #d19eb7;
    color: #fff;
}
.modal-select-box
    .select2-container--default
    .select2-selection--single
    .select2-selection__arrow
    b {
    border-color: #131523;
    border-width: 2px 0 0 2px;
    padding: 3px;
    margin-top: -2px;
}
.status-toggle {
    display: flex;
    align-items: center;
}
.status-toggle span {
    margin-left: 8px;
    font-weight: 400;
    font-size: 14px;
}
.del-icon {
    margin-bottom: 23px;
}
.del-icon i {
    font-size: 60px;
    color: #f0142f;
    margin-bottom: 23px;
}
.modal-content .close-btn i {
    font-size: 20px;
    color: #f0142f;
}
.modal.contentmodal .modal-content .submit-section .btn {
    padding: 7px 12px;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 600;
    color: #ffffff;
    min-width: 150px;
    margin-top: 5px;
}
.modal-content.doctor-profile {
    border-radius: 10px;
}
.change-photo-btn {
    color: #7e84a3;
    cursor: pointer;
    display: -ms-flexbox;
    display: flex;
    font-size: 13px;
    font-weight: 600;
    padding: 15px;
    position: relative;
    transition: 0.3s;
    text-align: center;
    height: 120px;
    background: #fafafa;
    border: 1px dashed #e1e1e1;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    vertical-align: middle;
    justify-content: center;
    margin: auto;
    margin-bottom: 10px;
}
.change-photo-btn input.upload {
    bottom: 0;
    cursor: pointer;
    filter: alpha(opacity=0);
    left: 0;
    margin: 0;
    opacity: 0;
    padding: 0;
    position: absolute;
    right: 0;
    top: 0;
    height: 100px;
    width: 100%;
}
.bootstrap-tagsinput {
    display: block;
    border-radius: 5px;
    background: #fff;
    border: 1px solid #ddd;
    min-height: 40px;
    padding-top: 5px;
    padding-left: 5px;
    padding-right: 10px;
    width: 100%;
    box-shadow: none;
}
.bootstrap-tagsinput input {
    border: none;
    box-shadow: none;
    outline: none;
    background-color: transparent;
    padding: 0 6px;
    margin: 0;
    width: auto;
    max-width: inherit;
}
.bootstrap-tagsinput .tag {
    margin-right: 5px;
    margin-bottom: 5px;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    padding: 7px 30px 7px 15px;
    border-radius: 40px;
}
/*-----------------
	24. Blog Details
-----------------------*/

.ck-blurred.ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline {
    height: 150px;
}
.feather.feather-edit-3 {
    width: 12px;
    margin-right: 5px;
}
.fetrash.feather.feather-trash-2 {
    width: 12px;
    margin-bottom: 2.7px;
    margin-right: 5px;
}
.feather.feather-eye-off {
    width: 14px;
    margin-right: 3px;
    margin-bottom: 2px;
}
.feather.feather-eye {
    width: 11px;
    margin-right: 3px;
    margin-bottom: 2px;
}
.close-btn {
    border: 0;
    outline: 0;
    background: transparent;
    font-size: 18px;
}
.blog-single-post .blog-image {
    margin-bottom: 20px;
    border-radius: 25px;
}
.blog-single-post .blog-title {
    font-weight: 700;
    color: #000000;
    margin-bottom: 25px;
    font-size: 20px;
}
.about-author-img {
    background-color: #fff;
    height: 62px;
    width: 62px;
}
.about-author-img img {
    border-radius: 5px;
    height: 62px;
    width: 62px;
}
.author-details {
    margin-left: 20px;
    flex: 1;
}
.about-author {
    display: flex;
    display: -ms-flexbox;
    align-items: center;
}
.author-details .blog-author-name {
    display: inline-block;
    color: #131523;
    font-weight: 600;
    margin-bottom: 5px;
}
.author-details .blog-author-name span {
    font-size: 12px;
    font-weight: 400;
    color: #7e84a3;
    padding-left: 3px;
}
.author-details p {
    font-size: 12px;
}
.blog-comments .comments-list {
    list-style: none;
    margin: 0;
    padding: 0;
    position: relative;
}
.blog-comments .comments-list li {
    clear: both;
    padding-left: 57px;
}
.blog-comments .comments-list li .comment {
    margin-bottom: 20px;
}
.blog-comments .comments-list li .comment-author {
    left: 0;
    position: absolute;
}
.blog-comments .comments-list li img.avatar {
    height: 42px;
    width: 42px;
    border-radius: 5px;
}
.blog-comments .comment-btn {
    color: #000000;
    display: inline-block;
    font-size: 12px;
}
.blog-comments .comment-btn img {
    width: auto !important;
}
.blog-author-name {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 5px;
}
.blog-date {
    color: #7e84a3;
    font-size: 12px;
    padding-left: 15px;
    font-weight: 400;
}
.comments-list .comment-block p {
    font-size: 12px;
    margin-bottom: 10px;
}
.new-comment .form-floating > .form-control,
.form-floating > .form-select {
    height: 50px;
}
.new-comment .form-floating > label {
    padding: 0.8rem 0.6rem;
}
.social-share {
    float: left;
    list-style: none;
    margin: 0;
    padding: 0;
}
.social-share > li {
    display: inline-block;
    float: left;
    margin-left: 10px;
    text-align: center;
}
.social-share > li:first-child {
    margin-left: 0;
}
.social-share > li > a {
    border: 1px solid #3d5ee1;
    border-radius: 6px;
    color: #3d5ee1;
    display: flex;
    display: -ms-flexbox;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    height: 40px;
    width: 40px;
}
.social-share > li > a:hover {
    background-color: #3d5ee1;
    color: #fff;
    border-color: #fff;
}
.post-list ul {
    margin: 0 0 20px;
    padding-left: 0;
    list-style: none;
    display: flex;
    display: -ms-flexbox;
    align-items: center;
    flex-wrap: wrap;
}
.post-list ul li {
    font-size: 12px;
    color: #7e84a3;
    margin-bottom: 5px;
    margin-right: 30px;
}
.post-list ul li i {
    padding-right: 5px;
}
.post-list ul li:last-child {
    margin-right: 0;
}
.post-author img {
    border-radius: 5px;
    width: 36px;
    height: 36px;
    margin-right: 10px;
}
.post-author span:nth-child(1) {
    font-size: 14px;
    font-weight: 500;
    color: #131523;
    transition: all 0.5s;
}
.post-author span:nth-child(2) {
    font-size: 12px;
    font-weight: 400;
    color: #7e84a3;
    transition: all 0.5s;
}
.blog-content p {
    font-size: 14px;
}
a.blog-author-name:hover {
    color: #3d5ee1;
}
a.comment-btn:hover {
    color: #3d5ee1;
}
.save-btn {
    font-weight: 600;
    font-size: 16px;
    padding: 6px 75px;
}
.upload-file {
    margin-bottom: 20px;
}
.upload-file h6 {
    font-weight: 400;
    margin-bottom: 15px;
}
.upload-file img {
    border-radius: 5px;
    margin-bottom: 6px;
}
.upload-file p {
    font-size: 12px;
    color: #7e84a3;
}
.up-img {
    position: relative;
    padding-left: 11px;
}
.up-img .close-icon {
    position: absolute;
    top: -9px;
}
.table-action-btn {
    width: 79px;
    height: 27px;
}
.bank-details .modal-footer.blog-categories-btn {
    justify-content: start;
}
.blog-categories-btn {
    padding: 20px;
}
.modal-footer.blog-categories-btn > .bank-details-btn .btn {
    border-radius: 4px;
}
.checktoggle.checkbox-bg {
    background-color: #fff;
    border: 1px solid #d7d7d7;
}
.checktoggle.checkbox-bg::after {
    background: rgba(0, 0, 0, 0.25);
}
.check:checked + .checktoggle.checkbox-bg::after {
    background-color: #fff;
}
.categories-table .pagination {
    display: none;
}
.categories-table .dataTables_info {
    display: none;
}
.categories-table tr:last-child td {
    padding-bottom: 0px;
}
.blog-view .card-header {
    padding: 15px;
}
.blog-image:hover img {
    transform: scale(1.1);
}
.blog-image {
    overflow: hidden;
}
.grid-structure .grid-container {
    background-color: #dee2e6;
    margin-bottom: 10px;
    padding: 10px 20px;
}

/*-----------------
	27. Ribbons
-----------------------*/

.ribbon-primary {
    background: #7366ff;
}
.ribbon-bookmark.ribbon-primary:before {
    border-color: #7366ff;
    border-right-color: transparent;
}
.ribbon-bookmark.ribbon-right.ribbon-primary:before {
    border-right-color: #7366ff;
    border-left-color: transparent;
}
.ribbon-bookmark.ribbon-vertical-left.ribbon-primary:before,
.ribbon-bookmark.ribbon-vertical-right.ribbon-primary:before {
    border-right-color: #7366ff;
    border-bottom-color: transparent;
}
.ribbon-primary.ribbon-corner:before {
    border-top-color: #7366ff;
    border-left-color: #7366ff;
}
.ribbon-primary.ribbon-corner.ribbon-right:before {
    border-right-color: #7366ff;
    border-left-color: transparent;
}
.ribbon-primary.ribbon-corner.ribbon-bottom:before {
    border-top-color: transparent;
    border-bottom-color: #7366ff;
}
.ribbon-primary.ribbon-clip:before {
    border-color: transparent;
    border-top-color: #43f;
    border-right-color: #43f;
}
.ribbon-primary.ribbon-clip-right:before {
    border-right-color: transparent;
    border-top-color: #43f;
    border-left-color: #43f;
}
.ribbon-primary.ribbon-clip-bottom:before {
    border-top-color: transparent;
    border-bottom-color: #43f;
    border-right-color: #43f;
}
.ribbon-primary.ribbon-clip-bottom-right:before {
    border-top-color: transparent;
    border-bottom-color: #43f;
    border-right-color: transparent;
    border-left-color: #43f;
}
.ribbon-secondary {
    background: #f73164;
}
.ribbon-secondary {
    background: #f73164;
}
.ribbon-bookmark.ribbon-secondary:before {
    border-color: #f73164;
    border-right-color: transparent;
}
.ribbon-bookmark.ribbon-right.ribbon-secondary:before {
    border-right-color: #f73164;
    border-left-color: transparent;
}
.ribbon-bookmark.ribbon-vertical-left.ribbon-secondary:before,
.ribbon-bookmark.ribbon-vertical-right.ribbon-secondary:before {
    border-right-color: #f73164;
    border-bottom-color: transparent;
}
.ribbon-secondary.ribbon-corner:before {
    border-top-color: #f73164;
    border-left-color: #f73164;
}
.ribbon-secondary.ribbon-corner.ribbon-right:before {
    border-right-color: #f73164;
    border-left-color: transparent;
}
.ribbon-secondary.ribbon-corner.ribbon-bottom:before {
    border-top-color: transparent;
    border-bottom-color: #f73164;
}
.ribbon-secondary.ribbon-clip:before {
    border-color: transparent;
    border-top-color: #ec0944;
    border-right-color: #ec0944;
}
.ribbon-secondary.ribbon-clip-right:before {
    border-right-color: transparent;
    border-top-color: #ec0944;
    border-left-color: #ec0944;
}
.ribbon-secondary.ribbon-clip-bottom:before {
    border-top-color: transparent;
    border-bottom-color: #ec0944;
    border-right-color: #ec0944;
}
.ribbon-secondary.ribbon-clip-bottom-right:before {
    border-top-color: transparent;
    border-bottom-color: #ec0944;
    border-right-color: transparent;
    border-left-color: #ec0944;
}
.ribbon-success {
    background: #51bb25;
}
.ribbon-success {
    background: #51bb25;
}
.ribbon-bookmark.ribbon-success:before {
    border-color: #51bb25;
    border-right-color: transparent;
}
.ribbon-bookmark.ribbon-right.ribbon-success:before {
    border-right-color: #51bb25;
    border-left-color: transparent;
}
.ribbon-bookmark.ribbon-vertical-left.ribbon-success:before,
.ribbon-bookmark.ribbon-vertical-right.ribbon-success:before {
    border-right-color: #51bb25;
    border-bottom-color: transparent;
}
.ribbon-success.ribbon-corner:before {
    border-top-color: #51bb25;
    border-left-color: #51bb25;
}
.ribbon-success.ribbon-corner.ribbon-right:before {
    border-right-color: #51bb25;
    border-left-color: transparent;
}
.ribbon-success.ribbon-corner.ribbon-bottom:before {
    border-top-color: transparent;
    border-bottom-color: #51bb25;
}
.ribbon-success.ribbon-clip:before {
    border-color: transparent;
    border-top-color: #3f901d;
    border-right-color: #3f901d;
}
.ribbon-success.ribbon-clip-right:before {
    border-right-color: transparent;
    border-top-color: #3f901d;
    border-left-color: #3f901d;
}
.ribbon-success.ribbon-clip-bottom:before {
    border-top-color: transparent;
    border-bottom-color: #3f901d;
    border-right-color: #3f901d;
}
.ribbon-success.ribbon-clip-bottom-right:before {
    border-top-color: transparent;
    border-bottom-color: #3f901d;
    border-right-color: transparent;
    border-left-color: #3f901d;
}
.ribbon-danger {
    background: #dc3545;
}
.ribbon-danger {
    background: #dc3545;
}
.ribbon-bookmark.ribbon-danger:before {
    border-color: #dc3545;
    border-right-color: transparent;
}
.ribbon-bookmark.ribbon-right.ribbon-danger:before {
    border-right-color: #dc3545;
    border-left-color: transparent;
}
.ribbon-bookmark.ribbon-vertical-left.ribbon-danger:before,
.ribbon-bookmark.ribbon-vertical-right.ribbon-danger:before {
    border-right-color: #dc3545;
    border-bottom-color: transparent;
}
.ribbon-danger.ribbon-corner:before {
    border-top-color: #dc3545;
    border-left-color: #dc3545;
}
.ribbon-danger.ribbon-corner.ribbon-right:before {
    border-right-color: #dc3545;
    border-left-color: transparent;
}
.ribbon-danger.ribbon-corner.ribbon-bottom:before {
    border-top-color: transparent;
    border-bottom-color: #dc3545;
}
.ribbon-danger.ribbon-clip:before {
    border-color: transparent;
    border-top-color: #bd2130;
    border-right-color: #bd2130;
}
.ribbon-danger.ribbon-clip-right:before {
    border-right-color: transparent;
    border-top-color: #bd2130;
    border-left-color: #bd2130;
}
.ribbon-danger.ribbon-clip-bottom:before {
    border-top-color: transparent;
    border-bottom-color: #bd2130;
    border-right-color: #bd2130;
}
.ribbon-danger.ribbon-clip-bottom-right:before {
    border-top-color: transparent;
    border-bottom-color: #bd2130;
    border-right-color: transparent;
    border-left-color: #bd2130;
}
.ribbon-info {
    background: #a927f9;
}
.ribbon-info {
    background: #a927f9;
}
.ribbon-bookmark.ribbon-info:before {
    border-color: #a927f9;
    border-right-color: transparent;
}
.ribbon-bookmark.ribbon-right.ribbon-info:before {
    border-right-color: #a927f9;
    border-left-color: transparent;
}
.ribbon-bookmark.ribbon-vertical-left.ribbon-info:before,
.ribbon-bookmark.ribbon-vertical-right.ribbon-info:before {
    border-right-color: #a927f9;
    border-bottom-color: transparent;
}
.ribbon-info.ribbon-corner:before {
    border-top-color: #a927f9;
    border-left-color: #a927f9;
}
.ribbon-info.ribbon-corner.ribbon-right:before {
    border-right-color: #a927f9;
    border-left-color: transparent;
}
.ribbon-info.ribbon-corner.ribbon-bottom:before {
    border-top-color: transparent;
    border-bottom-color: #a927f9;
}
.ribbon-info.ribbon-clip:before {
    border-color: transparent;
    border-top-color: #9106e7;
    border-right-color: #9106e7;
}
.ribbon-info.ribbon-clip-right:before {
    border-right-color: transparent;
    border-top-color: #9106e7;
    border-left-color: #9106e7;
}
.ribbon-info.ribbon-clip-bottom:before {
    border-top-color: transparent;
    border-bottom-color: #9106e7;
    border-right-color: #9106e7;
}
.ribbon-info.ribbon-clip-bottom-right:before {
    border-top-color: transparent;
    border-bottom-color: #9106e7;
    border-right-color: transparent;
    border-left-color: #9106e7;
}
.ribbon-light {
    background: #f4f4f4;
}
.ribbon-light {
    background: #f4f4f4;
}
.ribbon-bookmark.ribbon-light:before {
    border-color: #f4f4f4;
    border-right-color: transparent;
}
.ribbon-bookmark.ribbon-right.ribbon-light:before {
    border-right-color: #f4f4f4;
    border-left-color: transparent;
}
.ribbon-bookmark.ribbon-vertical-left.ribbon-light:before,
.ribbon-bookmark.ribbon-vertical-right.ribbon-light:before {
    border-right-color: #f4f4f4;
    border-bottom-color: transparent;
}
.ribbon-light.ribbon-corner:before {
    border-top-color: #f4f4f4;
    border-left-color: #f4f4f4;
}
.ribbon-light.ribbon-corner.ribbon-right:before {
    border-right-color: #f4f4f4;
    border-left-color: transparent;
}
.ribbon-light.ribbon-corner.ribbon-bottom:before {
    border-top-color: transparent;
    border-bottom-color: #f4f4f4;
}
.ribbon-light.ribbon-clip:before {
    border-color: transparent;
    border-top-color: #dbdbdb;
    border-right-color: #dbdbdb;
}
.ribbon-light.ribbon-clip-right:before {
    border-right-color: transparent;
    border-top-color: #dbdbdb;
    border-left-color: #dbdbdb;
}
.ribbon-light.ribbon-clip-bottom:before {
    border-top-color: transparent;
    border-bottom-color: #dbdbdb;
    border-right-color: #dbdbdb;
}
.ribbon-light.ribbon-clip-bottom-right:before {
    border-top-color: transparent;
    border-bottom-color: #dbdbdb;
    border-right-color: transparent;
    border-left-color: #dbdbdb;
}
.ribbon-dark {
    background: #2c323f;
}
.ribbon-dark {
    background: #2c323f;
}
.ribbon-bookmark.ribbon-dark:before {
    border-color: #2c323f;
    border-right-color: transparent;
}
.ribbon-bookmark.ribbon-right.ribbon-dark:before {
    border-right-color: #2c323f;
    border-left-color: transparent;
}
.ribbon-bookmark.ribbon-vertical-left.ribbon-dark:before,
.ribbon-bookmark.ribbon-vertical-right.ribbon-dark:before {
    border-right-color: #2c323f;
    border-bottom-color: transparent;
}
.ribbon-dark.ribbon-corner:before {
    border-top-color: #2c323f;
    border-left-color: #2c323f;
}
.ribbon-dark.ribbon-corner.ribbon-right:before {
    border-right-color: #2c323f;
    border-left-color: transparent;
}
.ribbon-dark.ribbon-corner.ribbon-bottom:before {
    border-top-color: transparent;
    border-bottom-color: #2c323f;
}
.ribbon-dark.ribbon-clip:before {
    border-color: transparent;
    border-top-color: #171a21;
    border-right-color: #171a21;
}
.ribbon-dark.ribbon-clip-right:before {
    border-right-color: transparent;
    border-top-color: #171a21;
    border-left-color: #171a21;
}
.ribbon-dark.ribbon-clip-bottom:before {
    border-top-color: transparent;
    border-bottom-color: #171a21;
    border-right-color: #171a21;
}
.ribbon-dark.ribbon-clip-bottom-right:before {
    border-top-color: transparent;
    border-bottom-color: #171a21;
    border-right-color: transparent;
    border-left-color: #171a21;
}
.ribbon-warning {
    background: #f8d62b;
}
.ribbon-warning {
    background: #f8d62b;
}
.ribbon-bookmark.ribbon-warning:before {
    border-color: #f8d62b;
    border-right-color: transparent;
}
.ribbon-bookmark.ribbon-right.ribbon-warning:before {
    border-right-color: #f8d62b;
    border-left-color: transparent;
}
.ribbon-bookmark.ribbon-vertical-left.ribbon-warning:before,
.ribbon-bookmark.ribbon-vertical-right.ribbon-warning:before {
    border-right-color: #f8d62b;
    border-bottom-color: transparent;
}
.ribbon-warning.ribbon-corner:before {
    border-top-color: #f8d62b;
    border-left-color: #f8d62b;
}
.ribbon-warning.ribbon-corner.ribbon-right:before {
    border-right-color: #f8d62b;
    border-left-color: transparent;
}
.ribbon-warning.ribbon-corner.ribbon-bottom:before {
    border-top-color: transparent;
    border-bottom-color: #f8d62b;
}
.ribbon-warning.ribbon-clip:before {
    border-color: transparent;
    border-top-color: #e8c308;
    border-right-color: #e8c308;
}
.ribbon-warning.ribbon-clip-right:before {
    border-right-color: transparent;
    border-top-color: #e8c308;
    border-left-color: #e8c308;
}
.ribbon-warning.ribbon-clip-bottom:before {
    border-top-color: transparent;
    border-bottom-color: #e8c308;
    border-right-color: #e8c308;
}
.ribbon-warning.ribbon-clip-bottom-right:before {
    border-top-color: transparent;
    border-bottom-color: #e8c308;
    border-right-color: transparent;
    border-left-color: #e8c308;
}
.ribbon-wrapper {
    position: relative;
    padding-top: 40px;
}
.ribbon-wrapper .card-body,
.ribbon-vertical-left-wrapper .card-body,
.ribbon-vertical-right-wrapper .card-body {
    position: unset;
}
.ribbon {
    padding: 0 20px;
    height: 30px;
    line-height: 30px;
    clear: left;
    position: absolute;
    top: 12px;
    left: 0px;
    color: #fff;
    z-index: 1;
}
.ribbon-right {
    left: auto;
    right: -2px;
}
.ribbon-vertical-left-wrapper {
    padding-left: 40px;
    position: relative;
}
.ribbon-vertical-left {
    clear: none;
    padding: 0 5px;
    height: 70px;
    width: 30px;
    line-height: 70px;
    text-align: center;
    left: 12px;
    top: -2px;
}
.ribbon-vertical-right-wrapper {
    padding-right: 40px;
}
.ribbon-vertical-right {
    clear: none;
    padding: 0 5px;
    height: 70px;
    width: 30px;
    line-height: 70px;
    text-align: center;
    top: -2px;
    left: auto;
    right: 12px;
}

/*-----------------
	26. Drag & Drop
-----------------------*/

.draggable {
    cursor: -webkit-grab;
    cursor: grab;
}
.gu-unselectable .draggable {
    cursor: -webkit-grabbing;
    cursor: grabbing;
}
#dd-handle .list-group,
#multiple-list-group-a,
#multiple-list-group-b {
    min-height: 5.714rem;
}
#dd-handle .list-group .handle,
.gu-mirror .handle {
    padding: 0 5px;
    margin-right: 5px;
    background-color: rgba(0, 0, 0, 0.1);
    cursor: move;
    font-size: 1.2rem;
}

/*-----------------
	custom
-----------------------*/

.grid-option.table-striped thead tr {
    border-color: #f2f2f2;
}
.sidebar-menu > ul > li > a svg {
    width: 18px;
}
.list-group p {
    margin-bottom: 0;
}
.irs--flat .irs-from,
.irs--flat .irs-to,
.irs--flat .irs-single,
.irs--flat .irs-bar,
.irs--flat .irs-handle > i:first-child,
.irs--flat .irs-handle.state_hover > i:first-child,
.irs--flat .irs-handle:hover > i:first-child {
    background-color: #fcba38;
}
.irs--flat .irs-from:before,
.irs--flat .irs-to:before,
.irs--flat .irs-single:before {
    border-top-color: #fcba38;
}
.navtab-bg .nav-item {
    margin: 0 5px;
}
.navtab-bg .nav-link {
    background-color: #edeff1;
    color: #455560;
}
.nav-pills .nav-link {
    color: #455560;
}
.nav-bordered {
    border-bottom: 2px solid #dee2e6;
}
.nav-bordered li a {
    border: 0 !important;
}
.nav-bordered a.active {
    border-bottom: 2px solid #0d6efd !important;
}
.nav-bordered .nav-item {
    margin-bottom: -1px;
}
.nav-tabs.nav-bordered .nav-link:focus,
.nav-tabs.nav-bordered .nav-link:hover {
    background-color: transparent;
}
.button-list .btn:first-child {
    margin-left: 0;
}
.accordion-faq {
    font-size: 18px;
}
.custom-faq .card-header:first-child {
    border-radius: 20px;
}
.custom-accordion .card-header:first-child {
    border-radius: 20px;
}
.breadcrumb-line .breadcrumb-item:before {
    content: "-";
}
.breadcrumb-line .breadcrumb-item:first-child:before {
    content: "";
}
.breadcrumb-dot .breadcrumb-item:before {
    content: "•";
}
.breadcrumb-dot .breadcrumb-item:first-child:before {
    content: "";
}
.breadcrumb-separatorless .breadcrumb-item:before {
    content: "";
}
.breadcrumb-colored {
    padding: 12px 16px;
    border-radius: 4px;
}
.breadcrumb-colored li a {
    color: #fff;
}
.breadcrumb-colored .breadcrumb-item.active {
    color: #fff;
    opacity: 0.8;
}
.breadcrumb-colored .breadcrumb-item + .breadcrumb-item::before {
    color: #fff;
}
.twitter-bs-wizard-pager-link {
    display: inline-block;
}

/*-----------------
	27. Alert
-----------------------*/

.alertify .ajs-footer .ajs-buttons .ajs-button {
    font-weight: 500;
}
.alertify .ajs-footer .ajs-buttons .ajs-button.ajs-ok {
    color: #5156be;
}
.alertify .ajs-footer .ajs-buttons.ajs-primary {
    text-align: right;
}
.alertify .ajs-body .ajs-content .ajs-input:focus-visible {
    outline: 0;
}
.alertify .ajs-commands {
    right: 4px;
    left: auto;
    margin: -14px 0 0 24px;
}
.alertify-notifier .ajs-message {
    background-color: #5156be;
    border-color: #5156be;
    color: #fff;
    text-shadow: none !important;
}
.alertify-notifier .ajs-message.ajs-success {
    background-color: #2ab57d;
    border-color: #2ab57d;
}
.alertify-notifier .ajs-message.ajs-error {
    background-color: #fd625e;
    border-color: #fd625e;
}
.alertify-notifier .ajs-message.ajs-warning {
    background-color: #ffbf53;
    border-color: #ffbf53;
}
.alertify-notifier.ajs-right {
    right: 10px;
    left: auto;
}
.alertify-notifier.ajs-right .ajs-message {
    right: -320px;
    left: auto;
}
.alertify-notifier.ajs-right .ajs-message.ajs-visible {
    right: 290px;
    left: auto;
}
.alertify-notifier.ajs-left {
    left: 10px;
    right: auto;
}
.alertify-notifier.ajs-left .ajs-message {
    left: -300px;
    right: auto;
}
.alertify-notifier.ajs-left .ajs-message.ajs-visible {
    left: 0;
    right: auto;
}
body[data-layout-mode="dark"] .alertify .ajs-dialog {
    background-color: #313533;
}
body[data-layout-mode="dark"] .alertify .ajs-footer,
body[data-layout-mode="dark"] .alertify .ajs-header {
    color: #ced4da;
    background-color: #313533;
    border-color: #3b403d;
}
body[data-layout-mode="dark"] .alertify .ajs-body {
    color: #adb5bd;
}
body[data-layout-mode="dark"] .alertify .ajs-body .ajs-content .ajs-input {
    color: #adb5bd;
    background-color: #363a38;
    border: 1px solid #3b403d;
}
body[data-layout-mode="dark"] .alertify .ajs-footer .ajs-buttons .ajs-button {
    color: #adb5bd;
}
body[data-layout-mode="dark"] .alertify .ajs-footer .ajs-buttons .ajs-button.ajs-ok {
    color: #5156be;
}
.alertify .ajs-footer .ajs-buttons .ajs-button {
    background-color: transparent;
    color: #000;
    border: 0;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
}
.alertify .ajs-body .ajs-content .ajs-input {
    display: block;
    width: 100%;
    padding: 8px;
    margin: 4px;
    border-radius: 2px;
    border: 1px solid #ccc;
}
.alertify .ajs-header {
    border-bottom: 1px solid #eee;
}
.alertify .ajs-footer {
    border-top: 1px solid #eee;
}

/*-----------------
	28. Timeline
-----------------------*/

.timeline {
    position: relative;
    padding: 20px 0 20px;
    list-style: none;
    max-width: 1200px;
    margin: 0 auto;
}
.timeline:before {
    content: " ";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    width: 3px;
    margin-left: -1.5px;
    background-color: #e9ecef;
}
.timeline > li {
    position: relative;
    margin-bottom: 20px;
}
.timeline > li:before,
.timeline > li:after {
    content: " ";
    display: table;
}
.timeline > li:after {
    clear: both;
}
.timeline > li:before,
.timeline > li:after {
    content: " ";
    display: table;
}
.timeline > li:after {
    clear: both;
}
.timeline > li > .timeline-panel {
    float: left;
    position: relative;
    width: 46%;
    padding: 20px;
    border: 1px solid #e9ecef;
    border-radius: 0.25rem;
    -webkit-box-shadow: 0 1px 6px rgba(0, 0, 0, 0.05);
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.05);
}
.timeline > li > .timeline-panel:before {
    content: " ";
    display: inline-block;
    position: absolute;
    top: 26px;
    right: -8px;
    border-top: 8px solid transparent;
    border-right: 0 solid #e9ecef;
    border-bottom: 8px solid transparent;
    border-left: 8px solid #e9ecef;
}
.timeline > li > .timeline-panel:after {
    content: " ";
    display: inline-block;
    position: absolute;
    top: 27px;
    right: -7px;
    border-top: 7px solid transparent;
    border-right: 0 solid #fff;
    border-bottom: 7px solid transparent;
    border-left: 7px solid #fff;
}
.timeline > li > .timeline-badge {
    z-index: 1;
    position: absolute;
    top: 16px;
    left: 50%;
    width: 50px;
    height: 50px;
    margin-left: -25px;
    border-radius: 50% 50% 50% 50%;
    text-align: center;
    font-size: 1.4em;
    line-height: 50px;
    color: #fff;
    overflow: hidden;
}
.timeline > li.timeline-inverted > .timeline-panel {
    float: right;
}
.timeline > li.timeline-inverted > .timeline-panel:before {
    right: auto;
    left: -8px;
    border-right-width: 8px;
    border-left-width: 0;
}
.timeline > li.timeline-inverted > .timeline-panel:after {
    right: auto;
    left: -7px;
    border-right-width: 7px;
    border-left-width: 0;
}
.timeline-badge.primary {
    background-color: #fb9678;
}
.timeline-badge.success {
    background-color: #00c292;
}
.timeline-badge.warning {
    background-color: #fec107;
}
.timeline-badge.danger {
    background-color: #e46a76;
}
.timeline-badge.info {
    background-color: #03a9f3;
}
.timeline-title {
    margin-top: 0;
    color: inherit;
    font-weight: 400;
}
.timeline-body > p,
.timeline-body > ul {
    margin-bottom: 0;
}
.timeline-body > p + p {
    margin-top: 5px;
}
.cd-horizontal-timeline .events a {
    padding-bottom: 6px;
    color: #621aff;
}
.cd-horizontal-timeline .filling-line,
.cd-horizontal-timeline .events a.selected::after {
    background: #621aff;
}
.cd-horizontal-timeline .events a.selected::after {
    border-color: #621aff;
}
.cd-horizontal-timeline .m-t-40 {
    margin-top: 25px !important;
}
.cd-horizontal-timeline ol,
.cd-horizontal-timeline ul {
    list-style: none;
}
.cd-timeline-navigation a:hover,
.cd-timeline-navigation a:focus {
    border-color: #621aff;
}
.cd-horizontal-timeline a,
.cd-horizontal-timeline a:hover,
.cd-horizontal-timeline a:focus {
    color: #621aff;
}
.cd-horizontal-timeline blockquote,
.cd-horizontal-timeline q {
    quotes: none;
}
.cd-horizontal-timeline blockquote:before,
.cd-horizontal-timeline blockquote:after,
.cd-horizontal-timeline q:before,
.cd-horizontal-timeline q:after {
    content: "";
    content: none;
}
.cd-horizontal-timeline table {
    border-collapse: collapse;
    border-spacing: 0;
}
.cd-horizontal-timeline {
    opacity: 0;
    margin: 2em auto;
    -webkit-transition: opacity 0.2s;
    -moz-transition: opacity 0.2s;
    transition: opacity 0.2s;
}
.cd-horizontal-timeline::before {
    content: "mobile";
    display: none;
}
.cd-horizontal-timeline.loaded {
    opacity: 1;
}
.cd-horizontal-timeline .timeline {
    position: relative;
    height: 100px;
    width: 90%;
    max-width: 800px;
    margin: 0 auto;
}
.cd-horizontal-timeline .events-wrapper {
    position: relative;
    height: 100%;
    margin: 0 40px;
    overflow: hidden;
}
.cd-horizontal-timeline .events-wrapper::after,
.cd-horizontal-timeline .events-wrapper::before {
    content: "";
    position: absolute;
    z-index: 2;
    top: 0;
    height: 100%;
    width: 20px;
}
.cd-horizontal-timeline .events-wrapper::before {
    left: 0;
}
.cd-horizontal-timeline .events-wrapper::after {
    right: 0;
}
.cd-horizontal-timeline .events {
    position: absolute;
    z-index: 1;
    left: 0;
    top: 30px;
    height: 2px;
    background: #dfdfdf;
    -webkit-transition: -webkit-transform 0.4s;
    -moz-transition: -moz-transform 0.4s;
    transition: transform 0.4s;
}
.cd-horizontal-timeline .filling-line {
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background-color: #621aff;
    -webkit-transform: scaleX(0);
    -moz-transform: scaleX(0);
    -ms-transform: scaleX(0);
    -o-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -ms-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
    -webkit-transition: -webkit-transform 0.3s;
    -moz-transition: -moz-transform 0.3s;
    transition: transform 0.3s;
}
.cd-horizontal-timeline .events a {
    position: absolute;
    bottom: 0;
    z-index: 2;
    text-align: center;
    font-size: 1rem;
    padding-bottom: 12px;
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    -ms-transform: translateZ(0);
    -o-transform: translateZ(0);
    transform: translateZ(0);
}
.cd-horizontal-timeline .events a::after {
    content: "";
    position: absolute;
    left: 50%;
    right: auto;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
    bottom: -5px;
    height: 12px;
    width: 12px;
    border-radius: 50%;
    border: 2px solid #dfdfdf;
    background-color: #f8f8f8;
    -webkit-transition: background-color 0.3s, border-color 0.3s;
    -moz-transition: background-color 0.3s, border-color 0.3s;
    transition: background-color 0.3s, border-color 0.3s;
}
.no-touch .cd-horizontal-timeline .events a:hover::after {
    background-color: #621aff;
    border-color: #621aff;
}
.cd-horizontal-timeline .events a.selected {
    pointer-events: none;
}
.cd-horizontal-timeline .events a.selected::after {
    background-color: #621aff;
    border-color: #621aff;
}
.cd-horizontal-timeline .events a.older-event::after {
    border-color: #621aff;
}
.cd-timeline-navigation a {
    position: absolute;
    z-index: 1;
    top: 50%;
    bottom: auto;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    height: 34px;
    width: 34px;
    border-radius: 50%;
    border: 2px solid #dfdfdf;
    overflow: hidden;
    color: transparent;
    text-indent: 100%;
    white-space: nowrap;
    -webkit-transition: border-color 0.3s;
    -moz-transition: border-color 0.3s;
    transition: border-color 0.3s;
}
.cd-timeline-navigation a::after {
    content: "";
    position: absolute;
    height: 16px;
    width: 16px;
    left: 50%;
    top: 50%;
    bottom: auto;
    right: auto;
    -webkit-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    -o-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    background: url(../img/arrow.svg) no-repeat 0 0;
}
.cd-timeline-navigation a.prev {
    left: 0;
    -webkit-transform: translateY(-50%) rotate(180deg);
    -moz-transform: translateY(-50%) rotate(180deg);
    -ms-transform: translateY(-50%) rotate(180deg);
    -o-transform: translateY(-50%) rotate(180deg);
    transform: translateY(-50%) rotate(180deg);
}
.cd-timeline-navigation a.next {
    right: 0;
}
.no-touch .cd-timeline-navigation a:hover {
    border-color: #7b9d6f;
}
.cd-timeline-navigation a.inactive {
    cursor: not-allowed;
}
.cd-timeline-navigation a.inactive::after {
    background-position: 0 -16px;
}
.no-touch .cd-timeline-navigation a.inactive:hover {
    border-color: #dfdfdf;
}
.cd-horizontal-timeline .events-content {
    position: relative;
    width: 100%;
    margin: 2em 0;
    overflow: hidden;
    -webkit-transition: height 0.4s;
    -moz-transition: height 0.4s;
    transition: height 0.4s;
}
.cd-horizontal-timeline .events-content li {
    position: absolute;
    z-index: 1;
    width: 100%;
    left: 0;
    top: 0;
    -webkit-transform: translateX(-100%);
    -moz-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    -o-transform: translateX(-100%);
    transform: translateX(-100%);
    padding: 0 5%;
    opacity: 0;
    -webkit-animation-duration: 0.4s;
    -moz-animation-duration: 0.4s;
    animation-duration: 0.4s;
    -webkit-animation-timing-function: ease-in-out;
    -moz-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
}
.cd-horizontal-timeline .events-content li.selected {
    position: relative;
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
}
.cd-horizontal-timeline .events-content li.enter-right,
.cd-horizontal-timeline .events-content li.leave-right {
    -webkit-animation-name: cd-enter-right;
    -moz-animation-name: cd-enter-right;
    animation-name: cd-enter-right;
}
.cd-horizontal-timeline .events-content li.enter-left,
.cd-horizontal-timeline .events-content li.leave-left {
    -webkit-animation-name: cd-enter-left;
    -moz-animation-name: cd-enter-left;
    animation-name: cd-enter-left;
}
.cd-horizontal-timeline .events-content li.leave-right,
.cd-horizontal-timeline .events-content li.leave-left {
    -webkit-animation-direction: reverse;
    -moz-animation-direction: reverse;
    animation-direction: reverse;
}
.cd-horizontal-timeline .events-content li > * {
    max-width: 800px;
    margin: 0 auto;
}
.cd-horizontal-timeline .events-content h2 {
    font-weight: 600;
    margin-bottom: 0px;
}
.cd-horizontal-timeline .events-content em {
    display: block;
    font-style: italic;
    margin: 10px auto;
}
.cd-horizontal-timeline .events-content em::before {
    content: "- ";
}
.cd-horizontal-timeline .events-content p {
    font-size: 16px;
}
.cd-horizontal-timeline .timeline:before {
    content: " ";
    display: none;
    bottom: 0;
    left: 0%;
    width: 0px;
    margin-left: -1.5px;
    background-color: #eeeeee;
}
.cd-horizontal-timeline .events-content em,
.cd-horizontal-timeline .events-content p {
    line-height: 30px;
}
.h-250 {
    height: 250px;
}
.icons-list {
    padding: 0;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}
.icons-list li {
    display: block;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-size: 18px;
    border-radius: 50px;
    box-shadow: 0px 2px 3px rgb(215 197 255);
    border: 1px solid #e6ebf1;
    color: #7638ff;
    margin: 5px;
}

/*-----------------
	29. Responsive
-----------------------*/

@media only screen and (min-width: 1199.5px) and (max-width: 1620px) {
    .sm-box p {
        color: #fff;
        font-size: 12px;
    }
    .fa-twitter {
        bottom: -6px !important;
    }
    .fa-instagram {
        bottom: -4px !important;
    }
    .fa-linkedin-in {
        bottom: -9px !important;
    }
}

@media (min-width: 768px) {
    .avatar-xxl {
        width: 8rem;
        height: 8rem;
    }
    .avatar-xxl .border {
        border-width: 4px !important;
    }
    .avatar-xxl .rounded {
        border-radius: 12px !important;
    }
    .avatar-xxl .avatar-title {
        font-size: 42px;
    }
    .avatar-xxl.avatar-away:before,
    .avatar-xxl.avatar-offline:before,
    .avatar-xxl.avatar-online:before {
        border-width: 4px;
    }
}

@media (min-width: 992px) {
    #toggle_btn {
        align-items: center;
        color: #fff;
        display: inline-flex;
        float: left;
        font-size: 18px;
        height: 40px;
        width: 40px;
        background: #3d5ee1;
        justify-content: center;
        padding: 0 12px;
        border-radius: 10px;
        position: absolute;
        left: -22px;
        z-index: 99;
        top: 10px;
        box-shadow: 0px 0px 35px rgba(61, 47, 146, 0.1);
    }
    .mini-sidebar .menu-toggle #toggle_btn {
        left: 5px;
    }
    .menu-toggle {
        position: relative;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
    }
    .mini-sidebar .header-left .logo img {
        height: auto;
        max-height: 40px;
        width: auto;
    }
    .mini-sidebar .header .header-left .logo {
        display: none;
    }
    .mini-sidebar .header-left .logo.logo-small {
        display: block;
    }
    .mini-sidebar .header .header-left {
        padding: 0 5px;
        width: 78px;
    }
    .mini-sidebar .sidebar {
        width: 78px;
    }
    .mini-sidebar.expand-menu .sidebar {
        width: 240px;
    }
    .mini-sidebar .menu-title {
        visibility: hidden;
        white-space: nowrap;
    }
    .mini-sidebar.expand-menu .menu-title {
        visibility: visible;
    }
    .mini-sidebar .menu-title a {
        visibility: hidden;
    }
    .mini-sidebar.expand-menu .menu-title a {
        visibility: visible;
    }
    .modal-open.mini-sidebar .sidebar {
        z-index: 1051;
    }
    .mini-sidebar .sidebar .sidebar-menu ul > li > a span {
        display: none;
        transition: all 0.2s ease-in-out;
        opacity: 0;
    }
    .mini-sidebar.expand-menu .sidebar .sidebar-menu ul > li > a span {
        display: inline;
        opacity: 1;
    }
    .mini-sidebar.expand-menu .sidebar .sidebar-menu > ul > li > a i {
        width: 20px;
    }
    .mini-sidebar .page-wrapper {
        margin-left: 78px;
    }
}

@media (max-width: 1399px) {
    .award-list-outs {
        max-width: 300px;
    }
    .skip-group .btn-info {
        min-width: 130px;
        padding: 6px 15px;
        font-size: 15px;
    }
    .views-lesson h4 {
        font-size: 15px;
    }
    .login-wrapper .loginbox .login-right h1 {
        font-size: 22px;
    }
    .account-subtitle {
        font-size: 14px;
    }
    .login-wrapper .loginbox .login-right h2 {
        font-size: 20px;
    }
    .login-wrapper .loginbox .login-right .forgotpass a {
        font-size: 14px;
    }
}

@media (max-width: 1199.5px) {
    .sm-box h6 {
        font-size: 30px;
    }
    .sm-box p {
        font-size: 22px;
    }
    .sm-box .fab {
        font-size: 100px;
    }
    .dash-widget3 {
        padding-right: 15px;
    }
    .dash-widget4 {
        padding-left: 15px;
    }
    .app-listing {
        display: inline-block;
    }
    .app-listing li {
        width: 49%;
        display: inline-block;
    }
    .report-btn .btn {
        width: 200px;
    }
    .sm-box p {
        font-size: 16px;
    }
    .sm-box h6 {
        font-size: 20px;
    }
    .social-boxs {
        background: #fff;
        width: 50px;
        height: 46px;
    }
    .profile-user-img img {
        border: 4px solid #fff;
        width: 75px;
    }
    .names-profiles h4 {
        font-size: 16px;
    }
    .names-profiles h5 {
        font-size: 13px;
    }
    .follow-group {
        padding: 0px 10px;
    }
    .students-follows h4 {
        font-size: 18px;
    }
    .follow-btn-group {
        margin: 10px 0px;
    }
    .follow-btn-group .btn-info {
        min-width: 80px;
        font-size: 14px;
        padding: 5px 15px;
    }
    .profile-edit-icon {
        width: 26px;
        height: 26px;
    }
    .profile-edit-icon i {
        font-size: 12px;
    }
    .search-student-btn .btn-primary {
        min-width: 95px;
    }
}

@media (max-width: 991.98px) {
    .header .header-left {
        position: absolute;
        width: 100%;
        padding: 0px 50px;
    }
    .mobile_btn {
        color: #18aefa !important;
        cursor: pointer;
        display: block;
        font-size: 24px;
        height: 60px;
        left: 0;
        line-height: 60px;
        padding: 0 15px;
        position: absolute;
        text-align: center;
        top: 0;
        z-index: 10;
    }
    #toggle_btn {
        display: none;
    }
    .top-nav-search {
        display: none;
    }
    .login-wrapper .loginbox .login-left {
        padding: 80px 50px;
        width: 50%;
    }
    .login-wrapper .loginbox .login-right {
        padding: 50px;
        width: 50%;
    }
    .sidebar {
        margin-left: -225px;
        width: 225px;
        -webkit-transition: all 0.4s ease;
        -moz-transition: all 0.4s ease;
        transition: all 0.4s ease;
        z-index: 1041;
    }
    .page-wrapper {
        margin-left: 0;
        padding-left: 0;
        padding-right: 0;
        -webkit-transition: all 0.4s ease;
        -moz-transition: all 0.4s ease;
        transition: all 0.4s ease;
    }
    .user-menu.nav > li > a {
        color: #18aefa;
    }
    .user-menu.nav > li > a .badge {
        background-color: #3d5ee1;
        color: #fff;
    }
    .user-menu.nav > li > a:hover i,
    .user-menu.nav > li > a:focus i {
        color: #fff;
    }
    .sm-box h6 {
        font-size: 25px;
    }
    .sm-box p {
        font-size: 20px;
    }
    .sm-box .fab {
        font-size: 100px;
    }
    footer {
        text-align: center;
    }
    .dash-circle {
        display: block;
    }
    .dash-circle .col-12 {
        display: flex;
    }
    .dash-widget1 {
        border-right: none;
    }
    .dash-widget1 ul li {
        width: 40%;
    }
    .page-header .breadcrumb {
        font-size: 15px;
    }
    .multipleSelection #checkBoxes,
    .SortBy #checkBox {
        width: 100%;
    }
    .invoice-item-bg .invoice-info {
        border-right: 0;
        padding: 0;
        margin-top: 0px;
    }
    .invoice-payment-box .payment-details p {
        font-size: 15px;
    }
    .invoice-head p {
        font-size: 16px;
    }
    .invoice-item-bg {
        padding: 20px;
        padding-bottom: 0;
    }
    .invoice-terms h6 {
        font-size: 18px;
    }
    .invoice-terms h6 {
        font-size: 18px;
    }
    .invoice-head h2 {
        font-size: 18px;
    }
    .invoice-head p {
        font-size: 16px;
    }
    .invoice-item-bg .invoice-info {
        border-right: 0;
        padding: 0;
        margin-top: 0px;
    }
    .invoice-item-bg {
        padding: 20px;
        padding-bottom: 0;
    }
    .invoice-total-footer h4 {
        font-size: 15px;
    }
    .settings-form .social-icon {
        width: 45px;
        font-size: 20px;
    }
    .settings-form .trash {
        width: 40px;
    }
    .links-cont .form-control {
        width: calc(100% - 93px);
    }
    .sm-box p {
        font-size: 16px;
    }
    .sm-box h6 {
        font-size: 20px;
    }
    .social-boxs {
        background: #fff;
        width: 50px;
        height: 46px;
    }
    .activity-feed .feed-item .feed-text1 a {
        font-size: 16px;
    }
    .lesson-activity {
        padding-left: 15px;
    }
    .profile-user-img img {
        border: 4px solid #fff;
        width: 75px;
    }
    .names-profiles h4 {
        font-size: 16px;
    }
    .names-profiles h5 {
        font-size: 13px;
    }
    .follow-group {
        padding: 0px 10px;
    }
    .students-follows h4 {
        font-size: 18px;
    }
    .follow-btn-group {
        margin: 10px 0px;
    }
    .follow-btn-group .btn-info {
        min-width: 80px;
        font-size: 14px;
        padding: 5px 15px;
    }
    .profile-edit-icon {
        width: 26px;
        height: 26px;
    }
    .profile-edit-icon i {
        font-size: 12px;
    }
    .search-student-btn {
        margin-bottom: 20px;
    }
}

@media (max-width: 767.98px) {
    body {
        font-size: 0.9375rem;
    }
    h1,
    .h1 {
        font-size: 2rem;
    }
    h2,
    .h2 {
        font-size: 1.75rem;
    }
    h3,
    .h3 {
        font-size: 1.5rem;
    }
    h4,
    .h4 {
        font-size: 1.125rem;
    }
    h5,
    .h5 {
        font-size: 1rem;
    }
    h6,
    .h6 {
        font-size: 0.875rem;
    }
    .header .has-arrow .dropdown-toggle:after {
        display: none;
    }
    .user-menu.nav > li > a > span:not(.user-img) {
        display: none;
    }
    .navbar-nav .open .dropdown-menu {
        float: left;
        position: absolute;
    }
    .navbar-nav.user-menu .open .dropdown-menu {
        left: auto;
        right: 0;
    }
    .header .header-left {
        padding: 0 15px;
    }
    .header .header-left .logo {
        display: none;
    }
    .header-left .logo.logo-small {
        display: inline-block;
        left: 50%;
        position: relative;
        transform: translateX(-50%);
        -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
    }
    .login-wrapper .loginbox .login-left {
        display: none;
    }
    .login-wrapper .loginbox {
        max-width: 450px;
        min-height: unset;
    }
    .login-wrapper .loginbox .login-right {
        float: none;
        padding: 1.875rem;
        width: 100%;
    }
    .left-action {
        text-align: center;
        margin-bottom: 15px;
    }
    .right-action {
        text-align: center;
    }
    .top-action-left .float-left {
        float: none !important;
    }
    .top-action-left .btn-group {
        margin-bottom: 15px;
    }
    .top-action-right {
        text-align: center;
    }
    .top-action-right a.btn.btn-white {
        margin-bottom: 15px;
    }
    .mail-sent-time {
        float: left;
        margin-top: 10px;
        width: 100%;
    }
    .profile-btn {
        flex: 0 0 100%;
        margin-top: 20px;
    }
    .edit-link {
        font-size: 0.875rem;
        margin-top: 0;
    }
    .product_price {
        font-size: 1.5rem;
    }
    .login-wrapper .loginbox .login-right h1 {
        font-size: 22px;
    }
    .error-box h1 {
        font-size: 6em;
    }
    .error-box .btn {
        font-size: 15px;
        min-width: 150px;
        padding: 8px 20px;
    }
    .user-menu {
        padding-right: 0px;
    }
    .page-title {
        font-size: 22px;
    }
    .db-info h3 {
        font-size: 22px;
    }
    .app-listing li {
        width: 100%;
    }
    .invoices-tabs ul li {
        margin-right: 0;
        display: block;
        margin-bottom: 14px;
    }
    .invoices-tabs ul li a {
        padding-bottom: 6px;
    }
    .selectBox p {
        font-size: 12px;
    }
    .report-btn .btn {
        font-size: 14px;
        padding: 10px 0;
    }
    .invoices-tabs ul li a {
        font-size: 15px;
    }
    .inovices-widget-header .inovices-amount {
        font-size: 20px;
    }
    .invoices-settings-btn .btn {
        min-width: 140px;
        padding: 8px 0;
        font-size: 14px;
    }
    .invoices-settings-btn {
        margin-top: 20px;
        justify-content: start;
    }
    .invoices-grid-card .card-header,
    .card-middle,
    .invoices-grid-card .card-body,
    .invoices-grid-card .card-footer {
        padding: 15px;
    }
    .invoice-inner-head {
        padding: 15px;
    }
    .invoice-inner-datepic {
        padding-left: 15px;
        padding-top: 10px;
        padding-bottom: 15px;
    }
    .invoice-details-title {
        font-size: 16px;
    }
    .field-box .btn {
        min-width: 160px;
        padding: 8px 0;
        font-size: 14px;
    }
    .upload-sign .btn {
        min-width: 140px;
        padding: 8px 0;
        font-size: 14px;
    }
    .upload-sign {
        width: 100%;
    }
    .service-upload {
        width: 100%;
    }
    .add-links {
        font-size: 14px;
    }
    .invoice-total-footer h4 {
        font-size: 14px;
    }
    .invoice-add-table th {
        font-size: 14px;
    }
    .invoice-item .customer-text {
        font-size: 16px;
    }
    .invoice-add-table h4 {
        font-size: 16px;
        margin-bottom: 20px;
    }
    .invoices-page-header .invoices-breadcrumb-item a {
        font-size: 14px;
    }
    .invoices-create-btn .invoices-preview-link {
        margin-right: 0;
    }
    .customer-text-one {
        font-size: 16px;
    }
    .invoices-create-btn .delete-invoice-btn {
        display: none;
    }
    .invoices-create-btn .save-invoice-btn {
        display: none;
    }
    .invoice-name {
        font-size: 14px;
    }
    .invoice-head h2 {
        font-size: 16px;
    }
    .form-header h4 {
        font-size: 18px;
    }
    .bank-details-btn .btn {
        min-width: 120px;
        padding: 7px 0;
        font-size: 14px;
    }
    .bank-details .modal-footer {
        padding: 15px;
    }
    .invoice-payment-box .payment-details p {
        font-size: 14px;
    }
    .invoice-head p {
        font-size: 14px;
    }
    .invoice-item-one .invoice-info {
        text-align: left;
    }
    .invoice-payment-box h4 {
        font-size: 15px;
    }
    .invoice-payment-box .payment-details {
        margin-bottom: 20px;
        width: 100%;
    }
    .invoice-payment-box .payment-details p {
        font-size: 14px;
    }
    .invoice-payment-box h4 {
        font-size: 15px;
    }
    .invoice-item .invoice-details-two {
        margin-bottom: 20px;
        font-size: 14px;
    }
    .invoice-terms p {
        font-size: 14px;
    }
    .nav-tabs.menu-tabs .nav-link {
        display: block;
        text-align: center;
    }
    .nav-tabs.menu-tabs .nav-item {
        display: block;
    }
    .card .card-header .card-title {
        font-size: 16px;
    }
    .links-cont .form-group {
        margin-bottom: 10px;
    }
    .settings-form .trash {
        margin-bottom: 10px;
    }
    .settings-menu-links .nav {
        display: block;
    }
    .invoices-tabs ul li a {
        padding-bottom: 6px;
    }
    .app-listing li {
        width: 100%;
    }
    .report-btn .btn {
        font-size: 14px;
        padding: 10px 0;
    }
    .invoices-tabs ul li a {
        font-size: 15px;
    }
    .invoices-tabs ul li {
        margin-right: 0;
        display: block;
        margin-bottom: 14px;
    }
    .invoices-settings-btn {
        margin-top: 20px;
        justify-content: start;
    }
    .invoice-head h2 {
        font-size: 16px;
    }
    .invoice-head p {
        font-size: 14px;
    }
    .invoice-item-one .invoice-info {
        text-align: left;
    }
    .invoice-payment-box .payment-details {
        margin-bottom: 20px;
    }
    .invoice-item .invoice-details-two {
        margin-bottom: 20px;
        font-size: 14px;
    }
    .invoices-create-btn .invoices-preview-link {
        margin-right: 0;
    }
    .invoices-create-btn .btn {
        display: none;
    }
    .invoices-create-btn .btn {
        display: none;
    }
    .invoice-inner-head {
        padding: 15px;
    }
    .invoice-inner-date {
        padding: 15px;
        padding-bottom: 0;
    }
    .invoice-inner-datepic {
        padding-left: 15px;
        padding-top: 10px;
        padding-bottom: 15px;
    }
    .field-box .btn {
        min-width: 160px;
        padding: 8px 0;
        font-size: 14px;
    }
    .invoice-total-footer h4 {
        font-size: 14px;
    }
    .service-upload {
        width: 100%;
    }
    .upload-sign {
        width: 100%;
    }
    .invoice-item-one .invoice-details {
        text-align: left;
    }
    .invoice-item-two .invoice-info2 {
        text-align: left;
    }
    .invoice-item-two .invoice-details {
        text-align: left;
    }
    .invoice-item-box {
        width: 100%;
        float: left;
    }
    .invoice-issues-date {
        padding-top: 10px;
        padding-bottom: 0;
    }
    .invoices-settings-btn .btn {
        min-width: 140px;
        padding: 8px 0;
        font-size: 14px;
    }
    .invoices-settings-btn-one {
        margin-top: 0;
    }
    .payment-details {
        width: auto;
    }
    .invoice-item .customer-text {
        font-size: 16px;
    }
    .invoice-setting-btn .btn {
        min-width: 118px;
        padding: 7px 0;
        font-size: 14px;
    }
    .invoice-faq {
        margin-bottom: 20px;
    }
    .language-drop,
    .zoom-screen {
        display: none;
    }
    .activity-awards {
        display: block;
        margin-bottom: 20px;
    }
    .award-list-outs {
        margin: 10px 0px;
    }
    .sm-box p {
        font-size: 16px;
    }
    .sm-box h6 {
        font-size: 20px;
    }
    .social-boxs {
        background: #fff;
        width: 40px;
        height: 40px;
    }
    .lesson .btn-info {
        padding: 2px 7px;
        font-size: 13px;
    }
    .circle-bar > div canvas {
        width: 130px !important;
        height: 130px !important;
    }
    .circle-bar > div b {
        font-size: 20px;
    }
    .activity-feed .feed-item,
    .teacher-date-list {
        display: block !important;
    }
    .teacher-date-list li:last-child {
        margin-left: 0;
    }
    .skip-group .skip-btn {
        margin-bottom: 0;
    }
    .skip-group {
        margin-bottom: 10px;
    }
    .profile-user-img img {
        border: 4px solid #fff;
        width: 90px;
    }
    .names-profiles h4 {
        font-size: 16px;
    }
    .names-profiles h5 {
        font-size: 13px;
    }
    .follow-group {
        padding: 0px 10px;
    }
    .students-follows h4 {
        font-size: 18px;
    }
    .follow-btn-group {
        margin: 10px 0px;
    }
    .follow-btn-group .btn-info {
        min-width: 100px;
        font-size: 14px;
        padding: 5px 15px;
    }
    .profile-edit-icon {
        width: 26px;
        height: 26px;
    }
    .profile-edit-icon i {
        font-size: 12px;
    }
    .user-img .user-text {
        display: none;
    }
    .star-student.table-striped tbody tr td,
    .star-student.table-striped tbody tr td a {
        font-size: 14px;
    }
    .social-boxs img {
        max-width: 25px;
    }
    .teacher-date-list li:nth-child(2) {
        display: none;
    }
    .activity-btns .btn-info {
        min-width: 125px;
    }
    .teacher-date-list {
        margin: 10px 0px;
    }
    .chart-list-out span {
        width: 5px;
        height: 5px;
    }
    .lesson-view-all a {
        font-size: 12px;
    }
    .chart-list-out li {
        margin-right: 5px;
    }
    .search-student-btn .btn-primary {
        min-width: 120px;
        padding: 6px 15px;
    }
    .search-student-btn {
        margin-bottom: 20px;
    }
    .btn-outline-gray {
        width: 30px;
        height: 30px;
    }
    .download-grp .btn-outline-primary {
        font-size: 14px;
        padding: 3px 9px;
    }
    .download-grp .btn-primary {
        padding: 2px 7px;
    }
    .download-grp .btn-primary i {
        font-size: 13px;
    }
}

@media (max-width: 575.98px) {
    .card {
        margin-bottom: 0.9375rem;
    }
    .page-wrapper > .content {
        padding: 0.9375rem 0.9375rem 0;
    }
    .card-body {
        padding: 1.25rem;
    }
    .card-header {
        padding: 0.75rem 1.25rem;
    }
    .card-footer {
        padding: 0.75rem 1.25rem;
    }
    .card-chart .card-body {
        padding: 5px;
    }
    .page-header {
        margin-bottom: 0.9375rem;
    }
    .account-wrapper {
        padding: 0.9375rem;
    }
    .profile-image {
        flex: 0 0 100%;
        margin-bottom: 20px;
        text-align: center;
    }
    .profile-user-info {
        text-align: center;
    }
    .profile-btn {
        text-align: center;
    }
    .fc-toolbar .fc-right {
        display: inline-block;
        float: none;
        margin: 10px auto 0;
        width: auto;
        clear: both;
    }
    .fc-toolbar .fc-left {
        float: none;
        margin: 0 auto;
        width: 200px;
    }
    .fc-toolbar .fc-center {
        display: inline-block;
        width: 100%;
        text-align: center;
    }
    .fc-toolbar .fc-center h2 {
        width: 100%;
    }
    .profile-tab-cont {
        padding-top: 1.25rem;
    }
    .about-info .media {
        flex-direction: column;
    }
    .title-span {
        width: 100px;
    }
    .about-info ul li {
        font-size: 13px;
    }
    .dash-widget1 {
        display: block;
    }
    .dash-widget2 {
        border: none;
    }
    .dash-widget1 .circle-bar {
        width: auto;
        padding-right: 0px;
        text-align: center;
    }
    .dash-details {
        padding-top: 20px;
        text-align: left;
    }
    .dash-widget1 ul {
        margin: 0 0px;
    }
    .dash-widget1 ul li {
        width: auto;
        display: inline-block;
    }
    .view-link {
        font-size: 13px;
    }
    .page-header .breadcrumb {
        display: none;
    }
}

@media (max-width: 479px) {
    .dropdown-menu.notifications.show {
        transform: translate3d(-190px, 60px, 0px) !important;
    }
    .user-menu .dropdown-menu {
        width: 310px;
    }
    .notifications .noti-content {
        width: 310px;
        height: 355px;
    }
}

/* Icon */
svg.feather.feather-grid.feather-grid {
    width: 16px;
    height: 16px;
}
svg.feather.feather-list.feather-list {
    width: 16px;
    height: 16px;
}
.feather.feather-eye {
    width: 15px;
}
svg.feather.feather-edit.list-edit {
    width: 13px !important;
    height: 13px !important;
    margin-bottom: 7px !important;
    margin-left: 1px !important;
}
svg.feather.feather-trash-2.payment-trash {
    width: 26px;
    height: 26px;
    color: white;
}
.feather.feather-eye .blog-eye {
    width: 15px;
}
.feather.feather-edit-3 .blog-edit {
    width: 12px;
    margin-right: 7px;
    margin-top: -3 px;
}
svg.feather.feather-trash-2.blog-trash {
    width: 12px;
    margin-right: 7px;
    margin-top: -3px;
}
svg.feather.feather-plus-circle.blog-add {
    width: 16px;
    margin-right: 7px;
    margin-left: -3px;
    margin-top: -2.5px;
}
svg.feather.feather-clock.blog-clock {
    width: 12px;
    margin-right: 5px;
    margin-bottom: 3.1px;
}
svg.feather.feather-message-square.blog-square {
    width: 14px;
    margin-right: 7px;
    margin-bottom: 2px;
}
svg.feather.feather-grid.blog-grid {
    width: 14px;
    margin-right: 7px;
    margin-bottom: 3px;
}
svg.feather.feather-eye.blog-eye {
    width: 12px;
}
.feather.feather-copy {
    width: 15px;
}
.feather.feather-trash-2 {
    width: 15px;
}
.feather.feather-chevron-left {
    width: 16px;
}
/* .feather.feather-trash-2 {
    width: 26px;
} */
.feather.feather-git-commit {
    width: 20px;
}
.feather.feather-bookmark {
    width: 20px;
}

svg.feather.feather-user {
    width: 15px;
}
svg.feather.feather-phone-call {
    width: 15px;
}
svg.feather.feather-mail {
    width: 15px;
}
svg.feather.feather-calendar {
    width: 15px;
}
svg.feather.feather-map-pin {
    width: 15px;
}
svg.feather.feather-italic {
    width: 15px;
}

.blog-views {
    position: absolute !important;
    top: 20px !important;
    left: 15px;
    background: #f5f6fa !important;
    border-radius: 6px !important;
    padding: 2px 5px !important;
    display: flex !important;
    display: -ms-flexbox !important;
    align-items: center !important;
    font-size: 12px !important;
}

/* Pagination */
.ant-pagination-prev.ant-pagination-disabled {
    /* margin-right: 0px !important; */
    border: 0px rgb(0, 0, 0, 0.125) solid;
    border-radius: 0px !important;
    border-top-left-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
    padding-left: 10px;
    padding-right: 10px;
    color: #6c757d !important;
}
.ant-pagination-item.ant-pagination-item-1.ant-pagination-item-active {
    /* margin-right: 0px !important; */
    border-radius: 0px !important ;
    background-color: #1677ff !important;
    color: white !important;
    padding-left: 10px;
    padding-right: 10px;
}
ant-pagination-item.ant-pagination-item-2 {
    margin-right: 0px !important;
    border-radius: 0px !important ;
    background-color: #1677ff !important;
    color: white !important;
    padding-left: 10px;
    padding-right: 10px;
}
li.ant-pagination-item.ant-pagination-item-2 {
    border: 1px rgb(0, 0, 0, 0.125) solid !important;
    border-radius: 0px;
    /* border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem!important; */
    padding-left: 10px;
    padding-right: 10px;
    color: #6c757d !important;
}

li.ant-pagination-next {
    border: 1px rgb(0, 0, 0, 0.125) solid !important;
    border-radius: 0px;
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
    border-top-left-radius: 0rem !important;
    border-bottom-left-radius: 0rem !important;
    padding-left: 10px;
    position: relative;
    left: -9px;
    padding-right: 10px;
    color: #6c757d !important;
}
li.ant-pagination-item.ant-pagination-item-3 {
    border: 1px rgb(0, 0, 0, 0.125) solid !important;
    border-radius: 0px;
    /* border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem!important; */
    padding-left: 10px;
    padding-right: 10px;
    color: #6c757d !important;
    margin-left: -9px !important;
}
li.ant-pagination-item.ant-pagination-item-2.ant-pagination-item-active {
    /* margin-right: 0px !important; */
    border-radius: 0px !important ;
    background-color: #1677ff !important;
    color: white !important;
    padding-left: 10px;
    padding-right: 10px;
}
li.ant-pagination-item.ant-pagination-item-3.ant-pagination-item-active {
    border-radius: 0px !important ;
    background-color: #1677ff !important;
    color: white !important;
    padding-left: 10px;
    padding-right: 10px;
}
li.ant-pagination-next.ant-pagination-disabled {
    left: 0 !important;
}
li.ant-pagination-item.ant-pagination-item-1 {
    border: 1px rgb(0, 0, 0, 0.125) solid !important;
    border-radius: 0px;
    /* border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem!important; */
    border-top-left-radius: 0rem !important;
    border-bottom-left-radius: 0rem !important;
    padding-left: 10px;
    position: relative;
    left: 8px;
    padding-right: 10px;
    color: #6c757d !important;
}
li.ant-pagination-prev {
    border: 1px rgb(0, 0, 0, 0.125) solid !important;
    border-radius: 0px;
    /* border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem!important; */
    border-top-left-radius: 0rem !important;
    border-bottom-left-radius: 0rem !important;
    padding-left: 10px;
    position: relative;
    left: 17px;
    padding-right: 10px;
    color: #6c757d !important;
}
.ant-pagination .ant-pagination-prev {
    border-radius: 0px !important;
}
.ant-pagination-next.ant-pagination-disabled {
    border: 1px rgb(0, 0, 0, 0.125) solid !important;
    border-radius: 0px;
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
    padding-left: 10px;
    padding-right: 10px;
    color: #6c757d !important;
}
.paginate_button page-item :where(.css-dev-only-do-not-override-1vtf12y) a[disabled] {
    color: #6c757d;
    cursor: not-allowed;
}
.ant-pagination.ant-table-pagination.ant-table-pagination-right.css-dev-only-do-not-override-1vtf12y {
    position: relative !important;
}
.ant-pagination-total-text {
    position: absolute !important;
    left: 0 !important;
}
/* Pagination */

.select2-container--default .select2-selection--single .select2-selection__placeholder {
    color: #333 !important;
    position: relative !important;
    /* top: 3.5px !important; */
}
.select2-container--default .select2-selection--single {
    height: 43px !important;
}
.select2-container--default .select2-selection--single .select2-selection__rendered {
    font-size: 14px;
    font-weight: normal;
    color: #444;
    line-height: 45px !important;
}
.select2-container--default .select2-selection--single {
    background-color: #fff;
    border: 1px solid #d4d1d1 !important;
    border-radius: 4px;
}

.select2-container .select2-selection--single {
    border: 1px solid #ddd;
    height: 43.5px !important;
}
span#select2-91y0-container {
    line-height: 0px !important;
}
.select2.select2-container.select2-container--default.select2-container--below.select2-container--open.select2-container--focus {
    color: #444;
    line-height: 0px;
}
.select2-container--default .select2-selection--single .select2-selection__arrow {
    top: 23% !important;
    color: #333 !important;
}
.select2-search__field {
    padding: 4px;
    width: 100%;
    box-sizing: border-box;
    display: none;
}
.select2-container .select2-selection--single {
    box-sizing: border-box;
    cursor: pointer;
    display: block;
    height: 44px;
    user-select: none;
    -webkit-user-select: none;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
    border-color: #000 transparent transparent transparent !important;
    border-style: solid;
    border-width: 6px 6px 0 6px !important;
    height: 0;
    left: 50%;
    margin-left: -12px !important;
    margin-top: -2px;
    position: absolute;
    top: 50%;
    width: 0;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 26px;
    position: absolute;
    top: 10px;
    right: 1px;
    width: 20px;
}

a.btn.btn-sm.bg-success-light.me-2 {
    width: 32px;
    height: 32px;
}
a.btn.btn-sm.bg-danger-light {
    width: 32px;
    height: 32px;
}
.departmenteye.feather.feather-eye {
    width: 14px;
}
svg.feather.feather-edit {
    width: 14px !important;
    height: 14px !important;
}
.upload-images.upload-size.logo img {
    position: absolute;
    top: 18px;
}

svg.feather.feather-x-circle.crossmark {
    position: relative;
    left: 113px;
}
svg.feather.feather-minus-circle.invoice-minus {
    width: 15px !important;
}

/* scrollbar */

.horizontal-card-body,
.vertical-card-body,
.small-card-body {
    overflow: hidden;
}
.horizontal-card-body:hover {
    overflow-x: auto;
}
.vertical-card-body:hover {
    overflow-y: auto;
}
.small-card-body {
    overflow-y: auto;
}
.visible-scroll-body {
    overflow: auto;
}
.small-card-body::-webkit-scrollbar {
    width: 10px; /* Set the width of the scrollbar */
}
.small-card-body::-webkit-scrollbar-thumb {
    background-color: #ccc; /* Set the color of the scrollbar thumb */
}

.small-card-body::-webkit-scrollbar-track {
    background-color: #f1f1f1; /* Set the color of the scrollbar track */
}

.small-card-body::-webkit-scrollbar-thumb:vertical {
    height: 10px; /* Set the height of the scrollbar thumb */
}

/* Horizontal TimeLine */
li#timeline-dot-20\ May,
li#timeline-dot-30\ Aug,
li#timeline-dot-15\ Sep,
li#timeline-dot-01\ Nov,
li#timeline-dot-10\ Dec {
    width: 93px !important;
}
li#timeline-dot-16\ Jan,
li#timeline-dot-28\ Feb,
li#timeline-dot-20\ Mar,
li#timeline-dot-20\ May,
li#timeline-dot-09\ Jul,
li#timeline-dot-30\ Aug,
li#timeline-dot-15\ Sep,
li#timeline-dot-01\ Nov,
li#timeline-dot-10\ Dec,
li#timeline-dot-29\ Jan,
li#timeline-dot-3\ Mar {
    color: #621aff !important;
}
.horizontal-content {
    text-align: left !important;
    width: 800px !important;
    margin-left: 120px !important;
    font-size: 17px !important;
    margin-bottom: 98px !important;
    line-height: 1.8 !important;
    margin-top: -50px !important;
}
/* Dashboard */
.progress-text {
    margin-top: -100px;
}

/* Settings */
.upload-images.upload-size.logo {
    width: 178px !important;
}
img.favicon-changes {
    position: relative !important;
    top: 24px !important;
    left: 24px !important;
}
.rti--tag {
    align-items: center;
    background: #3d5ee1 !important;
    border-radius: 0px !important;
    display: inline-flex;
    justify-content: center;
    padding: 10px !important;
    color: white !important;
}

/* Events */
button.fc-prev-button.fc-button.fc-button-primary {
    background-color: #f1f1f1;
    color: #797979;
    border-color: #f1f1f1;
}
button.fc-next-button.fc-button.fc-button-primary {
    background-color: #f1f1f1 !important;
    color: #797979 !important;
    border-color: #f1f1f1;
}
button.fc-today-button.fc-button.fc-button-primary:disabled {
    background-color: #3d5ee1 !important;
    border-color: #3d5ee1 !important;
    color: #fff !important;
}
button.fc-today-button.fc-button.fc-button-primary {
    background-color: #f1f1f1 !important;
    color: #797979 !important;
    border-color: #f1f1f1;
}

button.fc-dayGridMonth-button.fc-button.fc-button-primary.fc-button-active {
    background-color: #3d5ee1 !important;
    border-color: #3d5ee1;
    color: #fff !important;
}
button.fc-dayGridMonth-button.fc-button.fc-button-primary {
    background-color: #f1f1f1 !important;
    color: #797979 !important;
    border-color: #f1f1f1;
}

button.fc-timeGridWeek-button.fc-button.fc-button-primary {
    background-color: #f1f1f1 !important;
    color: #797979 !important;
    border-color: #f1f1f1;
}
button.fc-timeGridWeek-button.fc-button.fc-button-primary.fc-button-active {
    background-color: #3d5ee1 !important;
    border-color: #3d5ee1 !important;
    color: #fff !important;
}

button.fc-timeGridDay-button.fc-button.fc-button-primary {
    background-color: #f1f1f1 !important;
    color: #797979 !important;
    border-color: #f1f1f1;
}
button.fc-timeGridDay-button.fc-button.fc-button-primary.fc-button-active {
    background-color: #3d5ee1 !important;
    border-color: #3d5ee1 !important;
    color: #fff !important;
}

th.fc-col-header-cell.fc-day.fc-day-sun,
th.fc-col-header-cell.fc-day.fc-day-mon,
th.fc-col-header-cell.fc-day.fc-day-tue,
th.fc-col-header-cell.fc-day.fc-day-wed,
th.fc-col-header-cell.fc-day.fc-day-thu,
th.fc-col-header-cell.fc-day.fc-day-fri,
th.fc-col-header-cell.fc-day.fc-day-sat {
    background: #eeeeee;
    border-color: #f3f3f3;
    font-size: 14px;
    line-height: 20px;
    padding: 10px 0;
    text-transform: uppercase;
}
.fc-unthemed th,
.fc-unthemed td,
.fc-unthemed thead,
.fc-unthemed tbody,
.fc-unthemed .fc-divider,
.fc-unthemed .fc-row,
.fc-unthemed .fc-popover {
    border-color: #f3f3f3;
}
a.fc-col-header-cell-cushion {
    color: #333333;
}
.fc .fc-daygrid-day-number {
    padding: 4px;
    position: relative;
    z-index: 4;
    color: #333333 !important;
}
.card-body.profile-blog {
    position: relative;
    padding: 26px !important;
}

.react-calendar {
    border: none !important;
}
button.react-calendar__navigation__arrow.react-calendar__navigation__prev2-button,
button.react-calendar__navigation__arrow.react-calendar__navigation__next2-button {
    color: white;
}
.progress-less.teacher-dashboard {
    width: 137px !important;
}

/* RANGESLIDER */
.irs--flat .irs-handle > i:first-child {
    background-color: #fcba38 !important;
}
.irs--flat .irs-bar {
    background-color: #fcba38 !important;
}
.irs--flat .irs-from,
.irs--flat .irs-to,
.irs--flat .irs-single {
    background-color: #fcba38 !important;
}
.irs--flat .irs-from:before,
.irs--flat .irs-to:before,
.irs--flat .irs-single:before {
    border-top-color: #fcba38 !important;
}
.react-datepicker-popper {
    z-index: 999 !important;
}
.calendar-info.calendar-info1 {
    margin-top: 64px !important;
}
span.flagg {
    margin-left: 7px;
}

span.toggle-password {
    position: absolute;
    right: 15px;
    top: 25%;
}

.css-1nmdiq5-menu {
    z-index: 99999999;
}

/* .css-13cymwt-control{

	min-height: 43px !important;
  } */

.css-1u9des2-indicatorSeparator {
    display: none;
}

.css-t3ipsp-control {
    min-height: 44px !important;
    box-shadow: 0 0 0 1px #e3e3e3 !important;
}

.css-13cymwt-control &:hover {
    box-shadow: none;
    font-size: 15px;
    height: 43px;
    line-height: 2;
}

.css-1nmdiq5-menu ul li {
    background: yellow !important;
}

.css-13cymwt-control {
    min-height: 43px !important;
}

.css-1nmdiq5-menu {
    z-index: 999999 !important;
}

.legend {
    float: none !important;
}
