.App {
  img {
    width: 40px;
  }
}

.MuiFab-primary {
  color: #fff !important;
}

.btn-primary {
  background-color: #3f89b5 !important;
  color: #fff !important;
}

.error-message {
  color: #f44336 !important;
}

.mt-5 {
  margin-top: 15px !important;
}

.pt-5 {
  padding-top: 15px !important;
}

.pb-5 {
  padding-bottom: 15px !important;
}

.lds-ripple.bd-gray div {
  border: 4px solid #eeeeee;
}

.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ripple div {
  position: absolute;
  border: 4px solid #fff;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}

@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }

  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}
